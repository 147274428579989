import React from 'react'
import MatchHead from '../../simple/match-head/match-head'
import MatchList from '../match-list/match-list'
import {MatchSeries} from '../../../api'

function Match({className, seriesArray}: {className?: string; seriesArray: MatchSeries[]}) {
  return (
    <div className={`gap gap--20 ${seriesArray.length === 0 ? 'hidden' : ''}`}>
      <div className={`match ${className || ''}`}>
        <MatchHead />
        <MatchList seriesArray={seriesArray} />
      </div>
    </div>
  )
}

export default Match
