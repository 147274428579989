import React, {useRef, useEffect, ReactNode} from 'react'

import {Fancybox as NativeFancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'

function Fancybox({
  delegate,
  options,
  children,
}: {
  delegate?: string
  options?: object
  children?: ReactNode
}) {
  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const container = containerRef.current
    const actualDelegate = delegate || '[data-fancybox]'
    const actualOptions = options || {}
    NativeFancybox.bind(container, actualDelegate, actualOptions)
    return () => {
      NativeFancybox.unbind(container)
      NativeFancybox.close()
    }
  }, [delegate, options])
  return <div ref={containerRef}>{children}</div>
}

export default Fancybox
