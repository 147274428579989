import React, {useState, useCallback} from 'react'
import Cropper, {Area} from 'react-easy-crop'
import {getCroppedImg} from '../../../core/utils/common-helpers'

function ImageCropper({
  image,
  onCropCompleteBase64,
  onCropCompleteFile,
  onCancel,
  aspect = 1,
  outputType,
}: {
  image: string
  onCropCompleteBase64: (croppedImage: string | null) => void
  onCropCompleteFile: (croppedImage: File | null) => void
  onCancel: () => void
  aspect?: number
  outputType: 'base64' | 'file'
}) {
  const [crop, setCrop] = useState<{x: number; y: number}>({x: 0, y: 0})
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)

  const onCropCompleteHandler = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleCrop = async () => {
    if (croppedAreaPixels) {
      let croppedImage
      try {
        croppedImage = await getCroppedImg(image, croppedAreaPixels, outputType)
      } catch (error) {
        return
      }
      if (outputType === 'base64') {
        onCropCompleteBase64(croppedImage as string | null)
      } else {
        onCropCompleteFile(croppedImage as File | null)
      }
    }
  }

  return (
    <div className="cropper">
      <div className="cropper-container">
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          showGrid={false}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropCompleteHandler}
          onZoomChange={setZoom}
        />
      </div>
      <div className="cropper-controls">
        <input
          className="cropper-zoom-range"
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          onChange={(e) => setZoom(Number(e.target.value))}
        />
        <button className="save" type="button" onClick={handleCrop}>
          Сохранить
        </button>
        <button className="save" type="button" onClick={onCancel}>
          Отмена
        </button>
      </div>
    </div>
  )
}

export default ImageCropper
