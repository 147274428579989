/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice} from '@reduxjs/toolkit'
import {TournamentsTempsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {fetchTournamentsRequest, fetchTournamentsTempsMine} from '../api-actions'

const initialState: TournamentsTempsState = {
  isTournamentsTempsLoading: false,
  isTournamentsTempLoading: false,
  isTournamentsTempsError: false,
  isTournamentsTempError: false,
  tournamentsTemps: [],
  currentTournamentsTemp: null,
}

const tournamentsTempsData = createSlice({
  name: NameSpace.TournamentsTemps,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTournamentsTempsMine.pending, (state) => {
        state.isTournamentsTempsLoading = true
      })
      .addCase(fetchTournamentsTempsMine.fulfilled, (state, action) => {
        state.tournamentsTemps = action.payload
        state.isTournamentsTempsLoading = false
      })
      .addCase(fetchTournamentsTempsMine.rejected, (state) => {
        state.isTournamentsTempsError = true
        state.isTournamentsTempsLoading = false
      })
      .addCase(fetchTournamentsRequest.pending, (state) => {
        state.isTournamentsTempLoading = true
      })
      .addCase(fetchTournamentsRequest.fulfilled, (state, action) => {
        state.currentTournamentsTemp = action.payload
        state.isTournamentsTempLoading = false
      })
      .addCase(fetchTournamentsRequest.rejected, (state) => {
        state.isTournamentsTempError = true
        state.isTournamentsTempLoading = false
      })
  },
})

export default tournamentsTempsData
