import React, {useEffect, useState} from 'react'
import {FormikProvider, useFormik} from 'formik'
import CrumbsList from '../../components/ordinary/crumbs-list/crumbs-list'
import Title from '../../components/ui/title/title'
import AppRoute from '../../core/constants/routes'
import ButtonsList from '../../components/ui/buttons-list/buttons-list'
import Avatar from '../../components/ordinary/avatar/avatar'
import CommandsForm from '../../components/ordinary/commands-form/commands-form'
import CompoundCommandsList from '../../components/simple/compound-commands-list/compound-commands-list'
import ButtonForm from '../../components/ui/button-form/button-form'
import {registrationCommandFormValidationSchema} from '../../core/utils/form-validate-helpers'
import {useAppDispatch, useAppSelector, useFilteredButtons} from '../../core/hooks'
import {createTeam, fetchTeamsRequestsMine} from '../../core/store/api-actions'
import {getAuthorizationUserId} from '../../core/store/authorization-data/selectors'
import {getTeamTemps} from '../../core/store/teams-temps-data/selectors'
import RequestApplicationList from '../../components/ordinary/request-application-list/request-application-list'
import {changeMessageModal} from '../../core/store/modals-data/modals-data'
import {getUser} from '../../core/store/users-data/selectors'
import {getCreateTeamStatus} from '../../core/store/create-team-data/selectors'

function RegistrationCommandsPage() {
  const dispatch = useAppDispatch()
  const crumbsRoutes = [AppRoute.Main, AppRoute.RegistrationCommandsPage]
  const filteredButtons = useFilteredButtons()
  const userId = useAppSelector(getAuthorizationUserId)
  const user = useAppSelector(getUser)
  const teamTemps = useAppSelector(getTeamTemps)
  const createTeamSuccess = useAppSelector(getCreateTeamStatus)
  const [avatarImage, setAvatarImage] = useState<File | null>(null)
  const [showForm, setShowForm] = useState<boolean>(false)

  const initialValues = {
    user_id: userId || '',
    full_name: '',
    club: '',
    zone_id: '',
    club_address: '',
    city_id: '',
    court_coverage: '',
    phone: user?.phone || '',
    email: user?.email || '',
    logo: '',
    partner_logo: '',
    is_draft: 0,
    players: [
      {
        first_name: '',
        last_name: '',
        birthday: '',
        gender: '',
        photo: '',
        ntrp: 0,
      },
    ],
  }
  const validationSchema = registrationCommandFormValidationSchema

  useEffect(() => {
    if (userId) {
      dispatch(fetchTeamsRequestsMine(Number(userId)))
    }
  }, [dispatch, userId])

  useEffect(() => {
    if (createTeamSuccess) {
      dispatch(changeMessageModal({messageModal: true}))
    }
  }, [createTeamSuccess, dispatch])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const prepareFormData = (formData: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updatedPlayers = formData.players.map((player: any) => ({
      ...player,
      gender: player.gender?.value,
    }))
    return {...formData, players: JSON.stringify(updatedPlayers)}
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    const preparedData = prepareFormData(values)
    dispatch(createTeam(preparedData))
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  const handleSaveDraft = () => {
    formik.setFieldValue('is_draft', 1)
    formik.handleSubmit()
  }

  const handleSaveAndSubmit = async () => {
    await formik.setFieldValue('is_draft', 0)
    await formik.validateForm()
    if (Object.keys(formik.errors).length === 0) {
      await formik.submitForm()
    }
  }

  const handleAvatarChange = (file: File | null) => {
    setAvatarImage(file)
    formik.setFieldValue('logo', file || '')
  }
  const handleCreateApplication = () => {
    setShowForm(true)
  }

  const renderForm = () => (
    <FormikProvider value={formik}>
      <form className="gap" onSubmit={formik.handleSubmit}>
        <div className="gap gap--50">
          <div className="gap gap--15">
            <Title title="Команда" />
            <div className="background-form background-form--profile">
              <Avatar
                profile={avatarImage?.name || 'camera.svg'}
                onFileChange={handleAvatarChange}
              />
              <CommandsForm formik={formik} />
            </div>
          </div>
          <div className="gap gap--15">
            <Title title="Состав команды" />
            <div className="background-form background-form--profile">
              <CompoundCommandsList formik={formik} players={formik.values.players} />
            </div>
          </div>
        </div>
      </form>
      <div className="saves">
        <ButtonForm className="save--green" title="Сохранить" onClick={handleSaveDraft} />
        <ButtonForm title="Сохранить и отправить на проверку" onClick={handleSaveAndSubmit} />
      </div>
    </FormikProvider>
  )

  return (
    <div className="container">
      <div className="gap gap--20">
        <div className="gap gap--20">
          <CrumbsList routes={crumbsRoutes} />
          <div className="gap gap--25">
            <div className="gap">
              <div className="gap gap--row gap--15">
                <div className="gap gap--50 gap--row-2">
                  <Title title="Регистрация команды" className="title--uppercase" />
                </div>
                <ButtonsList buttonsData={filteredButtons} />
              </div>
            </div>
          </div>
        </div>
        {teamTemps.length === 0 && !showForm ? renderForm() : null}
        {teamTemps.length > 0 && !showForm ? (
          <RequestApplicationList temps={teamTemps} type="team" />
        ) : null}
        <div className={!showForm ? 'saves' : 'hidden'}>
          <ButtonForm
            className={teamTemps.length > 0 ? 'save' : 'hidden'}
            title="Создать заявку"
            onClick={handleCreateApplication}
          />
        </div>
        {showForm && renderForm()}
      </div>
    </div>
  )
}

export default RegistrationCommandsPage
