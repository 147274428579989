import React from 'react'
import AccordionItem from '../accordion-item/accordion-item'
import {HdbkFaq, HdbkMainProvision} from '../../../api'

function AccordionList({items}: {items: HdbkFaq[] | HdbkMainProvision[]}) {
  return (
    <ul className="accordion">
      {items.map((item) => {
        let itemId
        if ('faq_id' in item) {
          itemId = item.faq_id
        } else if ('provision_id' in item) {
          itemId = item.provision_id
        }
        return <AccordionItem key={itemId} data={item} />
      })}
    </ul>
  )
}

export default AccordionList
