import {ApiSettingsSocialsGet200Response} from '../../../api'
import NameSpace from '../../constants/api-constants'
import {State} from '../index'

export const getSocialsLoadingStatus = (state: State): boolean =>
  state[NameSpace.Socials].isSocialsLoading
export const getSocialsErrorStatus = (state: State): boolean =>
  state[NameSpace.Socials].isSocialsError
export const getSocials = (state: State): ApiSettingsSocialsGet200Response | null =>
  state[NameSpace.Socials].socials
