import React from 'react'
import {MatchSeries} from '../../../api'
import ChessLink from '../chess-link/chess-link'

function ChessCell({seriesArray}: {seriesArray: MatchSeries[]}) {
  return (
    <td className="table__cell">
      <div className="table__wrapper">
        {seriesArray.map((series) => (
          <ChessLink series={series} key={series.series_id} />
        ))}
      </div>
    </td>
  )
}
export default ChessCell
