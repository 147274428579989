import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import CommandList from '../command-list/command-list'
import {
  fetchTeam,
  fetchTeamPlayers,
  fetchSeasons,
  fetchTeamsSeasonStats,
  fetchTeamsStats,
  fetchTeamsRegionStats,
} from '../../../core/store/api-actions'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getTeam} from '../../../core/store/teams-data/selectors'
import {getTeamPlayers} from '../../../core/store/team-players-data/selectors'
import {getActiveSeason, getCaptain} from '../../../core/utils/array-prepare-helpers'
import {getTeamsStats} from '../../../core/store/teams-stats-data/selectors'
import {getSeasons} from '../../../core/store/seasons-data/selectors'
import {getTeamsSeasonStats} from '../../../core/store/teams-season-stats-data/selectors'
import {getTeamsRegionStats} from '../../../core/store/teams-region-stats-data/selectors'

function CommandsItem() {
  const dispatch = useAppDispatch()
  const {id: teamId} = useParams()
  const team = useAppSelector(getTeam)
  const teamPlayers = useAppSelector(getTeamPlayers)
  const seasons = useAppSelector(getSeasons)
  const activeSeason = getActiveSeason(seasons)
  const teamsCommonStats = useAppSelector(getTeamsStats)
  const teamCommonStats = teamsCommonStats.length > 0 ? teamsCommonStats[0] : null
  const teamsSeasonStats = useAppSelector(getTeamsSeasonStats)
  const teamSeasonStats = teamsSeasonStats.length > 0 ? teamsSeasonStats[0] : null
  const teamsRegionStats = useAppSelector(getTeamsRegionStats)
  const teamRegionStats = teamsRegionStats.length > 0 ? teamsRegionStats[0] : null
  const captain = getCaptain(teamPlayers)

  useEffect(() => {
    dispatch(fetchTeam(Number(teamId)))
    dispatch(fetchTeamPlayers(Number(teamId)))
    dispatch(fetchTeamsStats({teamId: Number(teamId)}))
  }, [dispatch, teamId])

  useEffect(() => {
    dispatch(fetchSeasons())
  }, [dispatch])

  useEffect(() => {
    if (activeSeason) {
      dispatch(
        fetchTeamsSeasonStats({
          seasonId: activeSeason.season_id,
          teamId: Number(teamId),
        }),
      )
    }
  }, [dispatch, activeSeason, teamId])

  useEffect(() => {
    if (team?.region_id) {
      dispatch(
        fetchTeamsRegionStats({
          regionId: team?.region_id,
          teamId: team.team_id,
        }),
      )
    }
  }, [dispatch, team])

  return (
    <div className="background background--command">
      <div className="background__wrapper">
        <svg className="background__logo" width="1295" height="745">
          <use xlinkHref="images/sprite.svg#background-logo-big" />
        </svg>
      </div>
      <div className="command">
        <h2 className="command__title">{team?.full_name}</h2>
        <div className="command__wrap">
          <img
            className="command__img"
            src={
              team?.avatar?.includes('logo_placeholder_100x100.svg')
                ? '/images/default/team.svg'
                : team?.avatar
            }
            width="150"
            height="150"
            alt={team?.full_name}
          />
          <CommandList team={team} captain={captain} />
          <div className="command__block">
            <div className="command__content">
              <svg className="command__icon" width="36" height="36">
                <use xlinkHref="images/sprite.svg#ball" />
              </svg>
              <div className="command__texts">
                <p className="command__number">{teamCommonStats?.matches_games || '-'}</p>
                <p className="command__subtitle">игры</p>
              </div>
            </div>
            <div className="command__content">
              <svg className="command__icon" width="36" height="36">
                <use xlinkHref="images/sprite.svg#star" />
              </svg>
              <div className="command__texts">
                <p className="command__number">{teamSeasonStats?.wins || '-'}</p>
                <p className="command__subtitle">победы в сезоне</p>
              </div>
            </div>
            <div className="command__content">
              <svg className="command__icon" width="36" height="36">
                <use xlinkHref="images/sprite.svg#cup" />
              </svg>
              <div className="command__texts">
                <p className="command__number">{teamRegionStats?.wins || '-'}</p>
                <p className="command__subtitle"> победы в регионе</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommandsItem
