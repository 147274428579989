import React, {useEffect} from 'react'
import {BannerPositionEnum} from '../../../api'
import BannerItem from '../banner-item/banner-item'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getBanners, getBannersErrorStatus} from '../../../core/store/banners-data/selectors'
import {sortItemsByRank} from '../../../core/utils/array-prepare-helpers'
import {fetchBanners} from '../../../core/store/api-actions'
import Stub from '../stub/stub'

function BannerList({bannerPositions}: {bannerPositions: BannerPositionEnum[]}) {
  const dispatch = useAppDispatch()
  const banners = useAppSelector(getBanners)
  const bannersErrorStatus = useAppSelector(getBannersErrorStatus)
  const filteredBanners = banners.filter((banner) =>
    bannerPositions.includes(banner.position as BannerPositionEnum),
  )
  const rankedBanners = sortItemsByRank(filteredBanners)

  useEffect(() => {
    dispatch(fetchBanners())
  }, [dispatch])

  return (
    <>
      <div className={`gap gap--20 ${filteredBanners.length === 0 ? 'hidden' : ''}`}>
        {rankedBanners.map((banner) => (
          <BannerItem banner={banner} key={banner.banner_id} />
        ))}
      </div>
      <Stub
        type={bannersErrorStatus ? 'error' : ''}
        error={bannersErrorStatus ? 'Произошла ошибка при загрузке баннеров.' : ''}
      />
    </>
  )
}

export default BannerList
