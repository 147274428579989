import React from 'react'
import CrumbsList from '../../components/ordinary/crumbs-list/crumbs-list'
import Title from '../../components/ui/title/title'
import TranslationList from '../../components/simple/translation-list/translation-list'
import AppRoute from '../../core/constants/routes'

function StreamPage() {
  const crumbsRoutes = [AppRoute.Main, AppRoute.StreamPage]

  return (
    <div className="container-y">
      <div className="gap gap--20">
        <div className="container-x">
          <div className="gap gap--20">
            <CrumbsList routes={crumbsRoutes} />
            <Title title="Трансляции" />
          </div>
        </div>
        <TranslationList />
      </div>
    </div>
  )
}

export default StreamPage
