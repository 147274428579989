import {RoutesType} from '../types/app-route'

const AppRoute: RoutesType = {
  Main: {
    title: 'Главная',
    url: '/',
  },
  TournamentsPage: {
    title: 'Турниры',
    url: '/tournaments',
  },
  AboutStaticPages: {
    title: 'Статические страницы на странице О нас',
    url: '/about-pages',
  },
  AboutStaticPage: {
    title: 'Статическая страница на странице О нас',
    url: '/about-pages/:alias',
  },
  CurrentNewsPage: {
    title: 'Страница одной новости',
    url: '/posts/:id',
  },
  PostsPage: {
    title: 'Новости',
    url: '/posts',
  },
  AlbumsPage: {
    title: 'Фотоальбомы',
    url: '/albums',
  },
  AlbumPage: {
    title: 'Фотоальбом',
    url: '/albums/:id',
  },
  VideoPage: {
    title: 'Видео',
    url: '/videos/:id',
  },
  VideosPage: {
    title: 'Видео',
    url: '/videos',
  },
  LeaguePage: {
    title: 'Лига',
    url: '/league',
  },
  StreamPage: {
    title: 'Трансляции',
    url: '/stream',
  },
  MainProvisionsPage: {
    title: 'Основные положения серии',
    url: '/about-pages/main-provisions',
  },
  FaqPage: {
    title: 'FAQ',
    url: '/about-pages/faq',
  },
  TablesPage: {
    title: 'Таблицы и сетки',
    url: '/tables',
  },
  RatingPage: {
    title: 'Рейтинг',
    url: '/tables/rating',
  },
  RatingPageWithId: {
    title: 'Рейтинг',
    url: '/tables/rating/:id',
  },
  ChessPage: {
    title: 'Шахматка',
    url: '/tables/chess',
  },
  ChessPageWithId: {
    title: 'Шахматка',
    url: '/tables/chess/:id',
  },
  GridsPage: {
    title: 'Сетка Плей-офф',
    url: '/tables/grids',
  },
  GridsPageWithId: {
    title: 'Сетка Плей-офф',
    url: '/tables/grids/:id',
  },
  ProtocolsPage: {
    title: 'Протоколы',
    url: '/protocol',
  },
  ProtocolsPageWithId: {
    title: 'Протоколы Турнира',
    url: '/protocol/:id',
  },
  AboutPage: {
    title: 'О турнире',
    url: '/tournament',
  },
  AboutPageWithId: {
    title: 'О турнире',
    url: '/tournament/:id',
  },
  Error: {
    title: 'Страница ошибки',
    url: '*',
  },
  CommandsRatingPage: {
    title: 'Участники',
    url: '/commands-rating',
  },
  PlayersRatingPage: {
    title: 'Участники',
    url: '/players-rating',
  },
  MediaPage: {
    title: 'Медиа',
    url: '/media',
  },
  PlayerStatPage: {
    title: 'Статистика игрока',
    url: '/players-stat',
  },
  PlayerStatPageWithId: {
    title: 'Статистика игрока',
    url: '/players-stat/:id',
  },
  PlayerMeetPage: {
    title: 'Личные встречи',
    url: '/player-meet',
  },
  PlayerMeetPageWithId: {
    title: 'Личные встречи',
    url: '/player-meet/:id',
  },
  PlayerHistoryPage: {
    title: 'История игрока',
    url: '/player-history',
  },
  PlayerHistoryPageWithId: {
    title: 'История игрока',
    url: '/player-history/:id',
  },
  CommandMatchesPage: {
    title: 'Матчи',
    url: '/command-matches',
  },
  CommandMatchesPageWithId: {
    title: 'Матчи',
    url: '/command-matches/:id',
  },
  CommandLineUpPage: {
    title: 'Состав',
    url: '/command-line-up',
  },
  CommandLineUpPageWithId: {
    title: 'Состав',
    url: '/command-line-up/:id',
  },
  CommandCalendarPage: {
    title: 'Календарь',
    url: '/command-calendar',
  },
  CommandCalendarPageWithId: {
    title: 'Календарь',
    url: '/command-calendar/:id',
  },
  RegistrationCommandsPage: {
    title: 'Регистрация команды',
    url: '/registration-commands',
  },
  RegistrationTournamentPage: {
    title: 'Регистрация турнира',
    url: '/registration-tournament',
  },
  PersonalPage: {
    title: 'Личный кабинет',
    url: '/personal',
  },
  PaymentPage: {
    title: 'Оплата',
    url: '/payment',
  },
  MatchPage: {
    title: 'Матч',
    url: '/match',
  },
  MatchPageWithId: {
    title: 'Матч',
    url: '/match/:id',
  },
  RequestTournamentPage: {
    title: 'Страница заявки турнира',
    url: '/registration-tournament/:id',
  },
  RequestCommandPage: {
    title: 'Страница заявки команды',
    url: '/registration-commands/:id',
  },
  PaymentProcessPage: {
    title: 'Статус платежа',
    url: '/payment-process',
  },
}

export default AppRoute
