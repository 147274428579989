import React from 'react'
import {TournamentTemp, TeamTemp} from '../../../api'
import RequestApplicationItem from '../request-application-item/request-application-item'

function RequestApplicationList({
  temps,
  type,
}: {
  temps: (TournamentTemp | TeamTemp)[]
  type: 'tournament' | 'team'
}) {
  return (
    <ul className="gap gap--20">
      {temps.map((temp) => (
        <li
          key={
            type === 'team' ? (temp as TeamTemp).team_id : (temp as TournamentTemp).tournament_id
          }>
          <RequestApplicationItem temp={temp} type={type} />
        </li>
      ))}
    </ul>
  )
}

export default RequestApplicationList
