import React from 'react'
import Profile from '../profile/profile'
import NavigationList from '../navigation-list/navigation-list'
import Logo from '../../ui/logo/logo'

function Header() {
  return (
    <header className="header">
      <Logo className="logo--header" width="115" height="60" />
      <NavigationList />
      <Profile />
    </header>
  )
}

export default Header
