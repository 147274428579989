import React, {useEffect, useState} from 'react'
import Sorting from '../sorting/sorting'
import RatingCommandsRow from '../rating-commands-row/rating-commands-row'
import {RatingTeam, StatsTeam} from '../../../api'
import {findTeamStatsById, sortTeamsRating} from '../../../core/utils/array-prepare-helpers'
import {teamsRatingSorters} from '../../../core/constants/sorters'
import {useAppSelector} from '../../../core/hooks'
import {getTeamsRatingSorterStatus} from '../../../core/store/teams-rating-data/selectors'
import {ItemsForSort} from '../../../core/constants/filters'

function RatingCommandsTable({
  teamsRating,
  teamsStats,
}: {
  teamsRating: RatingTeam[]
  teamsStats: StatsTeam[]
}) {
  const [sortedTeamsRating, setSortedTeamsRating] = useState<RatingTeam[]>(teamsRating)
  const sorterStatus = useAppSelector(getTeamsRatingSorterStatus)

  useEffect(() => {
    let updatedTeamsRating = [...(teamsRating || [])]
    if (sorterStatus) {
      updatedTeamsRating = sortTeamsRating(updatedTeamsRating, teamsStats, sorterStatus)
    }
    setSortedTeamsRating(updatedTeamsRating)
  }, [teamsRating, sorterStatus, teamsStats])

  return (
    <div className={`table-scroll ${teamsRating.length === 0 ? 'hidden' : ''}`}>
      <div className="table-border">
        <table className="table">
          <thead className="table__thead">
            <tr className="table__row">
              <th className="table__th table__number">
                <p />
              </th>
              {teamsRatingSorters.map((teamRatingSorters) => (
                <th className="table__th" key={teamRatingSorters}>
                  <Sorting title={teamRatingSorters} items={ItemsForSort.TeamsRating} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table__tbody">
            {sortedTeamsRating.map((teamRating, index) => (
              <RatingCommandsRow
                key={teamRating.team_id}
                index={index}
                teamRating={teamRating}
                teamStats={findTeamStatsById(teamsStats, teamRating.team_id)}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default RatingCommandsTable
