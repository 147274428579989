import React from 'react'
import {Link} from 'react-router-dom'

function Button({to, className, text}: {to?: string; className?: string; text?: string}) {
  return (
    <Link className={`button ${className || ''}`} to={to || '/'}>
      <p className="button__link">{text}</p>
      <div className="button__block">
        <svg className="button__icon" width="6" height="12">
          <use xlinkHref="images/sprite.svg#arrow" />
        </svg>
      </div>
    </Link>
  )
}

export default Button
