import React from 'react'
import {LinkType} from '../../../core/types/common'
import TabsItem from '../tabs-item/tabs-item'

function TabsList({tabsData}: {tabsData: LinkType[]}) {
  return (
    <ul className="tabs">
      {tabsData.map((tab) => (
        <TabsItem key={tab.title} tab={tab} />
      ))}
    </ul>
  )
}

export default TabsList
