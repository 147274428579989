import React from 'react'

function CalendarYear({
  currentYear,
  onChangeDate,
}: {
  currentYear: number
  onChangeDate: (type: string, date?: Date) => void
}) {
  return (
    <div className="calendar__years">
      <button
        type="button"
        className="calendar__arrow calendar__arrow--left"
        onClick={() => onChangeDate('prevYear')}>
        <svg className="calendar__icon" width="7" height="14">
          <use xlinkHref="images/sprite.svg#arrow" />
        </svg>
      </button>
      <p className="calendar__year">{currentYear}</p>
      <button type="button" className="calendar__arrow" onClick={() => onChangeDate('nextYear')}>
        <svg className="calendar__icon" width="7" height="14">
          <use xlinkHref="images/sprite.svg#arrow" />
        </svg>
      </button>
    </div>
  )
}

export default CalendarYear
