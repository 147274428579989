/* eslint-disable no-param-reassign, import/no-cycle */
import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {fetchTournament, fetchTournaments, fetchTournamentsMine} from '../api-actions'
import {TournamentsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {FilterOption} from '../../types/common'

const initialState: TournamentsState = {
  isTournamentsLoading: false,
  isTournamentLoading: false,
  isTournamentsError: false,
  isTournamentError: false,
  tournaments: [],
  currentTournament: null,
  searchString: '',
  filterSeasonStatus: {
    value: 'all',
    label: 'все сезоны',
  },
  filterRegionStatus: {
    value: 'all',
    label: 'все регионы',
  },
}

export const tournamentsData = createSlice({
  name: NameSpace.Tournaments,
  initialState,
  reducers: {
    filteringTournamentsBySeason: (
      state,
      action: PayloadAction<{filterSeasonStatus: FilterOption}>,
    ) => {
      const {filterSeasonStatus} = action.payload
      state.filterSeasonStatus = filterSeasonStatus
    },
    filteringTournamentsByRegion: (
      state,
      action: PayloadAction<{filterRegionStatus: FilterOption}>,
    ) => {
      const {filterRegionStatus} = action.payload
      state.filterRegionStatus = filterRegionStatus
    },
    filteringTournamentsByString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload
    },
    resetTournamentsFilters: (state) => {
      state.filterSeasonStatus = initialState.filterSeasonStatus
      state.filterRegionStatus = initialState.filterRegionStatus
      state.searchString = initialState.searchString
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTournaments.pending, (state) => {
        state.isTournamentsLoading = true
      })
      .addCase(fetchTournaments.fulfilled, (state, action) => {
        state.tournaments = action.payload
        state.isTournamentsLoading = false
      })
      .addCase(fetchTournaments.rejected, (state) => {
        state.isTournamentsError = true
        state.isTournamentsLoading = false
      })
      .addCase(fetchTournament.pending, (state) => {
        state.isTournamentLoading = true
      })
      .addCase(fetchTournament.fulfilled, (state, action) => {
        state.currentTournament = action.payload
        state.isTournamentLoading = false
      })
      .addCase(fetchTournament.rejected, (state) => {
        state.isTournamentError = true
        state.isTournamentLoading = false
      })
      .addCase(fetchTournamentsMine.pending, (state) => {
        state.isTournamentsLoading = true
      })
      .addCase(fetchTournamentsMine.fulfilled, (state, action) => {
        state.tournaments = action.payload
        state.isTournamentsLoading = false
      })
      .addCase(fetchTournamentsMine.rejected, (state) => {
        state.isTournamentsError = true
        state.isTournamentsLoading = false
      })
  },
})

export const {
  filteringTournamentsBySeason,
  filteringTournamentsByRegion,
  filteringTournamentsByString,
  resetTournamentsFilters,
} = tournamentsData.actions
