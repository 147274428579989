import React from 'react'
import {TabsType} from '../../../core/types/common'
import SwitchItem from '../switch-item/switch-item'

function SwitchList({
  selectedTab,
  onTabClick,
  tabsData,
}: {
  selectedTab: TabsType
  onTabClick: (tab: TabsType) => void
  tabsData: TabsType[]
}) {
  return (
    <ul className="switch">
      {tabsData.map((tab) => (
        <SwitchItem
          key={tab.tabType}
          title={tab.title}
          className={selectedTab === tab ? 'switch__item--active' : ''}
          onClick={() => onTabClick(tab)}
        />
      ))}
    </ul>
  )
}

export default SwitchList
