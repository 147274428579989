import React, {ReactElement} from 'react'
import Footer from '../../simple/footer/footer'
import Header from '../../simple/header/header'
import MainWrapper from '../../simple/main-wrapper/main-wrapper'
import Modals from '../../simple/modals/modals'

function Layout({children}: {children: ReactElement}) {
  return (
    <>
      <div className="body-container">
        <Header />
        <MainWrapper>{children}</MainWrapper>
        <Footer />
      </div>
      <Modals />
    </>
  )
}

export default Layout
