import React from 'react'
import {Link} from 'react-router-dom'
import AppRoute from '../../../core/constants/routes'

function Logo({className, width, height}: {className?: string; width: string; height: string}) {
  return (
    <Link className={`logo ${className || ''}`} to={AppRoute.Main.url}>
      <svg className="logo__icon" width={width} height={height}>
        <use xlinkHref="images/sprite.svg#logo" />
      </svg>
    </Link>
  )
}

export default Logo
