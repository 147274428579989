/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice} from '@reduxjs/toolkit'
import {TeamsRegionStatsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {fetchTeamsRegionStats} from '../api-actions'

const initialState: TeamsRegionStatsState = {
  teamsRegionStatsLoading: false,
  teamsRegionStatsError: false,
  teamsRegionStats: [],
}

const teamsRegionStatsData = createSlice({
  name: NameSpace.TeamsRegionStats,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTeamsRegionStats.pending, (state) => {
        state.teamsRegionStatsLoading = true
      })
      .addCase(fetchTeamsRegionStats.fulfilled, (state, action) => {
        state.teamsRegionStats = action.payload
        state.teamsRegionStatsLoading = false
      })
      .addCase(fetchTeamsRegionStats.rejected, (state) => {
        state.teamsRegionStatsError = true
        state.teamsRegionStatsLoading = false
      })
  },
})

export default teamsRegionStatsData
