import React from 'react'
import FooterInfo from '../footer-info/footer-info'
import FooterList from '../footer-list/footer-list'
import PartnersList from '../../ordinary/partners-list/partners-list'
import SocialList from '../../smart/social-list/social-list'
import Logo from '../../ui/logo/logo'
import {FooterDocumentArray} from '../../../core/constants/navigations'

function Footer() {
  const currentYear = new Date().getFullYear()

  return (
    <footer id="footer" className="footer">
      <div className="footer__container">
        <div className="footer__nav">
          <FooterInfo />
          <FooterList footerLinksData={FooterDocumentArray} />
          <PartnersList />
        </div>
        <Logo className="logo--footer" width="145" height="85" />
        <SocialList />
      </div>
      <div className="footer__content">
        <p className="footer__copyright">Клубная теннисная лига © 2005-{currentYear}</p>
      </div>
    </footer>
  )
}

export default Footer
