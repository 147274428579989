import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import GameItem from '../../ordinary/game-item/game-item'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getMatches} from '../../../core/store/matches-data/selectors'
import {fetchMatches} from '../../../core/store/api-actions'
import Stub from '../../ordinary/stub/stub'

function GameList() {
  const dispatch = useAppDispatch()
  const {id} = useParams()
  const matches = useAppSelector(getMatches)

  useEffect(() => {
    dispatch(
      fetchMatches({
        seriesId: Number(id),
        _with: ['team1', 'team2', 'matchPlayers1', 'matchPlayers2', 'periods'],
      }),
    )
  }, [dispatch, id])

  return (
    <div className="gap gap--0">
      <ul className="game">
        {matches.map((match, index) => (
          <GameItem key={match.match_id} match={match} index={index} />
        ))}
      </ul>
      <Stub type={matches.length === 0 ? 'absence' : ''} />
    </div>
  )
}

export default GameList
