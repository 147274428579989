import React, {useState} from 'react'
import RatingTable from '../rating-table/rating-table'
import Pagination from '../pagination/pagination'
import {RoundTableInner} from '../../../api'
import {RATING_PER_PAGE} from '../../../core/constants/common'

function Rating({roundTablesInner}: {roundTablesInner?: RoundTableInner[]}) {
  const [currentPage, setCurrentPage] = useState(0)
  const rowsPerPage = RATING_PER_PAGE
  const pageCount = Math.ceil((roundTablesInner?.length || 0) / rowsPerPage)
  const indexOfLastRow = (currentPage + 1) * rowsPerPage
  const indexOfFirstRow = indexOfLastRow - rowsPerPage
  const currentRoundTablesInner = roundTablesInner
    ? roundTablesInner.slice(indexOfFirstRow, indexOfLastRow)
    : []

  const handlePageClick = (selectedPage: {selected: number}) => {
    setCurrentPage(selectedPage.selected)
  }

  return (
    <div className="gap gap--40">
      <div className="gap gap--0">
        <RatingTable roundTablesInner={currentRoundTablesInner} />
      </div>
      <Pagination pageCount={pageCount} forcePage={currentPage} onPageClick={handlePageClick} />
    </div>
  )
}

export default Rating
