/* eslint-disable import/no-cycle */
import {configureStore} from '@reduxjs/toolkit'
import redirect from './middlewares/redirect'
import rootReducer from './root-reducer'
import api from '../api'

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: api,
      },
    }).concat(redirect),
})

export type State = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store
