import React from 'react'

const ChessIndicator = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div className="chess__line">
      <div className="chess__indicator" ref={ref} />
    </div>
  )
})

ChessIndicator.displayName = 'ChessIndicator'
export default ChessIndicator
