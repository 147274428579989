import React from 'react'

function MatchHead() {
  return (
    <div className="match__grid">
      <p className="match__title">дата</p>
      <div className="match__wrap">
        <p className="match__title match__end">команда</p>
        <p className="match__title match__center">счет</p>
        <p className="match__title">команда</p>
      </div>
      <p className="match__title">место проведения</p>
    </div>
  )
}

export default MatchHead
