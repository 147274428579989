import NameSpace from '../../constants/api-constants'
import {StatsTeam} from '../../../api'
import {State} from '../index'

export const getTeamsSeasonStatsLoading = (state: State): boolean =>
  state[NameSpace.TeamsSeasonStats].teamsSeasonStatsLoading
export const getTeamsSeasonStatsError = (state: State): boolean =>
  state[NameSpace.TeamsSeasonStats].teamsSeasonStatsError
export const getTeamsSeasonStats = (state: State): StatsTeam[] =>
  state[NameSpace.TeamsSeasonStats].teamsSeasonStats
