import NameSpace from '../../constants/api-constants'
import {ApiUsersDesireRolesGet200ResponseInner} from '../../../api'

import {State} from '../index'

export const getDesireRolesLoadingStatus = (state: State): boolean =>
  state[NameSpace.DesireRoles].desireRolesLoading
export const getDesireRolesErrorStatus = (state: State): boolean =>
  state[NameSpace.DesireRoles].desireRolesError
export const getDesireRoles = (state: State): ApiUsersDesireRolesGet200ResponseInner[] =>
  state[NameSpace.DesireRoles].desireRoles
