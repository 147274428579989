export const RATING_PER_PAGE = 10
export const CONTENT_PER_PAGE = 12
export const LEAGUES_PER_PAGE = 12
export const TOURNAMENTS_PER_PAGE = 6
export const MAX_PAGINATION_ITEMS = 10
export const MATCH_SERIES_PER_PAGE = 8
export const COMMAND_MATCH_SERIES_PER_PAGE = 5
export const PAGESIZE = 0
export enum SendRequestStatus {
  Success = 'SUCCESS',
  UnSuccess = 'UN_SUCCESS',
  Pending = 'PENDING',
  Normal = 'NORMAL',
}

export const sendErrorText =
  'Сервер не отвечает. Пожалуйста, попробуйте позже или проверьте ваше интернет-соединение.'

export const eventTypeColors = {
  home: '#009B34',
  away: '#9C1F00',
  'home-away': '#DBA507',
  booked: '#B0D9FF',
}

export const tournamentTypes = {
  league: 'Лига',
  commercial: 'Турнир',
  undefined: 'Не известно',
}

export const matchTypes = {
  free: 'свободный',
  'single-man': 'одиночный | мужчины',
  'single-woman': 'одиночный | женщины',
  'single-mix': 'одиночный | смешанный',
  'pair-man': 'парный | мужчины',
  'pair-woman': 'парный | женщины',
  'pair-mix': 'парный | смешанный',
  undefined: 'тип матча не указан',
}

export const genderOptions = [
  {
    value: 'man',
    label: 'мужчина',
  },
  {
    value: 'woman',
    label: 'женщина',
  },
]
