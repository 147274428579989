import React from 'react'
import {StatsPlayer} from '../../../api'

function StatsRow({playerStats}: {playerStats: StatsPlayer}) {
  return (
    <tr className="table__row table__row--border">
      <td className="table__cell">
        <div className="table__link table__start">
          <p className="table__name">Одиночный</p>
        </div>
      </td>
      <td className="table__cell">{playerStats.matches_games || 0}</td>
      <td className="table__cell">{playerStats.efficiency || 0}</td>
      <td className="table__cell">{playerStats.wins || 0}</td>
      <td className="table__cell">{playerStats.losses || 0}</td>
    </tr>
  )
}

export default StatsRow
