import React from 'react'
import {useLocation} from 'react-router-dom'
import {NavigationWithSubMenu} from '../../../core/types/navigation'
import NavList from '../nav-list/nav-list'

function NavigationItem({
  headerNavigation,
  isActive,
  onHoverClick,
  onMouseLeave,
  isHoverMode,
}: {
  headerNavigation: NavigationWithSubMenu
  isActive: boolean
  onHoverClick: () => void
  onMouseLeave: () => void
  isHoverMode: boolean
}) {
  const location = useLocation()
  const isCurrentPage = headerNavigation.submenu?.some(
    (submenu) => submenu.url === location.pathname,
  )

  return (
    <li
      className={`navigation__item ${isCurrentPage ? 'navigation__item--active' : ''} ${isActive ? 'navigation__item--open' : ''}`}
      onMouseEnter={isHoverMode ? onHoverClick : undefined}
      onClick={!isHoverMode ? onHoverClick : undefined}
      onMouseLeave={isHoverMode ? onMouseLeave : undefined}>
      <button type="button" className="navigation__link">
        <p className="navigation__text">{headerNavigation.title}</p>
        <svg className="navigation__icon" width="15" height="7">
          <use xlinkHref="images/sprite.svg#drop-arrow" />
        </svg>
      </button>
      <NavList headerNavigation={headerNavigation} isActive={isActive} onClick={onHoverClick} />
    </li>
  )
}

export default NavigationItem
