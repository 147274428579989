/* eslint-disable react-hooks/rules-of-hooks */
import React, {useState} from 'react'
import Select from 'react-select'
import {DropdownPlaceholders, DropdownTypes, ItemsForFilter} from '../../../core/constants/filters'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {
  getLeaguesFilterRegionStatus,
  getLeaguesFilterSeasonStatus,
  getLeaguesFilterStageStatus,
} from '../../../core/store/leagues-data/selectors'
import {FilterOption} from '../../../core/types/common'
import {
  filteringLeaguesByRegion,
  filteringLeaguesBySeason,
  filteringLeaguesByStage,
} from '../../../core/store/leagues-data/leagues-data'
import {getVideosFilterSeasonStatus} from '../../../core/store/videos-data/selectors'
import {getAlbumsFilterSeasonStatus} from '../../../core/store/albums-data/selectors'
import {getPostsFilterSeasonStatus} from '../../../core/store/posts-data/selectors'
import {filteringVideosBySeason} from '../../../core/store/videos-data/videos-data'
import {filteringAlbumsBySeason} from '../../../core/store/albums-data/albums-data'
import {filteringPostsBySeason} from '../../../core/store/posts-data/posts-data'
import {
  getTournamentsFilterRegionStatus,
  getTournamentsFilterSeasonStatus,
} from '../../../core/store/tournaments-data/selectors'
import {
  filteringTournamentsByRegion,
  filteringTournamentsBySeason,
} from '../../../core/store/tournaments-data/tournaments-data'
import {
  getCalendarTournamentStageFilter,
  getCalendarTournamentTypeFilter,
} from '../../../core/store/calendar-data/selectors'
import {
  changeTournamentStageFilter,
  changeTournamentTypeFilter,
} from '../../../core/store/calendar-data/calendar-data'
import getTeamMatchSeriesSeasonFilter from '../../../core/store/team-match-series-data/selectors'
import {filteringTeamMatchSeriesBySeason} from '../../../core/store/team-match-series-data/team-match-series-data'
import {
  getTeamsRatingFilterRegionStatus,
  getTeamsRatingFilterSeasonStatus,
} from '../../../core/store/teams-rating-data/selectors'
import {
  filteringTeamsRatingByRegion,
  filteringTeamsRatingBySeason,
} from '../../../core/store/teams-rating-data/teams-rating-data'
import {getPlayersRatingFilterSeasonStatus} from '../../../core/store/players-rating-data/selectors'
import {filteringPlayersRatingBySeason} from '../../../core/store/players-rating-data/players-rating-data'
import getPlayerInfoFilterSeason from '../../../core/store/player-info-data/selectors'
import {filteringPlayerInfoBySeason} from '../../../core/store/player-info-data/player-info-data'

function Dropdown({
  className,
  options,
  type,
  items,
  onChangeCallback,
}: {
  className?: string
  options: FilterOption[]
  type: string
  items: string
  onChangeCallback?: (selectedOption: FilterOption) => void
}) {
  const dispatch = useAppDispatch()
  const [selectedTournament, setSelectedTournament] = useState<FilterOption | null>(null)
  const createPlaceholder = (type: string) => {
    switch (type) {
      case DropdownTypes.Season:
        return DropdownPlaceholders.Season
      case DropdownTypes.Region:
        return DropdownPlaceholders.Region
      case DropdownTypes.Stage:
        return DropdownPlaceholders.Stage
      case DropdownTypes.TournamentType:
        return DropdownPlaceholders.TournamentType
      case DropdownTypes.Tournaments:
        return DropdownPlaceholders.Tournaments
      default:
        return DropdownTypes.Undefined
    }
  }

  const createValue = (items: string): FilterOption | null => {
    switch (type) {
      case DropdownTypes.Season:
        switch (items) {
          case ItemsForFilter.Leagues:
            return useAppSelector(getLeaguesFilterSeasonStatus)
          case ItemsForFilter.Tournaments:
            return useAppSelector(getTournamentsFilterSeasonStatus)
          case ItemsForFilter.Videos:
            return useAppSelector(getVideosFilterSeasonStatus)
          case ItemsForFilter.Albums:
            return useAppSelector(getAlbumsFilterSeasonStatus)
          case ItemsForFilter.Posts:
            return useAppSelector(getPostsFilterSeasonStatus)
          case ItemsForFilter.TeamMatchSeriesArray:
            return useAppSelector(getTeamMatchSeriesSeasonFilter)
          case ItemsForFilter.TeamsRating:
            return useAppSelector(getTeamsRatingFilterSeasonStatus)
          case ItemsForFilter.PlayersRating:
            return useAppSelector(getPlayersRatingFilterSeasonStatus)
          case ItemsForFilter.PlayerInfo:
            return useAppSelector(getPlayerInfoFilterSeason)
          default:
            return null
        }
      case DropdownTypes.Region:
        switch (items) {
          case ItemsForFilter.Leagues:
            return useAppSelector(getLeaguesFilterRegionStatus)
          case ItemsForFilter.Tournaments:
            return useAppSelector(getTournamentsFilterRegionStatus)
          case ItemsForFilter.TeamsRating:
            return useAppSelector(getTeamsRatingFilterRegionStatus)
          default:
            return null
        }
      case DropdownTypes.Stage:
        switch (items) {
          case ItemsForFilter.Leagues:
            return useAppSelector(getLeaguesFilterStageStatus)
          case ItemsForFilter.MatchSeriesArray:
            return useAppSelector(getCalendarTournamentStageFilter)
          default:
            return null
        }
      case DropdownTypes.TournamentType:
        switch (items) {
          case ItemsForFilter.MatchSeriesArray:
            return useAppSelector(getCalendarTournamentTypeFilter)
          default:
            return null
        }
      case DropdownTypes.Tournaments:
        return selectedTournament
      default:
        return null
    }
  }

  const handleFilterChange = (selectedOption: FilterOption | null) => {
    if (selectedOption) {
      switch (type) {
        case DropdownTypes.Season:
          switch (items) {
            case ItemsForFilter.Leagues:
              dispatch(filteringLeaguesBySeason({filterSeasonStatus: selectedOption}))
              break
            case ItemsForFilter.Tournaments:
              dispatch(filteringTournamentsBySeason({filterSeasonStatus: selectedOption}))
              break
            case ItemsForFilter.Videos:
              dispatch(filteringVideosBySeason({filterSeasonStatus: selectedOption}))
              break
            case ItemsForFilter.Albums:
              dispatch(filteringAlbumsBySeason({filterSeasonStatus: selectedOption}))
              break
            case ItemsForFilter.Posts:
              dispatch(filteringPostsBySeason({filterSeasonStatus: selectedOption}))
              break
            case ItemsForFilter.TeamMatchSeriesArray:
              dispatch(filteringTeamMatchSeriesBySeason({filterSeasonStatus: selectedOption}))
              break
            case ItemsForFilter.TeamsRating:
              dispatch(filteringTeamsRatingBySeason({filterSeasonStatus: selectedOption}))
              break
            case ItemsForFilter.PlayersRating:
              dispatch(filteringPlayersRatingBySeason({filterSeasonStatus: selectedOption}))
              break
            case ItemsForFilter.PlayerInfo:
              dispatch(filteringPlayerInfoBySeason({filterSeasonStatus: selectedOption}))
              break
            default:
              break
          }
          break
        case DropdownTypes.Region:
          switch (items) {
            case ItemsForFilter.Leagues:
              dispatch(filteringLeaguesByRegion({filterRegionStatus: selectedOption}))
              break
            case ItemsForFilter.Tournaments:
              dispatch(filteringTournamentsByRegion({filterRegionStatus: selectedOption}))
              break
            case ItemsForFilter.TeamsRating:
              dispatch(filteringTeamsRatingByRegion({filterRegionStatus: selectedOption}))
              break
            default:
              break
          }
          break
        case DropdownTypes.Stage:
          switch (items) {
            case ItemsForFilter.Leagues:
              dispatch(filteringLeaguesByStage({filterStageStatus: selectedOption}))
              break
            case ItemsForFilter.MatchSeriesArray:
              dispatch(changeTournamentStageFilter({tournamentStageFilter: selectedOption}))
              break
            default:
              break
          }
          break
        case DropdownTypes.TournamentType:
          switch (items) {
            case ItemsForFilter.MatchSeriesArray:
              dispatch(changeTournamentTypeFilter({tournamentTypeFilter: selectedOption}))
              break
            default:
              break
          }
          break
        case DropdownTypes.Tournaments:
          setSelectedTournament(selectedOption)
          break
        default:
          break
      }
      if (onChangeCallback) {
        onChangeCallback(selectedOption)
      }
    }
  }

  return (
    <Select
      defaultValue={options[0]}
      options={options}
      isSearchable={false}
      placeholder={createPlaceholder(type)}
      onChange={handleFilterChange}
      classNamePrefix="select"
      className={`select ${className || ''}`}
      value={createValue(items)}
    />
  )
}

export default Dropdown
