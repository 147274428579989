import {matchPath} from 'react-router-dom'
import {Area} from 'react-easy-crop'
import AppRoute from '../constants/routes'
import {ItemsForFilter} from '../constants/filters'
import {Player, Round, Team, Tournament, TournamentTypeEnum} from '../../api'

export function isSafari(): boolean {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export function range(start: number, end: number, step: number = 1): number[] {
  const result = []
  for (let i = start; i < end; i += step) {
    result.push(i)
  }
  return result
}

export function getItemForPageType(locationPathname: string) {
  if (matchPath({path: AppRoute.VideosPage.url}, locationPathname)) {
    return ItemsForFilter.Videos
  }
  if (matchPath({path: AppRoute.AlbumsPage.url}, locationPathname)) {
    return ItemsForFilter.Albums
  }
  return ItemsForFilter.Posts
}

export function generateTournamentButtonsArray(tournament: Tournament | null) {
  return [
    {
      title: 'Таблицы и сетки',
      url: `${AppRoute.RatingPage.url}/${tournament?.tournament_id}`,
    },
    {
      title: 'Протоколы',
      url: `${AppRoute.ProtocolsPage.url}/${tournament?.tournament_id}`,
    },
    {
      title: `О ${tournament?.type === TournamentTypeEnum.Commercial ? 'турнире' : 'лиге'}`,
      url: `${AppRoute.AboutPage.url}/${tournament?.tournament_id}`,
      hidden: tournament?.type !== TournamentTypeEnum.Commercial,
    },
  ]
}

export function generateTournamentTabsArray(
  tournamentId: string | undefined,
  lapRounds: Round[],
  playoffRounds: Round[],
) {
  return [
    {
      title: 'Рейтинг',
      url: `${AppRoute.RatingPage.url}/${tournamentId}`,
      hidden: lapRounds.length === 0,
    },
    {
      title: 'Таблица',
      url: `${AppRoute.ChessPage.url}/${tournamentId}`,
      hidden: lapRounds.length === 0,
    },
    {
      title: 'Сетка',
      url: `${AppRoute.GridsPage.url}/${tournamentId}`,
      hidden: playoffRounds.length === 0,
    },
  ]
}

export function generatePlayerButtonsArray(player: Player | null) {
  return [
    {title: 'Статистика игрока', url: `${AppRoute.PlayerStatPage.url}/${player?.player_id}`},
    {title: 'Личные встречи', url: `${AppRoute.PlayerMeetPage.url}/${player?.player_id}`},
    {title: 'История игрока', url: `${AppRoute.PlayerHistoryPage.url}/${player?.player_id}`},
  ]
}

export function generateTeamButtonsArray(team: Team | null) {
  return [
    {title: 'Календарь игр', url: `${AppRoute.CommandCalendarPage.url}/${team?.team_id}`},
    {title: 'Матчи', url: `${AppRoute.CommandMatchesPage.url}/${team?.team_id}`},
    {title: 'Состав', url: `${AppRoute.CommandLineUpPage.url}/${team?.team_id}`},
  ]
}

export const getCroppedImg = (
  imageSrc: string,
  crop: Area,
  outputType: 'base64' | 'file',
): Promise<string | File> => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = imageSrc
    image.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      if (!ctx) {
        reject(new Error('Failed to get canvas context'))
        return
      }
      canvas.width = crop.width
      canvas.height = crop.height
      ctx.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height)
      if (outputType === 'base64') {
        const base64Image = canvas.toDataURL('image/png')
        resolve(base64Image)
      } else {
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], 'cropped_image.png', {type: 'image/png'})
            resolve(file)
          } else {
            reject(new Error('Failed to create Blob'))
          }
        }, 'image/png')
      }
    }
    image.onerror = () => {
      reject(new Error('Failed to load image'))
    }
  })
}

export function getStatusConfig(status?: string, className?: string) {
  const baseClass = `clue ${className || ''}`

  switch (status) {
    case 'draft':
      return {
        iconUrl: 'images/sprite.svg#draft',
        statusText: 'Черновик',
        containerClass: `${baseClass} clue--draft`,
      }
    case 'accepted':
      return {
        iconUrl: 'images/sprite.svg#star',
        statusText: 'Принято',
        containerClass: baseClass,
      }
    case 'rejected':
      return {
        iconUrl: 'images/sprite.svg#cross',
        statusText: 'Отклонено',
        containerClass: baseClass,
      }
    case 'on-approval':
      return {
        iconUrl: 'images/sprite.svg#star',
        statusText: 'На рассмотрении',
        containerClass: baseClass,
      }
    default:
      return {
        iconUrl: 'images/sprite.svg#unknown',
        statusText: 'Отправлено',
        containerClass: baseClass,
      }
  }
}

export function fixUrl(url: string) {
  if (url.startsWith('http://')) {
    return url.replace('http://', 'https://')
  }
  return url
}
