import React from 'react'
import {Link} from 'react-router-dom'
import {Match} from '../../../api'
import {matchTypes} from '../../../core/constants/common'
import AppRoute from '../../../core/constants/routes'

function GameItem({match, index}: {match: Match; index: number}) {
  const hasPlayed =
    match.gf !== null && match.gf !== undefined && match.ga !== null && match.ga !== undefined
  const periodsPlayed = match.periods?.some(
    (period) =>
      period.gf !== null &&
      period.gf !== undefined &&
      period.ga !== null &&
      period.ga !== undefined,
  )
  const shouldHide = match.periods && match.periods.length > 0 ? !periodsPlayed : !hasPlayed

  return (
    <li className={`game__item ${shouldHide ? 'hidden' : ''}`}>
      <div className="game__head">
        <p className="game__title">Матч {index + 1}</p>
        <div className="game__content">
          <p className="game__subtitle">{matchTypes[match.type || 'undefined']}</p>
        </div>
      </div>
      <div className="game__grid">
        <div className="game__container">
          <div className="game__players">
            {match.matchPlayers1?.map((player) => (
              <Link
                className="game__link"
                to={`${AppRoute.PlayerStatPage.url}/${player.player_id}`}
                key={player.player_id}>
                <img
                  className="game__img"
                  width="40"
                  height="40"
                  src={
                    player.player?.avatar?.includes('photo_placeholder_100x100.svg')
                      ? '/images/default/player.svg'
                      : player.player?.avatar
                  }
                  alt={`${player.player?.first_name} ${player.player?.last_name}`}
                />
                <p className="game__text">
                  {player.player?.first_name} {player.player?.last_name}
                </p>
              </Link>
            ))}
            <Link
              className={`game__link ${match.matchPlayers1?.length === 0 ? '' : 'hidden'}`}
              to={`${AppRoute.CommandCalendarPage.url}/${match.team1_id}`}>
              <img
                className="game__img"
                width="40"
                height="40"
                src={
                  match.team1?.avatar?.includes('logo_placeholder_100x100.svg')
                    ? '/images/default/team.svg'
                    : match.team1?.avatar
                }
                alt={match.team1?.full_name}
              />
              <p className="game__text">{match.team1?.full_name}</p>
            </Link>
          </div>
          <div
            className={`game__win ${
              match.gf !== undefined &&
              match.ga !== undefined &&
              match.gf >= 0 &&
              match.ga >= 0 &&
              !(match.gf === null && match.ga === null) &&
              match.gf > match.ga
                ? ''
                : 'hidden'
            }`}>
            <div className="game__point" />
            <p className="game__victory">Победа</p>
          </div>
        </div>
        <div className="game__block">
          {match.periods?.map((period) => {
            const periodPlayed =
              period.gf !== null &&
              period.gf !== undefined &&
              period.ga !== null &&
              period.ga !== undefined
            return (
              <div className={`game__scores ${periodPlayed ? '' : 'hidden'}`} key={period.id}>
                <p
                  className={`game__number ${
                    period.gf !== undefined && period.ga !== undefined && period.gf > period.ga
                      ? 'game__number--win'
                      : ''
                  }`}>
                  {period.gf === null ? '-' : period.gf}
                </p>
                <p className="game__points">:</p>
                <p
                  className={`game__number ${
                    period.gf !== undefined && period.ga !== undefined && period.ga > period.gf
                      ? 'game__number--win'
                      : ''
                  }`}>
                  {period.ga === null ? '-' : period.ga}
                </p>
              </div>
            )
          })}
          <div className={`game__scores ${match.periods?.length === 0 ? '' : 'hidden'}`}>
            <p
              className={`game__number ${
                match.gf !== undefined && match.ga !== undefined && match.gf > match.ga
                  ? 'game__number--win'
                  : ''
              }`}>
              {match.gf === null ? '-' : match.gf}
            </p>
            <p className="game__points">:</p>
            <p
              className={`game__number ${
                match.gf !== undefined && match.ga !== undefined && match.ga > match.gf
                  ? 'game__number--win'
                  : ''
              }`}>
              {match.ga === null ? '-' : match.ga}
            </p>
          </div>
        </div>
        <div className="game__container game__container--right">
          <div className="game__players">
            {match.matchPlayers2?.map((player) => (
              <Link
                className="game__link"
                to={`${AppRoute.PlayerStatPage.url}/${player.player_id}`}
                key={player.player_id}>
                <img
                  className="game__img"
                  width="40"
                  height="40"
                  src={
                    player.player?.avatar?.includes('photo_placeholder_100x100.svg')
                      ? '/images/default/player.svg'
                      : player.player?.avatar
                  }
                  alt={`${player.player?.first_name} ${player.player?.last_name}`}
                />
                <p className="game__text">
                  {player.player?.first_name} {player.player?.last_name}
                </p>
              </Link>
            ))}
            <Link
              className={`game__link ${match.matchPlayers2?.length === 0 ? '' : 'hidden'}`}
              to={`${AppRoute.CommandCalendarPage.url}/${match.team2_id}`}>
              <img
                className="game__img"
                width="40"
                height="40"
                src={
                  match.team2?.avatar?.includes('logo_placeholder_100x100.svg')
                    ? '/images/default/team.svg'
                    : match.team2?.avatar
                }
                alt={match.team2?.full_name}
              />
              <p className="game__text">{match.team2?.full_name}</p>
            </Link>
          </div>
          <div
            className={`game__win ${
              match.ga !== undefined &&
              match.gf !== undefined &&
              match.ga >= 0 &&
              match.gf >= 0 &&
              !(match.ga === null && match.gf === null) &&
              match.ga > match.gf
                ? ''
                : 'hidden'
            }`}>
            <div className="game__point" />
            <p className="game__victory">Победа</p>
          </div>
        </div>
      </div>
    </li>
  )
}

export default GameItem
