import NameSpace from '../../constants/api-constants'
import {State} from '../index'

export const getAuthorizationModalStatus = (state: State): boolean =>
  state[NameSpace.Modals].authorizationModal
export const getSendRecoveryCodeModalStatus = (state: State): boolean =>
  state[NameSpace.Modals].sendRecoveryCodeModal
export const getRecoveryPasswordModalStatus = (state: State): boolean =>
  state[NameSpace.Modals].recoveryPasswordModal
export const getRecoveryPasswordSuccessModalStatus = (state: State): boolean =>
  state[NameSpace.Modals].recoverPasswordSuccessModal
export const getMessageModalStatus = (state: State): boolean => state[NameSpace.Modals].messageModal
