import React, {useEffect} from 'react'
import {useAppSelector} from '../../../core/hooks'
import {
  getAuthorizationModalStatus,
  getMessageModalStatus,
  getRecoveryPasswordModalStatus,
  getRecoveryPasswordSuccessModalStatus,
  getSendRecoveryCodeModalStatus,
} from '../../../core/store/modals-data/selectors'
import {isSafari} from '../../../core/utils/common-helpers'
import ModalAuthorization from '../../smart/modal-authorization/modal-authorization'
import ModalSendRecoveryCode from '../../smart/modal-send-recovery-code/modal-send-recovery-code'
import ModalRecoveryPassword from '../../smart/modal-recovery-password/modal-recovery-password'
import ModalVerification from '../../smart/modal-verification/modal-verification'
import ModalRecoverySuccess from '../../smart/modal-recovery-success/modal-recovery-success'

function Modals() {
  const isAuthorizationModalOpen = useAppSelector(getAuthorizationModalStatus)
  const isMessageModalOpen = useAppSelector(getMessageModalStatus)
  const isSendRecoveryCodeModalOpen = useAppSelector(getSendRecoveryCodeModalStatus)
  const isRecoveryPasswordModalOpen = useAppSelector(getRecoveryPasswordModalStatus)
  const isRecoveryPasswordSuccessModalOpen = useAppSelector(getRecoveryPasswordSuccessModalStatus)
  const isModalOpen =
    isAuthorizationModalOpen ||
    isMessageModalOpen ||
    isSendRecoveryCodeModalOpen ||
    isRecoveryPasswordModalOpen ||
    isRecoveryPasswordSuccessModalOpen

  useEffect(() => {
    const addClass = () => {
      if (isSafari()) {
        document.body.classList.toggle('fixed-safari', isModalOpen)
        document.documentElement.classList.toggle('fixed-safari', isModalOpen)
      } else {
        document.body.classList.toggle('fixed', isModalOpen)
      }
    }
    addClass()
    return () => {
      addClass()
    }
  }, [isModalOpen])

  return (
    <>
      <ModalAuthorization />
      <ModalSendRecoveryCode />
      <ModalRecoveryPassword />
      <ModalVerification />
      <ModalRecoverySuccess />
    </>
  )
}

export default Modals
