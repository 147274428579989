import React from 'react'
import {Link} from 'react-router-dom'
import {MatchSeries} from '../../../api'
import AppRoute from '../../../core/constants/routes'

function ChessLink({series}: {series: MatchSeries}) {
  return (
    <Link
      className="table__link"
      to={`${AppRoute.MatchPage.url}/${series.series_id}`}
      key={series.series_id}>
      {`${series.score1} : ${series.score2}`}
    </Link>
  )
}
export default ChessLink
