/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice} from '@reduxjs/toolkit'
import {TeamsStatsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {fetchTeamsStats} from '../api-actions'

const initialState: TeamsStatsState = {
  teamsStatsLoading: false,
  teamsStatsError: false,
  teamsStats: [],
}

const teamsStatsData = createSlice({
  name: NameSpace.TeamsStats,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTeamsStats.pending, (state) => {
        state.teamsStatsLoading = true
      })
      .addCase(fetchTeamsStats.fulfilled, (state, action) => {
        state.teamsStats = action.payload
        state.teamsStatsLoading = false
      })
      .addCase(fetchTeamsStats.rejected, (state) => {
        state.teamsStatsError = true
        state.teamsStatsLoading = false
      })
  },
})

export default teamsStatsData
