import React from 'react'
import {Tag} from '../../../api'

function HashtagItem({tag}: {tag: Tag}) {
  return (
    <li className="hashtag__item">
      <p className="hashtag__link">{tag.name}</p>
    </li>
  )
}

export default HashtagItem
