import React, {useEffect, useState} from 'react'
import CalendarMonthItem from '../calendar-month-item/calendar-month-item'

function CalendarMonthList({
  currentYear,
  onChangeDate,
  currentDate,
}: {
  currentYear: number
  onChangeDate: (type: string, date?: Date) => void
  currentDate: Date
}) {
  const [monthsOfYear, setMonthsOfYear] = useState<Date[]>([])

  useEffect(() => {
    const newMonthsOfYear: Date[] = []
    for (let month = 0; month < 12; month += 1) {
      newMonthsOfYear.push(new Date(currentYear, month, 1))
    }

    if (window.innerWidth <= 780) {
      if (currentDate > newMonthsOfYear[newMonthsOfYear.length - 4]) {
        for (let month = 0; month < 12; month += 1) {
          newMonthsOfYear.push(new Date(currentYear + 1, month, 1))
        }
      } else if (currentDate < newMonthsOfYear[3]) {
        for (let month = 0; month < 12; month += 1) {
          newMonthsOfYear.unshift(new Date(currentYear - 1, month, 1))
        }
      }
    }
    setMonthsOfYear(newMonthsOfYear)
  }, [currentYear, currentDate])

  return (
    <div className="calendar__wrapper">
      <button
        type="button"
        className="calendar__button calendar__button--left"
        onClick={() => onChangeDate('prevMonth')}>
        <svg className="calendar__icon" width="7" height="14">
          <use xlinkHref="images/sprite.svg#arrow" />
        </svg>
      </button>
      <ul className="calendar__months">
        {monthsOfYear.map((currentMonth) => (
          <CalendarMonthItem
            currentMonth={currentMonth}
            onChangeDate={onChangeDate}
            currentDate={currentDate}
            key={currentMonth.getDate()}
          />
        ))}
      </ul>
      <button type="button" className="calendar__button" onClick={() => onChangeDate('nextMonth')}>
        <svg className="calendar__icon" width="7" height="14">
          <use xlinkHref="images/sprite.svg#arrow" />
        </svg>
      </button>
    </div>
  )
}

export default CalendarMonthList
