import NameSpace from '../../constants/api-constants'
import {MatchSeries} from '../../../api'

import {State} from '../index'

export const getMatchSeriesArrayLoadingStatus = (state: State): boolean =>
  state[NameSpace.MatchSeries].isMatchSeriesArrayLoading
export const getMatchSeriesArrayErrorStatus = (state: State): boolean =>
  state[NameSpace.MatchSeries].isMatchSeriesArrayError
export const getMatchSeriesArray = (state: State): MatchSeries[] =>
  state[NameSpace.MatchSeries].matchSeriesArray
export const getMatchSeriesLoadingStatus = (state: State): boolean =>
  state[NameSpace.MatchSeries].isMatchSeriesLoading
export const getMatchSeriesErrorStatus = (state: State): boolean =>
  state[NameSpace.MatchSeries].isMatchSeriesError
export const getMatchSeries = (state: State): MatchSeries | null =>
  state[NameSpace.MatchSeries].matchSeries
