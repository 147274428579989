import NameSpace from '../../constants/api-constants'
import {State} from '../index'

export const getSendRecoveryCodeRequestStatus = (state: State): string =>
  state[NameSpace.SendRecoveryCode].sendCodeRequestStatus
export const getSendRecoveryCodeStatus = (state: State): boolean | undefined =>
  state[NameSpace.SendRecoveryCode].sendCodeStatus
export const getSendRecoveryCodeUserId = (state: State): number | undefined =>
  state[NameSpace.SendRecoveryCode].sendCodeUserId
export const getSendRecoveryCodeErrors = (state: State): object | undefined =>
  state[NameSpace.SendRecoveryCode].errors
