import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Dropdown from '../../smart/dropdown/dropdown'
import {DropdownTypes, ItemsForFilter} from '../../../core/constants/filters'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {transformTournamentsToOptions} from '../../../core/utils/array-prepare-helpers'
import PayButton from '../pay-button/pay-button'
import {Team, Tournament} from '../../../api'
import {fetchTeamPayment, fetchTournamentPayment} from '../../../core/store/api-actions'
import {getPayments} from '../../../core/store/payment-data/selectors'
import AppRoute from '../../../core/constants/routes'
import {FilterOption} from '../../../core/types/common'
import {resetPaymentState} from '../../../core/store/payment-data/payment-data'

function PaymentForm({
  role,
  team,
  tournaments,
}: {
  role: number
  team?: Team
  tournaments?: Tournament[]
}) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const paymentUrl = useAppSelector(getPayments)
  const tournamentsOptions = transformTournamentsToOptions(tournaments)
  const [selectedTournament, setSelectedTournament] = useState<FilterOption | null>(null)
  const [tournamentPrice, setTournamentPrice] = useState<number>(0)

  useEffect(() => {
    if (team?.team_id && team.payment_status === 'unpaid') {
      dispatch(fetchTeamPayment(Number(team.team_id)))
    }
  }, [dispatch, team?.team_id, team?.payment_status])

  useEffect(() => {
    if (role === 0) {
      navigate(AppRoute.Main.url)
    }
  }, [role, navigate])

  useEffect(() => {
    if (selectedTournament) {
      dispatch(resetPaymentState())
      dispatch(fetchTournamentPayment(Number(selectedTournament.value)))
      const selectedTournamentData = tournaments?.find(
        (tournament) => tournament.tournament_id === Number(selectedTournament.value),
      )
      if (selectedTournamentData?.product?.price) {
        setTournamentPrice(selectedTournamentData.product.price)
      } else {
        setTournamentPrice(0)
      }
    }
  }, [selectedTournament, dispatch, tournaments])

  const handlePaymentClick = () => {
    if (paymentUrl) {
      window.location.href = paymentUrl
    }
  }

  const handleTournamentChange = (selectedOption: FilterOption) => {
    setSelectedTournament(selectedOption)
  }

  return (
    <div className="payment">
      <div className={`payment__item ${role === 34 ? '' : 'hidden'}`}>
        <div className="payment__content">
          <p className="payment__title">Регистрация команды</p>
        </div>
        <div className="payment__pay">
          <p className="payment__price">{team?.product?.price || '0'} руб.</p>
          <PayButton
            title="Оплатить"
            className={`save--pay ${!paymentUrl ? 'save--disabled' : ''}`}
            onClick={handlePaymentClick}
          />
        </div>
      </div>
      <div className={`payment__item ${role === 66 ? '' : 'hidden'}`}>
        <div className="payment__content">
          <p className="payment__title">Регистрация турнира</p>
          <div className="form">
            <p className="form__text">Название турнира</p>
            <Dropdown
              className="select--registration"
              options={tournamentsOptions}
              type={DropdownTypes.Tournaments}
              items={ItemsForFilter.Tournaments}
              onChangeCallback={handleTournamentChange}
            />
          </div>
        </div>
        <div className="payment__pay">
          <p className="payment__price">{tournamentPrice} руб.</p>
          <PayButton
            title="Оплатить"
            className={`save--pay ${!paymentUrl ? 'save--disabled' : ''}`}
            onClick={handlePaymentClick}
          />
        </div>
      </div>
      {/* не готово на беке */}
      {/* <div className={`payment__item ${role === 34 ? "" : "hidden"}`}>
        <div className="payment__content">
          <p className="payment__title">Оплата за матч</p>
          <label className="form">
            <p className="form__text">Матч</p>
            <Dropdown
              className="select--registration"
              options={seasonsOptions}
              type={DropdownTypes.Season}
              items={ItemsForFilter.Tournaments}
            />
          </label>
        </div>
        <div className="payment__pay">
          <p className="payment__price">2 000 руб.</p>
          <PayButton title="Оплатить" className="save--pay" />
        </div>
      </div> */}
    </div>
  )
}

export default PaymentForm
