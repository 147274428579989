/* eslint-disable no-param-reassign, import/no-cycle */
import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {fetchLeagues, fetchTournament} from '../api-actions'
import {LeaguesState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {FilterOption} from '../../types/common'

const initialState: LeaguesState = {
  isLeaguesLoading: false,
  isLeagueLoading: false,
  isLeaguesError: false,
  isLeagueError: false,
  leagues: [],
  currentLeague: null,
  filterSeasonStatus: {
    value: 'all',
    label: 'все сезоны',
  },
  filterRegionStatus: {
    value: 'all',
    label: 'все регионы',
  },
  filterStageStatus: {
    value: 'all',
    label: 'все этапы',
  },
}

export const leaguesData = createSlice({
  name: NameSpace.Leagues,
  initialState,
  reducers: {
    filteringLeaguesBySeason: (
      state,
      action: PayloadAction<{filterSeasonStatus: FilterOption}>,
    ) => {
      const {filterSeasonStatus} = action.payload
      state.filterSeasonStatus = filterSeasonStatus
    },
    filteringLeaguesByRegion: (
      state,
      action: PayloadAction<{filterRegionStatus: FilterOption}>,
    ) => {
      const {filterRegionStatus} = action.payload
      state.filterRegionStatus = filterRegionStatus
    },
    filteringLeaguesByStage: (state, action: PayloadAction<{filterStageStatus: FilterOption}>) => {
      const {filterStageStatus} = action.payload
      state.filterStageStatus = filterStageStatus
    },
    resetLeaguesFilters: (state) => {
      state.filterSeasonStatus = initialState.filterSeasonStatus
      state.filterRegionStatus = initialState.filterRegionStatus
      state.filterStageStatus = initialState.filterStageStatus
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLeagues.pending, (state) => {
        state.isLeaguesLoading = true
      })
      .addCase(fetchLeagues.fulfilled, (state, action) => {
        state.leagues = action.payload
        state.isLeaguesLoading = false
      })
      .addCase(fetchLeagues.rejected, (state) => {
        state.isLeaguesError = true
        state.isLeaguesLoading = false
      })
      .addCase(fetchTournament.pending, (state) => {
        state.isLeagueLoading = true
      })
      .addCase(fetchTournament.fulfilled, (state, action) => {
        state.currentLeague = action.payload
        state.isLeagueLoading = false
      })
      .addCase(fetchTournament.rejected, (state) => {
        state.isLeagueError = true
        state.isLeagueLoading = false
      })
  },
})

export const {
  filteringLeaguesBySeason,
  filteringLeaguesByRegion,
  filteringLeaguesByStage,
  resetLeaguesFilters,
} = leaguesData.actions
