/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice} from '@reduxjs/toolkit'
import {SendRecoveryState} from '../../types/state'
import {SendRequestStatus} from '../../constants/common'
import NameSpace from '../../constants/api-constants'
import {sendRecoveryCode} from '../api-actions'

const initialState: SendRecoveryState = {
  sendCodeRequestStatus: SendRequestStatus.Normal,
  sendCodeStatus: undefined,
  sendCodeUserId: undefined,
  errors: undefined,
}

const sendRecoveryCodeData = createSlice({
  name: NameSpace.SendRecoveryCode,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(sendRecoveryCode.pending, (state) => {
        state.sendCodeRequestStatus = SendRequestStatus.Pending
      })
      .addCase(sendRecoveryCode.fulfilled, (state, action) => {
        state.sendCodeRequestStatus = SendRequestStatus.Success
        state.sendCodeStatus = action.payload.response.success
        state.sendCodeUserId = action.payload.response.user_id
        state.errors = action.payload.response.errors
      })
      .addCase(sendRecoveryCode.rejected, (state) => {
        state.sendCodeRequestStatus = SendRequestStatus.UnSuccess
      })
  },
})

export default sendRecoveryCodeData
