enum NameSpace {
  Players = 'PLAYERS',
  Teams = 'TEAMS',
  Posts = 'POSTS',
  Albums = 'ALBUMS',
  Videos = 'VIDEOS',
  Banners = 'BANNERS',
  Pages = 'PAGES',
  Tournaments = 'TOURNAMENTS',
  Leagues = 'LEAGUES',
  Seasons = 'SEASONS',
  Regions = 'REGIONS',
  Faqs = 'FAQS',
  Provisions = 'PROVISIONS',
  Rounds = 'ROUNDS',
  Matches = 'MATCHES',
  MatchSeries = 'MATCH_SERIES',
  TeamPlayers = 'TEAM_PLAYERS',
  Authorization = 'AUTHORIZATION',
  SendRecoveryCode = 'SEND_RECOVERY_CODE',
  RecoveryPassword = 'RECOVERY_PASSWORD',
  Users = 'USERS',
  DesireRoles = 'DESIRE_ROLES',
  Cities = 'CITIES',
  Zones = 'ZONES',
  TournamentsTemps = 'TOURNAMENTS_TEMPS',
  TeamsTemps = 'TEAMS_TEMPS',
  Modals = 'MODALS',
  Calendar = 'CALENDAR',
  TeamMatchSeries = 'TEAM_MATCH_SERIES',
  PlayerMatches = 'PLAYER_MATCHES',
  TeamsRating = 'TEAMS_RATING',
  PlayersRating = 'PLAYERS_RATING',
  PlayersStats = 'PLAYERS_STATS',
  TeamsStats = 'TEAMS_STATS',
  TeamsSeasonStats = 'TEAMS_SEASON_STATS',
  TeamsRegionStats = 'TEAMS_REGION_STATS',
  RoundTablesInner = 'ROUND_TABLES_INNER',
  PlayerTeams = 'PLAYER_TEAMS',
  PlayerInfo = 'PLAYER_INFO',
  CreateTeamTemps = 'CREATE_TEAM_TEMPS',
  CreateTournamentTemps = 'CREATE_TOURNAMENT_TEMPS',
  Payments = 'PAYMENTS',
  PaymentStatus = 'PAYMENT_STATUS',
  Socials = 'SOCIALS',
}

export default NameSpace
