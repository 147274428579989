/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice} from '@reduxjs/toolkit'
import {fetchCities} from '../api-actions'
import {CitiesState} from '../../types/state'
import NameSpace from '../../constants/api-constants'

const initialState: CitiesState = {
  isCitiesLoading: false,
  isCitiesError: false,
  cities: [],
}

const citiesData = createSlice({
  name: NameSpace.Cities,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCities.pending, (state) => {
        state.isCitiesLoading = true
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.cities = action.payload
        state.isCitiesLoading = false
      })
      .addCase(fetchCities.rejected, (state) => {
        state.isCitiesError = true
        state.isCitiesLoading = false
      })
  },
})

export default citiesData
