/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice} from '@reduxjs/toolkit'
import {TeamPlayersState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {fetchTeamPlayers} from '../api-actions'

const initialState: TeamPlayersState = {
  isTeamPlayersLoading: false,
  isTeamPlayersError: false,
  teamPlayers: [],
}

const teamPlayersData = createSlice({
  name: NameSpace.TeamPlayers,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTeamPlayers.pending, (state) => {
        state.isTeamPlayersLoading = true
      })
      .addCase(fetchTeamPlayers.fulfilled, (state, action) => {
        state.teamPlayers = action.payload
        state.isTeamPlayersLoading = false
      })
      .addCase(fetchTeamPlayers.rejected, (state) => {
        state.isTeamPlayersError = true
        state.isTeamPlayersLoading = false
      })
  },
})

export default teamPlayersData
