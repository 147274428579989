import React from 'react'
import {Link} from 'react-router-dom'
import {StatsPlayer, TeamPlayer} from '../../../api'
import AppRoute from '../../../core/constants/routes'

function PlayerItem({
  teamPlayer,
  playerStats,
}: {
  teamPlayer: TeamPlayer
  playerStats?: StatsPlayer
}) {
  return (
    <li className="player__item">
      <Link className="player__link" to={`${AppRoute.PlayerStatPage.url}/${teamPlayer.player_id}`}>
        <div className="player__content">
          <img
            className="player__img"
            width="150"
            height="150"
            src={
              teamPlayer.player?.avatar?.includes('photo_placeholder_100x100.svg')
                ? '/images/default/player.svg'
                : teamPlayer.player?.avatar
            }
            alt={`${teamPlayer.player?.first_name} ${teamPlayer.player?.last_name}`}
          />
          <div className="efficiency">
            <p className="efficiency__number">{playerStats?.efficiency || 0}</p>
            <p className="efficiency__text">КПД</p>
          </div>
        </div>
        <div className="player__full">
          <p className="player__name">{teamPlayer.player?.first_name}</p>
          <p className="player__surname">{teamPlayer.player?.last_name}</p>
        </div>
      </Link>
    </li>
  )
}

export default PlayerItem
