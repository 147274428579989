import NameSpace from '../../constants/api-constants'
import {Player} from '../../../api'

import {State} from '../index'

export const getPlayersLoadingStatus = (state: State): boolean =>
  state[NameSpace.Players].isPlayersLoading
export const getPlayersErrorStatus = (state: State): boolean =>
  state[NameSpace.Players].isPlayersError
export const getPlayers = (state: State): Player[] => state[NameSpace.Players].players
export const getPlayerLoadingStatus = (state: State): boolean =>
  state[NameSpace.Players].isPlayerLoading
export const getPlayerErrorStatus = (state: State): boolean =>
  state[NameSpace.Players].isPlayerError
export const getPlayer = (state: State): Player | null => state[NameSpace.Players].player
