import React from 'react'
import FilterTournamentsItem from '../../smart/filter-tournaments-item/filter-tournaments-item'
import {Tournament} from '../../../api'
import Stub from '../stub/stub'

function FilterTournaments({
  tournaments,
  tournamentsErrorStatus,
  filteredTournaments,
}: {
  tournaments: Tournament[]
  tournamentsErrorStatus: boolean
  filteredTournaments: Tournament[]
}) {
  return (
    <div className="gap gap--0">
      <ul className="filter filter--tournaments">
        <FilterTournamentsItem />
      </ul>
      <Stub
        type={
          tournamentsErrorStatus
            ? 'error'
            : tournaments.length > 0 && filteredTournaments.length === 0
              ? 'searchError'
              : tournaments.length === 0
                ? 'absence'
                : ''
        }
        error={tournamentsErrorStatus ? 'Произошла ошибка при загрузке турниров.' : ''}
      />
    </div>
  )
}

export default FilterTournaments
