import React, {useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import AppRoute from '../../core/constants/routes'
import CrumbsList from '../../components/ordinary/crumbs-list/crumbs-list'
import {useAppDispatch, useAppSelector} from '../../core/hooks'
import {
  getPaymentMessage,
  // getPaymentStatus,
  // getPaymentSuccess
} from '../../core/store/payment-status-data/selectors'
import {fetchCheckPaymentStatus} from '../../core/store/api-actions'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

function PaymentProcessPage() {
  const dispatch = useAppDispatch()
  const query = useQuery()
  const orderId = query.get('orderId')

  // const status = useAppSelector(getPaymentStatus)
  const message = useAppSelector(getPaymentMessage)
  // const success = useAppSelector(getPaymentSuccess)

  useEffect(() => {
    if (orderId) {
      dispatch(fetchCheckPaymentStatus(orderId))
    }
  }, [dispatch, orderId])

  const crumbsRoutes = [AppRoute.Main, AppRoute.PaymentProcessPage]
  return (
    <div className="container">
      <CrumbsList routes={crumbsRoutes} />
      <div className="mistake">
        <div className="background-form">
          <div className="personal">
            <p className="personal__text">{message}</p>
            <Link className="personal__link" to={AppRoute.Main.url}>
              Перейти на главную
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentProcessPage
