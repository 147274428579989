import React from 'react'
import {getStatusConfig} from '../../../core/utils/common-helpers'

function RequestStatus({status, className}: {status?: string; className?: string}) {
  const {iconUrl, statusText, containerClass} = getStatusConfig(status, className)

  return (
    <div className={containerClass}>
      <svg className="clue__icon" width="24" height="24">
        <use xlinkHref={iconUrl} />
      </svg>
      <p className="clue__text">{statusText}</p>
    </div>
  )
}

export default RequestStatus
