import React, {useEffect} from 'react'
import Title from '../../ui/title/title'
import Button from '../../ui/button/button'
import AppRoute from '../../../core/constants/routes'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import CalendarList from '../../ordinary/calendar-list/calendar-list'
import {fetchMatchSeriesArray} from '../../../core/store/api-actions'
import {
  getMatchSeriesArray,
  getMatchSeriesArrayErrorStatus,
} from '../../../core/store/match-series-data/selectors'
import {getTopMatchesByDate} from '../../../core/utils/array-prepare-helpers'
import Stub from '../../ordinary/stub/stub'

function MatchesWidget() {
  const dispatch = useAppDispatch()
  const matchSeriesArray = useAppSelector(getMatchSeriesArray)
  const matchSeriesArrayError = useAppSelector(getMatchSeriesArrayErrorStatus)
  const topMatchSeriesArray = getTopMatchesByDate(matchSeriesArray, 6)
  useEffect(() => {
    dispatch(
      fetchMatchSeriesArray({
        _with: ['team1', 'team2', 'tournament', 'round'],
      }),
    )
  }, [dispatch])

  return (
    <div className="gap gap--30">
      <div className="container-row">
        <div className="gap gap--row-2">
          <div className="gap gap--40 gap--row-3">
            <Title className="title--uppercase" title="Календарь" />
          </div>
          <Button className="button--full show-580" text="Подробнее" to={AppRoute.LeaguePage.url} />
        </div>
      </div>
      <div className="gap gap--0">
        <CalendarList matchSeriesArray={topMatchSeriesArray} />
        <Stub
          type={
            matchSeriesArrayError
              ? 'error'
              : matchSeriesArray.length > 0 && topMatchSeriesArray.length === 0
                ? 'searchError'
                : matchSeriesArray.length === 0
                  ? 'absenceMatch'
                  : ''
          }
          error={matchSeriesArrayError ? 'Произошла ошибка при загрузке серий матчей.' : ''}
        />
        <Button className="button--full hide-580" text="Подробнее" to={AppRoute.LeaguePage.url} />
      </div>
    </div>
  )
}

export default MatchesWidget
