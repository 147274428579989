import React, {useEffect} from 'react'
import SocialItem from '../../ordinary/social-item/social-item'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getSocials} from '../../../core/store/socials-data/selectors'
import {fetchSocials} from '../../../core/store/api-actions'

function SocialList() {
  const dispatch = useAppDispatch()
  const socials = useAppSelector(getSocials)

  useEffect(() => {
    dispatch(fetchSocials())
  }, [dispatch])

  if (!socials) {
    return null
  }

  return (
    <div className="social">
      {Object.entries(socials).map(([key, link]) =>
        link ? <SocialItem key={key} social={key} link={link} /> : null,
      )}
    </div>
  )
}

export default SocialList
