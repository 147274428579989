const matchesSliderConfig = {
  container: 'slider',
  controls: false,
  nav: false,
  items: 3,
  rewind: true,
  slideBy: 3,
  autoplay: true,
  autoplayTimeout: 4000,
  autoplayHoverPause: true,
  autoplayButtonOutput: false,
  speed: 1000,
  touch: true,
  mouseDrag: false,
  gutter: 0,
  responsive: {
    320: {
      items: 1,
    },
    480: {
      items: 2,
    },
    780: {
      items: 3,
    },
  },
}
export default matchesSliderConfig
