/* eslint-disable no-param-reassign */
import {createSlice} from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import {fetchSocials} from '../api-actions'
import NameSpace from '../../constants/api-constants'
import {SocialsState} from '../../types/state'

const initialState: SocialsState = {
  isSocialsLoading: false,
  isSocialsError: false,
  socials: null,
}

export const socialsData = createSlice({
  name: NameSpace.Socials,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSocials.pending, (state) => {
        state.isSocialsLoading = true
        state.isSocialsError = false
      })
      .addCase(fetchSocials.fulfilled, (state, action) => {
        state.socials = action.payload
        state.isSocialsLoading = false
      })
      .addCase(fetchSocials.rejected, (state) => {
        state.isSocialsError = true
        state.isSocialsLoading = false
      })
  },
})

export default socialsData
