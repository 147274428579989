import {TournamentsTempsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {TournamentTemp} from '../../../api'
import {State} from '../index'

export const getTournamentTempsLoadingStatus = (state: State): boolean =>
  state[NameSpace.TournamentsTemps].isTournamentsTempsLoading
export const getTournamentTempLoadingStatus = (state: State): boolean =>
  state[NameSpace.TournamentsTemps].isTournamentsTempLoading
export const getTournamentTempsErrorStatus = (
  state: State,
): TournamentsTempsState['isTournamentsTempsError'] =>
  state[NameSpace.TournamentsTemps].isTournamentsTempsError
export const getTournamentTempErrorStatus = (
  state: State,
): TournamentsTempsState['isTournamentsTempError'] =>
  state[NameSpace.TournamentsTemps].isTournamentsTempError
export const getTournamentTemps = (state: State): TournamentTemp[] =>
  state[NameSpace.TournamentsTemps].tournamentsTemps
export const getCurrentTournamentTemps = (state: State): TournamentTemp | null =>
  state[NameSpace.TournamentsTemps].currentTournamentsTemp
