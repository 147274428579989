import {TournamentsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {Tournament} from '../../../api'
import {FilterOption} from '../../types/common'
import {State} from '../index'

export const getTournamentsLoadingStatus = (state: State): boolean =>
  state[NameSpace.Tournaments].isTournamentsLoading
export const getTournamentLoadingStatus = (state: State): boolean =>
  state[NameSpace.Tournaments].isTournamentLoading
export const getTournamentsErrorStatus = (state: State): TournamentsState['isTournamentsError'] =>
  state[NameSpace.Tournaments].isTournamentsError
export const getTournamentErrorStatus = (state: State): TournamentsState['isTournamentError'] =>
  state[NameSpace.Tournaments].isTournamentError
export const getTournaments = (state: State): Tournament[] =>
  state[NameSpace.Tournaments].tournaments
export const getCurrentTournament = (state: State): Tournament | null =>
  state[NameSpace.Tournaments].currentTournament
export const getTournamentsFilterSeasonStatus = (state: State): FilterOption =>
  state[NameSpace.Tournaments].filterSeasonStatus
export const getTournamentsFilterRegionStatus = (state: State): FilterOption =>
  state[NameSpace.Tournaments].filterRegionStatus
export const getTournamentsSearchString = (state: State): string =>
  state[NameSpace.Tournaments].searchString
