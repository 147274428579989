import React from 'react'

function Next() {
  return (
    <div className="next">
      <p className="next__text">Смотрите так же</p>
    </div>
  )
}

export default Next
