import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import {useEffect, useState} from 'react'
import {getUser} from '../store/users-data/selectors'
import {registrationButtonsArray} from '../constants/navigations'
import {NavigationItem} from '../types/navigation'
import {AppDispatch, State} from '../store'

export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useAppSelector: TypedUseSelectorHook<State> = useSelector

export const useFilteredButtons = () => {
  const user = useAppSelector(getUser)
  const [filteredButtons, setFilteredButtons] = useState<NavigationItem[]>([])

  useEffect(() => {
    if (user?.role !== undefined) {
      const buttons = registrationButtonsArray.filter((button) => {
        if (user.role === 4096) {
          return true
        }
        if (user.role === 34) {
          return button.title === 'Регистрация команды' || button.title === 'Оплата'
        }
        if (user.role === 66) {
          return button.title === 'Регистрация турнира' || button.title === 'Оплата'
        }
        return false
      })
      setFilteredButtons(buttons)
    }
  }, [user])

  return filteredButtons
}
