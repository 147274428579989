import {format} from 'date-fns'
import {ru} from 'date-fns/locale'
import React from 'react'

export function simpleDateFormat(date: Date | null, dateFormat: string): string | null {
  if (date) {
    return format(date, dateFormat, {locale: ru})
  }
  return null
}

export function roundDateFormat(date: Date | null, dateFormat: string): string | undefined {
  if (date) {
    const formattedDate = format(date, dateFormat, {locale: ru})
    return dateFormat.includes('MMM') ? formattedDate.slice(0, -1) : formattedDate
  }
  return undefined
}

export function boldDayDateFormat(date: Date | null) {
  if (date) {
    return (
      <div className="date">
        <b>{format(date, 'dd')}</b>.{format(date, 'MM.yyyy')}
      </div>
    )
  }
  return null
}

export function yearDateFormat(date: Date | null) {
  if (date) {
    return format(date, 'yyyy')
  }
  return null
}

export function withTextDateFormat(date: Date | null, text: string) {
  if (date) {
    return (
      <div className="date">
        {text}
        <p>
          <b>{format(date, 'dd.MM.')}</b>
          {format(date, 'yyyy')} г.
        </p>
      </div>
    )
  }
  return null
}

export function calculateAge(birthDate: Date) {
  const today = new Date()
  return (
    today.getFullYear() -
    birthDate.getFullYear() -
    (today.getMonth() < birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())
      ? 1
      : 0)
  )
}
