import React from 'react'
import Select, {ActionMeta, SingleValue} from 'react-select'
import {FilterOption} from '../../../core/types/common'
import {SelectPlaceholders, SelectTypes} from '../../../core/constants/selects'

function FormSelect({
  options,
  type,
  value,
  name,
  error,
  onChangeSelect,
}: {
  options: FilterOption[]
  type: string
  value?: FilterOption
  name: string
  error?: boolean
  onChangeSelect: (
    selectedOption: SingleValue<FilterOption>,
    actionMeta: ActionMeta<FilterOption>,
  ) => void
}) {
  const createPlaceholder = (type: string): string => {
    switch (type) {
      case SelectTypes.DesireRoles:
        return SelectPlaceholders.DesireRoles
      case SelectTypes.Cities:
        return SelectPlaceholders.Cities
      case SelectTypes.Zones:
        return SelectPlaceholders.Zones
      case SelectTypes.Gender:
        return SelectPlaceholders.Gender
      default:
        return SelectPlaceholders.Undefined
    }
  }

  return (
    <Select
      options={options}
      isSearchable={false}
      placeholder={createPlaceholder(type)}
      onChange={(selectedOption, actionMeta) => onChangeSelect(selectedOption, actionMeta)}
      classNamePrefix="select"
      className={`select select--form ${error ? 'select--error' : ''}`}
      value={value}
      name={name}
      defaultValue={value}
    />
  )
}

export default FormSelect
