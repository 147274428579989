/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice} from '@reduxjs/toolkit'
import {fetchCheckPaymentStatus} from '../api-actions'
import {PaymentStatusState} from '../../types/state'
import NameSpace from '../../constants/api-constants'

const initialState: PaymentStatusState = {
  status: 'idle',
  message: '',
}

const paymentStatusData = createSlice({
  name: NameSpace.PaymentStatus,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCheckPaymentStatus.pending, (state) => {
        state.status = 'loading'
        state.message = 'Проверка статуса оплаты...'
      })
      .addCase(fetchCheckPaymentStatus.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.success = action.payload.success
        state.message = action.payload.success
          ? 'Оплата произведена успешно!'
          : 'Возникла ошибка при оплате'
      })
      .addCase(fetchCheckPaymentStatus.rejected, (state, action) => {
        state.status = 'failed'
        state.message = action.error.message || 'Ошибка связи с сервером'
      })
  },
})

export default paymentStatusData
