/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice} from '@reduxjs/toolkit'
import {TeamsSeasonStatsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {fetchTeamsSeasonStats} from '../api-actions'

const initialState: TeamsSeasonStatsState = {
  teamsSeasonStatsLoading: false,
  teamsSeasonStatsError: false,
  teamsSeasonStats: [],
}

const teamsSeasonStatsData = createSlice({
  name: NameSpace.TeamsSeasonStats,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTeamsSeasonStats.pending, (state) => {
        state.teamsSeasonStatsLoading = true
      })
      .addCase(fetchTeamsSeasonStats.fulfilled, (state, action) => {
        state.teamsSeasonStats = action.payload
        state.teamsSeasonStatsLoading = false
      })
      .addCase(fetchTeamsSeasonStats.rejected, (state) => {
        state.teamsSeasonStatsError = true
        state.teamsSeasonStatsLoading = false
      })
  },
})

export default teamsSeasonStatsData
