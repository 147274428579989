import React from 'react'
import CrumbsItem from '../crumbs-item/crumbs-item'
import {RouteType} from '../../../core/types/app-route'

function CrumbsList({routes}: {routes: RouteType[]}) {
  return (
    <ul className="crumbs">
      {routes.map((route) => (
        <CrumbsItem route={route} key={route.url} />
      ))}
    </ul>
  )
}

export default CrumbsList
