import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {LinkType} from '../../../core/types/common'

function TabsItem({tab}: {tab: LinkType}) {
  const {pathname} = useLocation()
  const isActive = pathname === tab.url
  const className = `tabs__item ${isActive ? 'tabs__item--active' : tab.hidden ? 'hidden' : ''}`

  return (
    <li className={className}>
      <Link to={tab.url} className="tabs__button">
        {tab.title}
      </Link>
    </li>
  )
}

export default TabsItem
