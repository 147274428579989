import {SeasonsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {HdbkSeason} from '../../../api'
import {State} from '../index'

export const getSeasonsLoadingStatus = (state: State): boolean =>
  state[NameSpace.Seasons].isSeasonsLoading
export const getSeasonsErrorStatus = (state: State): SeasonsState['isSeasonsError'] =>
  state[NameSpace.Seasons].isSeasonsError
export const getSeasons = (state: State): HdbkSeason[] => state[NameSpace.Seasons].seasons
