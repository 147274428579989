import React, {useEffect} from 'react'
import Dropdown from '../dropdown/dropdown'
import {DropdownTypes, ItemsForFilter} from '../../../core/constants/filters'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getSeasons} from '../../../core/store/seasons-data/selectors'
import {transformSeasonsToOptions} from '../../../core/utils/array-prepare-helpers'
import {fetchSeasons} from '../../../core/store/api-actions'

function FilterTeamMatchesItem() {
  const dispatch = useAppDispatch()
  const seasons = useAppSelector(getSeasons)
  const seasonsOptions = transformSeasonsToOptions(seasons)

  useEffect(() => {
    dispatch(fetchSeasons())
  }, [dispatch])

  return (
    <li className="filter__item">
      <Dropdown
        options={seasonsOptions}
        type={DropdownTypes.Season}
        items={ItemsForFilter.TeamMatchSeriesArray}
      />
    </li>
  )
}

export default FilterTeamMatchesItem
