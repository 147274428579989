import React from 'react'
import {Link} from 'react-router-dom'
import {Match} from '../../../api'
import AppRoute from '../../../core/constants/routes'
import {simpleDateFormat} from '../../../core/utils/date-time-helpers'

function MeetingStatsItem({match}: {match: Match}) {
  const startDateTime = match?.start_dt ? new Date(match.start_dt) : ''
  const startDate = startDateTime
    ? simpleDateFormat(startDateTime, 'dd MMMM yyyy')
    : 'дата не указана'

  return (
    <li className="meet__grid">
      <Link className="meet__parent" to={`${AppRoute.MatchPage.url}/${match.series_id}`}>
        <div className="meet__container">
          <div className="meet__players">
            {match.matchPlayers1?.map((player) => (
              <div className="meet__link" key={player.player?.player_id}>
                <img
                  className="meet__img"
                  src={
                    player.player?.avatar?.includes('photo_placeholder')
                      ? '/images/default/player.svg'
                      : player.player?.avatar
                  }
                  width="25"
                  height="25"
                  alt={`${player.player?.first_name.charAt(0)}. ${player.player?.last_name}`}
                />
                <p className="meet__text">{`${player.player?.first_name.charAt(0)}. ${player.player?.last_name}`}</p>
              </div>
            ))}
          </div>
          <div className="meet__players">
            {match.matchPlayers2?.map((player) => (
              <Link
                className="meet__link"
                to={`${AppRoute.MatchPage.url}/${match.series_id}`}
                key={player.player?.player_id}>
                <img
                  className="meet__img"
                  src={
                    player.player?.avatar?.includes('photo_placeholder')
                      ? '/images/default/player.svg'
                      : player.player?.avatar
                  }
                  width="25"
                  height="25"
                  alt={`${player.player?.first_name.charAt(0)}. ${player.player?.last_name}`}
                />
                <p className="meet__text">{`${player.player?.first_name.charAt(0)}. ${player.player?.last_name}`}</p>
              </Link>
            ))}
          </div>
        </div>
        <p className="meet__text hide-980">{startDate}</p>
        <p className="meet__text hide-980">{match.tournament?.full_name}</p>
        <div className="meet__content">
          {match.periods?.map((period) => (
            <div className="meet__scores" key={period.id}>
              <p
                className={`meet__number ${period.gf !== undefined && period.ga !== undefined && period.gf > period.ga ? 'meet__number--win' : ''}`}>
                {period.gf === null ? '-' : period.gf}
              </p>
              <p
                className={`meet__number ${period.gf !== undefined && period.ga !== undefined && period.ga > period.gf ? 'meet__number--win' : ''}`}>
                {period.ga === null ? '-' : period.ga}
              </p>
            </div>
          ))}
        </div>
        <div className="meet__block">
          <p className="meet__text">{startDate}</p>
          <p className="meet__text">{match.tournament?.full_name}</p>
        </div>
      </Link>
    </li>
  )
}

export default MeetingStatsItem
