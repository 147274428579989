/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react'
import {FieldArray} from 'formik'
import CompoundCommandsItem from '../compound-commands-item/compound-commands-item'
import {TeamTempPlayer, TeamTempPlayerRequest} from '../../../api'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function CompoundCommandsList({
  players,
  formik,
}: {
  players: (TeamTempPlayerRequest | TeamTempPlayer)[]
  formik: any
}) {
  const [numForms, setNumForms] = useState(players.length)

  const handleRemove = (arrayHelpers: any, index: number) => {
    if (numForms > 1) {
      arrayHelpers.remove(index)
      setNumForms(numForms - 1)
    }
  }

  const handleAdd = (arrayHelpers: any) => {
    arrayHelpers.push({
      first_name: '',
      last_name: '',
      birthday: '',
      gender: '',
      photo: '',
      ntrp: '',
    })
    setNumForms(numForms + 1)
  }

  return (
    <FieldArray
      name="players"
      render={(arrayHelpers) => (
        <ul className="compound">
          {players.length > 0 &&
            players.map((player, index) => (
              <CompoundCommandsItem
                formik={formik}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                index={index}
                player={player}
                onRemove={() => handleRemove(arrayHelpers, index)}
                onAdd={() => handleAdd(arrayHelpers)}
                disableRemove={numForms <= 1}
                numForms={numForms}
              />
            ))}
        </ul>
      )}
    />
  )
}

export default CompoundCommandsList
