import {StatsPlayer} from '../../../api'
import NameSpace from '../../constants/api-constants'

import {State} from '../index'

export const getPlayersStatsLoading = (state: State): boolean =>
  state[NameSpace.PlayersStats].playersStatsLoading
export const getPlayersStatsError = (state: State): boolean =>
  state[NameSpace.PlayersStats].playersStatsError
export const getPlayersStats = (state: State): StatsPlayer[] =>
  state[NameSpace.PlayersStats].playersStats
