import React, {useState} from 'react'
import AlbumItem from '../album-item/album-item'
import Fancybox from '../../smart/fancybox/fancybox'
import {AlbumPhoto} from '../../../api'
import Pagination from '../pagination/pagination'
import {CONTENT_PER_PAGE} from '../../../core/constants/common'

function AlbumList({photos}: {photos?: AlbumPhoto[]}) {
  const [currentPage, setCurrentPage] = useState(0)
  const photosPerPage = CONTENT_PER_PAGE
  const pageCount = Math.ceil((photos?.length || 0) / photosPerPage)

  const indexOfLastPhoto = (currentPage + 1) * photosPerPage
  const indexOfFirstPhoto = indexOfLastPhoto - photosPerPage
  const currentPhotos = photos ? photos.slice(indexOfFirstPhoto, indexOfLastPhoto) : []

  const handlePageClick = (selectedPage: {selected: number}) => {
    setCurrentPage(selectedPage.selected)
  }

  return (
    <>
      <Fancybox
        options={{
          Toolbar: true,
          Navigation: false,
          Carousel: {
            infinite: false,
          },
        }}>
        <ul className="album">
          {currentPhotos?.map((photo) => <AlbumItem key={photo.photo_id} photo={photo} />)}
        </ul>
      </Fancybox>
      <Pagination
        className="pagination--arrows"
        pageCount={pageCount}
        forcePage={currentPage}
        onPageClick={handlePageClick}
      />
    </>
  )
}

export default AlbumList
