import React from 'react'
import LeagueItem from '../league-item/league-item'
import {Tournament} from '../../../api'

function LeagueList({leagues}: {leagues: Tournament[]}) {
  return (
    <ul className={`league ${leagues.length === 0 ? 'hidden' : ''}`}>
      {leagues.map((league) => (
        <LeagueItem key={league?.tournament_id} league={league} />
      ))}
    </ul>
  )
}

export default LeagueList
