import {AlbumsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {Album} from '../../../api'
import {FilterOption} from '../../types/common'

import {State} from '../index'

export const getAlbumsLoadingStatus = (state: State): boolean =>
  state[NameSpace.Albums].isAlbumsLoading
export const getAlbumLoadingStatus = (state: State): boolean =>
  state[NameSpace.Albums].isAlbumLoading
export const getAlbumsErrorStatus = (state: State): AlbumsState['isAlbumsError'] =>
  state[NameSpace.Albums].isAlbumsError
export const getAlbumErrorStatus = (state: State): AlbumsState['isAlbumError'] =>
  state[NameSpace.Albums].isAlbumError
export const getAlbums = (state: State): Album[] => state[NameSpace.Albums].albums
export const getCurrentAlbum = (state: State): Album | null => state[NameSpace.Albums].currentAlbum
export const getAlbumsFilterSeasonStatus = (state: State): FilterOption =>
  state[NameSpace.Albums].filterSeasonStatus
