import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getRecoveryPasswordModalStatus} from '../../../core/store/modals-data/selectors'
import {
  changeRecoveryPasswordModal,
  changeRecoveryPasswordSuccessModal,
} from '../../../core/store/modals-data/modals-data'
import Title from '../../ui/title/title'
import {recoveryPassword} from '../../../core/store/api-actions'
import {recoveryPasswordValidationSchema} from '../../../core/utils/form-validate-helpers'
import {SendRequestStatus, sendErrorText} from '../../../core/constants/common'
import {
  getRecoveryPasswordErrors,
  getRecoveryPasswordRequestStatus,
  getRecoveryPasswordStatus,
  getRecoveryPasswordText,
} from '../../../core/store/recovery-password-data/selectors'
import {getSendRecoveryCodeUserId} from '../../../core/store/send-recovery-code-data/selectors'

function ModalRecoveryPassword() {
  const dispatch = useAppDispatch()
  const [firstShowPassword, setFirstShowPassword] = useState(false)
  const [secondShowPassword, setSecondShowPassword] = useState(false)
  const [requestError, setRequestError] = useState<string>('')
  const [recoveryText, setRecoveryText] = useState<string | undefined>(undefined)
  const isModalOpen = useAppSelector(getRecoveryPasswordModalStatus)
  const recoveryPasswordErrors = useAppSelector(getRecoveryPasswordErrors) || {}
  const recoveryPasswordStatus = useAppSelector(getRecoveryPasswordStatus)
  const recoveryPasswordRequestStatus = useAppSelector(getRecoveryPasswordRequestStatus)
  const recoveryPasswordUserId = useAppSelector(getSendRecoveryCodeUserId)
  const recoveryPasswordText = useAppSelector(getRecoveryPasswordText)

  const handleModalClose = () => {
    dispatch(changeRecoveryPasswordModal({recoveryPasswordModal: false}))
  }

  // eslint-disable-next-line
  const onSubmit = async (values: any) => {
    dispatch(recoveryPassword(values))
  }

  useEffect(() => {
    const successCodeSend =
      recoveryPasswordRequestStatus === SendRequestStatus.Success &&
      recoveryPasswordStatus &&
      recoveryPasswordUserId !== undefined
    if (successCodeSend) {
      dispatch(changeRecoveryPasswordModal({recoveryPasswordModal: false}))
      dispatch(changeRecoveryPasswordSuccessModal({recoveryPasswordSuccessModal: true}))
    }
  }, [recoveryPasswordStatus, recoveryPasswordRequestStatus, recoveryPasswordUserId, dispatch])

  useEffect(() => {
    if (recoveryPasswordRequestStatus === SendRequestStatus.UnSuccess) {
      setRequestError(sendErrorText)
    }
  }, [recoveryPasswordRequestStatus])

  const formik = useFormik({
    initialValues: {
      id: '',
      code: '',
      password: '',
      passwordRepeat: '',
    },
    validationSchema: recoveryPasswordValidationSchema,
    onSubmit,
  })

  useEffect(() => {
    formik.setFieldValue('id', recoveryPasswordUserId)
    // eslint-disable-next-line
  }, [recoveryPasswordUserId])

  useEffect(() => {
    setRecoveryText(recoveryPasswordText)
  }, [recoveryPasswordText])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e)
  }

  return (
    <div className={`modal ${isModalOpen ? '' : 'modal--close'}`}>
      <div className="modal__container">
        <div className="modal__scroll">
          <div className="modal__background">
            <svg className="modal__logo" width="1025" height="440">
              <use xlinkHref="images/sprite.svg#background-logo" />
            </svg>
            <div className="modal__column">
              <Title
                title={`Восстановление пароля пользователя с идентификатором ${formik.values.id}`}
                className="title--center title--white"
              />
              <ul className="modal__errors">
                <li className={`modal__error ${requestError === '' ? 'hidden' : ''}`}>
                  {requestError}
                </li>
                <li className={`modal__error ${recoveryText ? '' : 'hidden'}`}>{recoveryText}</li>
                {Object.values(recoveryPasswordErrors).map((error) => (
                  <li className="modal__error" key={error}>
                    {error}
                  </li>
                ))}
              </ul>
              <form className="modal__form" onSubmit={formik.handleSubmit}>
                <div className="modal__list">
                  <label className="form" htmlFor="recovery-code">
                    <p className="form__text">
                      Код из email
                      <span className={`form__help ${formik.errors.code ? '' : 'hidden'}`}>
                        {formik.errors.code}
                      </span>
                    </p>
                    <input
                      id="recovery-code"
                      className={`form__input ${formik.errors.code ? 'form__input--error' : ''}`}
                      type="text"
                      name="code"
                      placeholder="Введите код из email"
                      value={formik.values.code}
                      onChange={handleInputChange}
                    />
                  </label>
                  <label className="form form--password" htmlFor="recovery-password">
                    <p className="form__text">
                      Пароль
                      <span className={`form__help ${formik.errors.password ? '' : 'hidden'}`}>
                        {formik.errors.password}
                      </span>
                    </p>
                    <input
                      id="recovery-password"
                      className={`form__input ${formik.errors.password ? 'form__input--error' : ''}`}
                      type={firstShowPassword ? 'text' : 'password'}
                      placeholder="Придумайте пароль"
                      name="password"
                      value={formik.values.password}
                      onChange={handleInputChange}
                    />
                    <button
                      className={`form__control ${firstShowPassword ? 'form__control--view' : ''}`}
                      type="button"
                      onClick={() => setFirstShowPassword(!firstShowPassword)}
                    />
                  </label>
                  <label className="form form--password" htmlFor="recovery-passord-repeat">
                    <p className="form__text">
                      Подтверждение пароля
                      <span
                        className={`form__help ${formik.errors.passwordRepeat ? '' : 'hidden'}`}>
                        {formik.errors.passwordRepeat}
                      </span>
                    </p>
                    <input
                      id="recovery-passord-repeat"
                      className={`form__input ${formik.errors.passwordRepeat ? 'form__input--error' : ''}`}
                      type={secondShowPassword ? 'text' : 'password'}
                      placeholder="Повторите придуманный пароль"
                      name="passwordRepeat"
                      value={formik.values.passwordRepeat}
                      onChange={handleInputChange}
                    />
                    <button
                      className={`form__control ${secondShowPassword ? 'form__control--view' : ''}`}
                      type="button"
                      onClick={() => setSecondShowPassword(!secondShowPassword)}
                    />
                  </label>
                </div>
                <button className="modal__button modal__button--uppercase" type="submit">
                  Восстановить пароль
                </button>
              </form>
            </div>
          </div>
        </div>
        <button type="button" className="modal__close" onClick={handleModalClose}>
          <svg className="modal__icon" width="45" height="45">
            <use xlinkHref="images/sprite.svg#close" />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default ModalRecoveryPassword
