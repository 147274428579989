import NameSpace from '../../constants/api-constants'
import {Team} from '../../../api'
import {State} from '../index'

export const getTeamsLoadingStatus = (state: State): boolean =>
  state[NameSpace.Teams].isTeamsLoading
export const getTeamsErrorStatus = (state: State): boolean => state[NameSpace.Teams].isTeamsError
export const getTeams = (state: State): Team[] => state[NameSpace.Teams].teams
export const getTeamLoadingStatus = (state: State): boolean => state[NameSpace.Teams].isTeamLoading
export const getTeamErrorStatus = (state: State): boolean => state[NameSpace.Teams].isTeamError
export const getTeam = (state: State): Team | null => state[NameSpace.Teams].team
