/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {PlayerMatchesState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {SorterOption} from '../../types/common'

const initialState: PlayerMatchesState = {
  searchString: '',
  sorterStatus: {
    key: null,
    direction: 0,
  },
}

export const playerMatchesData = createSlice({
  name: NameSpace.PlayerMatches,
  initialState,
  reducers: {
    filteringPlayerMatchesByString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload
    },
    sortingPlayerMatches: (state, action: PayloadAction<{sorterStatus: SorterOption}>) => {
      const {sorterStatus} = action.payload
      state.sorterStatus = sorterStatus
    },
  },
})

export const {filteringPlayerMatchesByString, sortingPlayerMatches} = playerMatchesData.actions
