import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import AppRoute from '../../../core/constants/routes'

function Promo() {
  const location = useLocation()
  const isMain = location.pathname === AppRoute.Main.url

  return (
    <Link className={`promo ${isMain ? '' : 'hidden'}`} to="/">
      <picture className="promo__picture">
        <source srcSet="/images/dev/dev-promo-mobile.png" media="(max-width: 480px)" />
        <source srcSet="/images/dev/dev-promo-tablet.png" media="(max-width: 1080px)" />
        <img
          className="promo__img"
          src="/images/dev/dev-promo-desktop.png"
          width="1920"
          height="360"
          alt="Картинка"
        />
      </picture>
    </Link>
  )
}

export default Promo
