/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {TeamsRatingState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {fetchTeamsRating} from '../api-actions'
import {FilterOption, SorterOption} from '../../types/common'

const initialState: TeamsRatingState = {
  teamsRatingLoading: false,
  teamsRatingError: false,
  teamsRating: [],
  searchString: '',
  filterSeasonStatus: {
    value: 'all',
    label: 'все сезоны',
  },
  filterRegionStatus: {
    value: 'all',
    label: 'все регионы',
  },
  sorterStatus: {
    key: null,
    direction: 0,
  },
}

export const teamsRatingData = createSlice({
  name: NameSpace.TeamsRating,
  initialState,
  reducers: {
    sortingTeamsRating: (state, action: PayloadAction<{sorterStatus: SorterOption}>) => {
      const {sorterStatus} = action.payload
      state.sorterStatus = sorterStatus
    },
    filteringTeamsRatingBySeason: (
      state,
      action: PayloadAction<{filterSeasonStatus: FilterOption}>,
    ) => {
      const {filterSeasonStatus} = action.payload
      state.filterSeasonStatus = filterSeasonStatus
    },
    filteringTeamsRatingByString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload
    },
    filteringTeamsRatingByRegion: (
      state,
      action: PayloadAction<{filterRegionStatus: FilterOption}>,
    ) => {
      const {filterRegionStatus} = action.payload
      state.filterRegionStatus = filterRegionStatus
    },
    resetTeamsRatingFilters: (state) => {
      state.searchString = initialState.searchString
      state.filterRegionStatus = initialState.filterRegionStatus
      state.filterSeasonStatus = initialState.filterSeasonStatus
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTeamsRating.pending, (state) => {
        state.teamsRatingLoading = true
      })
      .addCase(fetchTeamsRating.fulfilled, (state, action) => {
        state.teamsRating = action.payload
        state.teamsRatingLoading = false
      })
      .addCase(fetchTeamsRating.rejected, (state) => {
        state.teamsRatingError = true
        state.teamsRatingLoading = false
      })
  },
})

export const {
  sortingTeamsRating,
  filteringTeamsRatingBySeason,
  filteringTeamsRatingByString,
  filteringTeamsRatingByRegion,
  resetTeamsRatingFilters,
} = teamsRatingData.actions
