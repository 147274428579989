/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice} from '@reduxjs/toolkit'
import {PaymentState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {fetchTeamPayment, fetchTournamentPayment} from '../api-actions'

const initialState: PaymentState = {
  isPaymentLoading: false,
  isPaymentError: false,
  paymentUrl: undefined,
}

export const paymentsData = createSlice({
  name: NameSpace.Payments,
  initialState,
  reducers: {
    resetPaymentState: (state) => {
      state.paymentUrl = undefined
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTeamPayment.pending, (state) => {
        state.isPaymentLoading = true
        state.isPaymentError = false
      })
      .addCase(fetchTeamPayment.fulfilled, (state, action) => {
        state.isPaymentLoading = false
        state.isPaymentError = false
        state.paymentUrl = action.payload.url || ''
      })
      .addCase(fetchTeamPayment.rejected, (state) => {
        state.isPaymentError = true
        state.isPaymentLoading = false
      })
      .addCase(fetchTournamentPayment.pending, (state) => {
        state.isPaymentLoading = true
        state.isPaymentError = false
      })
      .addCase(fetchTournamentPayment.fulfilled, (state, action) => {
        state.isPaymentLoading = false
        state.isPaymentError = false
        state.paymentUrl = action.payload.url || ''
      })
      .addCase(fetchTournamentPayment.rejected, (state) => {
        state.isPaymentError = true
        state.isPaymentLoading = false
      })
  },
})

export const {resetPaymentState} = paymentsData.actions
