import React, {ReactNode} from 'react'
import {Navigate} from 'react-router-dom'
import {useAppSelector} from '../../../core/hooks'
import {
  getAuthorizationStatus,
  getAuthorizationUserRole,
} from '../../../core/store/authorization-data/selectors'
import AppRoute from '../../../core/constants/routes'

function PrivateRoute({children, roles}: {children: ReactNode; roles?: number[]}) {
  const isAuthenticated = useAppSelector(getAuthorizationStatus)
  const userRole = useAppSelector(getAuthorizationUserRole)

  if (!isAuthenticated) {
    return <Navigate to={AppRoute.Main.url} replace />
  }

  if (roles && userRole !== undefined && !roles.includes(parseInt(userRole, 10))) {
    return <Navigate to={AppRoute.Main.url} replace />
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default PrivateRoute
