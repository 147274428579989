import NameSpace from '../../constants/api-constants'
import {RatingTeam} from '../../../api'
import {FilterOption, SorterOption} from '../../types/common'
import {State} from '../index'

export const getTeamsRatingLoading = (state: State): boolean =>
  state[NameSpace.TeamsRating].teamsRatingLoading
export const getTeamsRatingError = (state: State): boolean =>
  state[NameSpace.TeamsRating].teamsRatingError
export const getTeamsRating = (state: State): RatingTeam[] =>
  state[NameSpace.TeamsRating].teamsRating
export const getTeamsRatingSearchString = (state: State): string =>
  state[NameSpace.TeamsRating].searchString
export const getTeamsRatingFilterSeasonStatus = (state: State): FilterOption =>
  state[NameSpace.TeamsRating].filterSeasonStatus
export const getTeamsRatingFilterRegionStatus = (state: State): FilterOption =>
  state[NameSpace.TeamsRating].filterRegionStatus
export const getTeamsRatingSorterStatus = (state: State): SorterOption =>
  state[NameSpace.TeamsRating].sorterStatus
