import NameSpace from '../../constants/api-constants'

import {State} from '../index'

export const getPaymentsLoadingStatus = (state: State): boolean =>
  state[NameSpace.Payments].isPaymentLoading
export const getPaymentsErrorStatus = (state: State): boolean =>
  state[NameSpace.Payments].isPaymentError
export const getPayments = (state: State): string | undefined =>
  state[NameSpace.Payments].paymentUrl
