import React from 'react'
import TinySlider from 'tiny-slider-react'
import CalendarItem from '../calendar-item/calendar-item'
import {MatchSeries} from '../../../api'
import matchesSliderConfig from '../../../core/config/slider-config'
import 'tiny-slider/dist/tiny-slider.css'

function CalendarList({matchSeriesArray}: {matchSeriesArray: MatchSeries[]}) {
  if (matchSeriesArray && matchSeriesArray.length > 0) {
    return (
      <div className="widget">
        <TinySlider settings={matchesSliderConfig}>
          {matchSeriesArray.map((matchSeries) => (
            <CalendarItem matchSeries={matchSeries} key={matchSeries.series_id} />
          ))}
        </TinySlider>
      </div>
    )
  }
  return null
}

export default CalendarList
