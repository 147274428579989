import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import {ActionMeta, SingleValue} from 'react-select'
import ModalRegistrationList from '../../ordinary/modal-registration-list/modal-registration-list'
import {registrationFormValidationSchema} from '../../../core/utils/form-validate-helpers'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {registration} from '../../../core/store/api-actions'
import {sendErrorText, SendRequestStatus} from '../../../core/constants/common'
import {
  getAuthorizationErrors,
  getAuthorizationSendStatus,
} from '../../../core/store/authorization-data/selectors'
import {resetAuthorizationErrors} from '../../../core/store/authorization-data/authorization-data'
import {RegistrationFormValues} from '../../../core/types/form'
import {FilterOption} from '../../../core/types/common'

function ModalRegistration({hidden}: {hidden: boolean}) {
  const dispatch = useAppDispatch()
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY
  const registrationErrors = useAppSelector(getAuthorizationErrors) || {}
  const registrationSendStatus = useAppSelector(getAuthorizationSendStatus)
  const [requestError, setRequestError] = useState<string>('')

  useEffect(() => {
    if (registrationSendStatus === SendRequestStatus.UnSuccess) {
      setRequestError(sendErrorText)
    }
  }, [registrationSendStatus])

  const onSubmit = async (values: RegistrationFormValues) => {
    dispatch(registration(values))
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      desireRole: undefined,
      password: '',
      passwordRepeat: '',
      reCaptcha: '',
    } as RegistrationFormValues,
    validationSchema: registrationFormValidationSchema,
    onSubmit,
  })

  const handleReCaptchaChange = (value: string | null) => {
    dispatch(resetAuthorizationErrors())
    formik.setFieldValue('reCaptcha', value)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(resetAuthorizationErrors())
    formik.handleChange(e)
  }

  const handleSelectChange = (
    selectedOption: SingleValue<FilterOption>,
    actionMeta: ActionMeta<FilterOption>,
  ) => {
    const event = {
      target: {
        name: actionMeta.name,
        value: selectedOption || null,
      },
    }
    formik.handleChange(event)
  }

  return (
    <div className={`modal__background ${hidden ? 'hidden' : ''}`}>
      <svg className="modal__logo" width="2020" height="865">
        <use xlinkHref="images/sprite.svg#background-logo" />
      </svg>
      <div className="modal__column">
        <ul className="modal__errors">
          <li className={`modal__error ${requestError === '' ? 'hidden' : ''}`}>{requestError}</li>
          {Object.values(registrationErrors).map((error) => (
            <li className="modal__error" key={error}>
              {error}
            </li>
          ))}
        </ul>
        <form className="modal__form" onSubmit={formik.handleSubmit}>
          <ModalRegistrationList
            values={formik.values}
            errors={formik.errors}
            onChangeInput={handleInputChange}
            onChangeSelect={handleSelectChange}
          />
          <p className="modal__text">
            Нажимая кнопку “Зарегистрироваться”, Вы принимаете условия{' '}
            <button type="button" className="modal__link">
              Пользовательского соглашения
            </button>{' '}
            и{' '}
            <button type="button" className="modal__link">
              Политики конфиденциальности
            </button>
          </p>
          <div className="gap gap--5">
            <p
              className={`form__help form__help--center ${formik.errors.reCaptcha ? '' : 'hidden'}`}>
              {formik.errors.reCaptcha}
            </p>
            <ReCAPTCHA
              sitekey={siteKey || ''}
              className="modal__recaptcha"
              onChange={handleReCaptchaChange}
              hl="ru"
            />
          </div>
          <button className="modal__button" type="submit">
            Зарегистрироваться
          </button>
        </form>
      </div>
    </div>
  )
}

export default ModalRegistration
