import React from 'react'
import {BannerPositionEnum} from '../../../api'
import BannerList from '../../ordinary/banner-list/banner-list'

function AsideBanners({bannerPositions}: {bannerPositions: BannerPositionEnum[]}) {
  return (
    <aside className="sidebar-banner">
      <BannerList bannerPositions={bannerPositions} />
    </aside>
  )
}

export default AsideBanners
