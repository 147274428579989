import NameSpace from '../../constants/api-constants'
import {ApiError} from '../../types/state'

import {State} from '../index'

export const getCreateTournamentLoadingStatus = (state: State): boolean =>
  state[NameSpace.CreateTournamentTemps].createTournamentLoading
export const getCreateTournamentStatus = (state: State): boolean | undefined =>
  state[NameSpace.CreateTournamentTemps].createTournamentStatus
export const getCreateTournamentErrors = (state: State): ApiError[] =>
  state[NameSpace.CreateTournamentTemps].createTournamentErrors
