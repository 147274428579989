import NameSpace from '../../constants/api-constants'
import {Match} from '../../../api'

import {State} from '../index'

export const getMatchesLoadingStatus = (state: State): boolean =>
  state[NameSpace.Matches].isMatchesLoading
export const getMatchesErrorStatus = (state: State): boolean =>
  state[NameSpace.Matches].isMatchesError
export const getMatches = (state: State): Match[] => state[NameSpace.Matches].matches
