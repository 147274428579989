/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ModalsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'

const initialState: ModalsState = {
  authorizationModal: false,
  sendRecoveryCodeModal: false,
  recoveryPasswordModal: false,
  recoverPasswordSuccessModal: false,
  messageModal: false,
}

export const modalsData = createSlice({
  name: NameSpace.Modals,
  initialState,
  reducers: {
    changeAuthorizationModal: (state, action: PayloadAction<{authorizationModal: boolean}>) => {
      const {authorizationModal} = action.payload
      state.authorizationModal = authorizationModal
    },
    changeSendRecoveryCodeModal: (
      state,
      action: PayloadAction<{sendRecoveryCodeModal: boolean}>,
    ) => {
      const {sendRecoveryCodeModal} = action.payload
      state.sendRecoveryCodeModal = sendRecoveryCodeModal
    },
    changeRecoveryPasswordModal: (
      state,
      action: PayloadAction<{recoveryPasswordModal: boolean}>,
    ) => {
      const {recoveryPasswordModal} = action.payload
      state.recoveryPasswordModal = recoveryPasswordModal
    },
    changeRecoveryPasswordSuccessModal: (
      state,
      action: PayloadAction<{recoveryPasswordSuccessModal: boolean}>,
    ) => {
      const {recoveryPasswordSuccessModal} = action.payload
      state.recoverPasswordSuccessModal = recoveryPasswordSuccessModal
    },
    changeMessageModal: (state, action: PayloadAction<{messageModal: boolean}>) => {
      const {messageModal} = action.payload
      state.messageModal = messageModal
    },
  },
})

export const {
  changeAuthorizationModal,
  changeSendRecoveryCodeModal,
  changeMessageModal,
  changeRecoveryPasswordModal,
  changeRecoveryPasswordSuccessModal,
} = modalsData.actions
