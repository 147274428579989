import React, {useRef} from 'react'
import PlayerItem from '../player-item/player-item'
import useScrollbar from '../../smart/overlay-scrollbars/overlay-scrollbars'
import {StatsPlayer, TeamPlayer} from '../../../api'
import {findPlayerStatsById} from '../../../core/utils/array-prepare-helpers'

function PlayerList({
  teamPlayers,
  playersStats,
}: {
  teamPlayers: TeamPlayer[]
  playersStats: StatsPlayer[]
}) {
  const playerListRef = useRef(null)
  useScrollbar({root: playerListRef, hasScroll: true})

  return (
    <div
      className={`player-scroll ${teamPlayers.length === 0 ? 'hidden' : ''}`}
      ref={playerListRef}>
      <ul className="player">
        {teamPlayers.map((teamPlayer) => (
          <PlayerItem
            key={teamPlayer.id}
            teamPlayer={teamPlayer}
            playerStats={findPlayerStatsById(playersStats, teamPlayer.player_id)}
          />
        ))}
      </ul>
    </div>
  )
}

export default PlayerList
