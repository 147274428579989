import React, {useEffect} from 'react'
import Search from '../../ordinary/search/search'
import Dropdown from '../dropdown/dropdown'
import {DropdownTypes, ItemsForFilter} from '../../../core/constants/filters'
import {transformSeasonsToOptions} from '../../../core/utils/array-prepare-helpers'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getSeasons} from '../../../core/store/seasons-data/selectors'
import Reset from '../reset/reset'
import {fetchSeasons} from '../../../core/store/api-actions'

function FilterRatingPlayersItem() {
  const dispatch = useAppDispatch()
  const seasons = useAppSelector(getSeasons)
  const seasonsOptions = transformSeasonsToOptions(seasons)

  useEffect(() => {
    dispatch(fetchSeasons())
  }, [dispatch])

  return (
    <>
      <li className="filter__item">
        <Dropdown
          options={seasonsOptions}
          type={DropdownTypes.Season}
          items={ItemsForFilter.PlayersRating}
        />
      </li>
      <li className="filter__item">
        <Search items={ItemsForFilter.PlayersRating} />
      </li>
      <li className="filter__item">
        <Reset items={ItemsForFilter.PlayersRating} />
      </li>
    </>
  )
}

export default FilterRatingPlayersItem
