import React, {useState} from 'react'
import {useLocation} from 'react-router-dom'
import RedactorStyles from '../redactor-styles/redactor-styles'
import {HdbkFaq, HdbkMainProvision} from '../../../api'
import AppRoute from '../../../core/constants/routes'

function AccordionItem({data}: {data: HdbkFaq | HdbkMainProvision}) {
  const location = useLocation()
  const isFaqPage = location.pathname === AppRoute.FaqPage.url

  const titleToShow = isFaqPage ? (data as HdbkFaq).question : (data as HdbkMainProvision).title
  const textToShow = isFaqPage ? (data as HdbkFaq).answer : (data as HdbkMainProvision).text

  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <li className={`accordion__item ${isOpen ? 'accordion__item--open' : ''}`} onClick={toggleOpen}>
      <div className="accordion__block">
        <p className="accordion__title">{titleToShow}</p>
        <div className="accordion__border">
          <svg className="accordion__icon" width="8" height="16">
            <use xlinkHref="images/sprite.svg#arrow" />
          </svg>
        </div>
      </div>
      <div className="accordion__wrap">{textToShow && <RedactorStyles string={textToShow} />}</div>
    </li>
  )
}

export default AccordionItem
