import React from 'react'
import ContentItem from '../content-item/content-item'
import {Album, Post, Video} from '../../../api'

function ContentList({items, className}: {items: Video[] | Album[] | Post[]; className?: string}) {
  return (
    <ul className={`content ${className}`}>
      {items.map((item) => {
        let key
        if ('video_id' in item) {
          key = item.video_id
        } else if ('album_id' in item) {
          key = item.album_id
        } else if ('post_id' in item) {
          key = item.post_id
        } else {
          throw new Error('Unexpected item type')
        }

        return <ContentItem key={key} data={item} />
      })}
    </ul>
  )
}

export default ContentList
