/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useEffect, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {
  getAuthorizationStatus,
  getAuthorizationUserId,
} from '../../../core/store/authorization-data/selectors'
import {fetchUser} from '../../../core/store/api-actions'
import {getUser} from '../../../core/store/users-data/selectors'
import {removeLogin} from '../../../core/store/authorization-data/authorization-data'
import {resetUser} from '../../../core/store/users-data/users-data'
import AppRoute from '../../../core/constants/routes'
import {changeAuthorizationModal} from '../../../core/store/modals-data/modals-data'

function Profile() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const userId = useAppSelector(getAuthorizationUserId)
  const user = useAppSelector(getUser)
  const loginStatus = useAppSelector(getAuthorizationStatus)
  const [menuOpen, setMenuOpen] = useState(false)
  const profileRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (userId !== undefined) {
      dispatch(fetchUser(Number(userId)))
    }
  }, [dispatch, userId])

  const handleRemoveAuthClick = () => {
    dispatch(removeLogin())
    dispatch(resetUser())
    setMenuOpen(false)
    navigate(AppRoute.Main.url)
  }

  const getLinkTo = () => {
    if (!user || !user.confirmed_at || user.blocked_at !== null) {
      return AppRoute.PersonalPage.url
    }
    switch (user.role) {
      case 66:
        return AppRoute.RegistrationTournamentPage.url
      case 34:
        return AppRoute.RegistrationCommandsPage.url
      default:
        return AppRoute.PersonalPage.url
    }
  }

  const handleModalOpen = () => {
    dispatch(changeAuthorizationModal({authorizationModal: true}))
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  function isMobile() {
    return window.innerWidth <= 1080
  }

  return (
    <div
      className={`profile ${menuOpen ? 'profile--open' : ''}`}
      ref={profileRef}
      onMouseEnter={() => !isMobile() && setMenuOpen(true)}
      onMouseLeave={() => !isMobile() && setMenuOpen(false)}
      onClick={() => isMobile() && setMenuOpen(!menuOpen)}>
      <div className={`profile__parent ${loginStatus ? '' : 'hidden'}`}>
        <button type="button" className="profile__link" onClick={() => setMenuOpen(!menuOpen)}>
          <p className="profile__name hide-1080">
            {user?.profile?.first_name} {user?.profile?.last_name}
          </p>
          {/* нужно доработать */}
          <img
            className="profile__img"
            width="45"
            height="45"
            src="/images/default/profile.svg"
            alt={`${user?.profile?.first_name} ${user?.profile?.last_name}`}
          />
        </button>
        <ul className="profile__list">
          <li className="profile__item">
            <Link className="profile__unit" to={getLinkTo()}>
              Личный кабинет
            </Link>
          </li>
          {user && user.role === 66 && (
            <li className="profile__item">
              <a
                className="profile__unit"
                href="https://amatour-api.dev.ssdemo.ru/"
                target="_blank"
                rel="noopener noreferrer">
                Администрирование турнира
              </a>
            </li>
          )}
          {user && user.role === 34 && (
            <li className="profile__item">
              <a
                className="profile__unit"
                href="https://amatour-api.dev.ssdemo.ru/"
                target="_blank"
                rel="noopener noreferrer">
                Администрирование команды
              </a>
            </li>
          )}
          <li className="profile__item">
            <button type="button" className="profile__unit" onClick={handleRemoveAuthClick}>
              Выход из профиля
            </button>
          </li>
        </ul>
      </div>
      <div className={`profile__parent ${loginStatus ? 'hidden' : ''}`}>
        <button type="button" className="profile__link" onClick={handleModalOpen}>
          <p className="profile__author">войти</p>
          <img
            className="profile__img hide-1080"
            width="45"
            height="45"
            src="/images/default/profile.svg"
            alt="Заглушка профиля"
          />
        </button>
      </div>
    </div>
  )
}

export default Profile
