import React, {useEffect, useState} from 'react'
import MeetingStatsItem from '../meeting-stats-item/meeting-stats-item'
import {Match} from '../../../api'
import {useAppSelector} from '../../../core/hooks'
import {sortMatches} from '../../../core/utils/array-prepare-helpers'
import {getPlayerMatchesSorterStatus} from '../../../core/store/player-matches-data/selectors'

function MeetingStatsList({matches}: {matches: Match[]}) {
  const sorterStatus = useAppSelector(getPlayerMatchesSorterStatus)
  const [sortedPlayerMatches, setSortedPlayerMatches] = useState<Match[]>(matches || [])

  useEffect(() => {
    setSortedPlayerMatches(sortMatches([...(matches || [])], sorterStatus))
  }, [sorterStatus, matches])

  useEffect(() => {
    setSortedPlayerMatches([...(matches || [])])
  }, [matches])

  return (
    <ul className="meet__list">
      {sortedPlayerMatches.map((match) => (
        <MeetingStatsItem match={match} key={match.match_id} />
      ))}
    </ul>
  )
}

export default MeetingStatsList
