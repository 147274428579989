import {StatsTeam} from '../../../api'
import NameSpace from '../../constants/api-constants'
import {State} from '../index'

export const getTeamsRegionStatsLoading = (state: State): boolean =>
  state[NameSpace.TeamsRegionStats].teamsRegionStatsLoading
export const getTeamsRegionStatsError = (state: State): boolean =>
  state[NameSpace.TeamsRegionStats].teamsRegionStatsError
export const getTeamsRegionStats = (state: State): StatsTeam[] =>
  state[NameSpace.TeamsRegionStats].teamsRegionStats
