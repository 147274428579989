import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import ModalEntranceList from '../../ordinary/modal-entrance-list/modal-entrance-list'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {login} from '../../../core/store/api-actions'
import {loginFormValidationSchema} from '../../../core/utils/form-validate-helpers'
import {
  getAuthorizationErrors,
  getAuthorizationSendStatus,
} from '../../../core/store/authorization-data/selectors'
import {sendErrorText, SendRequestStatus} from '../../../core/constants/common'
import {
  changeAuthorizationModal,
  changeSendRecoveryCodeModal,
} from '../../../core/store/modals-data/modals-data'
import {resetAuthorizationErrors} from '../../../core/store/authorization-data/authorization-data'
import {LoginFormValues} from '../../../core/types/form'

function ModalEntrance({hidden}: {hidden: boolean}) {
  const dispatch = useAppDispatch()
  const [requestError, setRequestError] = useState<string>('')
  const loginErrors = useAppSelector(getAuthorizationErrors) || {}
  const loginSendStatus = useAppSelector(getAuthorizationSendStatus)

  useEffect(() => {
    if (loginSendStatus === SendRequestStatus.UnSuccess) {
      setRequestError(sendErrorText)
    }
  }, [loginSendStatus])

  const onSubmit = async (values: LoginFormValues) => {
    dispatch(login(values))
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: 0,
    } as LoginFormValues,
    validationSchema: loginFormValidationSchema,
    onSubmit,
  })

  const handleSendRecoveryCodeModalOpen = () => {
    dispatch(changeAuthorizationModal({authorizationModal: false}))
    dispatch(changeSendRecoveryCodeModal({sendRecoveryCodeModal: true}))
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(resetAuthorizationErrors())
    formik.handleChange(e)
  }

  return (
    <div className={`modal__background ${hidden ? 'hidden' : ''}`}>
      <svg className="modal__logo" width="1025" height="440">
        <use xlinkHref="images/sprite.svg#background-logo" />
      </svg>
      <div className="modal__column">
        <ul className="modal__errors">
          <li className={`modal__error ${requestError === '' ? 'hidden' : ''}`}>{requestError}</li>
          {Object.values(loginErrors).map((error) => (
            <li className="modal__error" key={error}>
              {error}
            </li>
          ))}
        </ul>
        <form className="modal__form" onSubmit={formik.handleSubmit}>
          <ModalEntranceList
            values={formik.values}
            errors={formik.errors}
            onChangeInput={handleInputChange}
          />
          <div className="modal__block">
            <label className="check" htmlFor="remember">
              <input
                id="remember"
                name="remember"
                className="check__input"
                type="checkbox"
                value={formik.values.remember}
                onInputCapture={(e) => {
                  dispatch(resetAuthorizationErrors())
                  formik.handleChange(e)
                }}
              />
              <span className="check__indicator" />
              <span className="check__text">Запомнить меня</span>
            </label>
            <button
              type="button"
              className="modal__recovery"
              onClick={handleSendRecoveryCodeModalOpen}>
              Забыли пароль?
            </button>
          </div>
          <button className="modal__button modal__button--uppercase" type="submit">
            Войти
          </button>
        </form>
      </div>
    </div>
  )
}

export default ModalEntrance
