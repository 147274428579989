import {Link} from 'react-router-dom'
import React from 'react'
import AppRoute from '../../../core/constants/routes'
import {Album, Post, Video} from '../../../api'
import {roundDateFormat} from '../../../core/utils/date-time-helpers'
import {isAlbum, isPost, isVideo} from '../../../core/utils/type-guards'

function ContentItem({data}: {data: Video | Album | Post}) {
  const publishedDate: string | undefined = data.published_dt
  const formattedDay: string | undefined = publishedDate
    ? roundDateFormat(new Date(publishedDate), 'dd')
    : ''
  const formattedMonth: string | undefined = publishedDate
    ? roundDateFormat(new Date(publishedDate), 'MMM')
    : ''

  let src
  let contentId

  if (isVideo(data)) {
    src = data._links?.preview?.href
  } else if (isAlbum(data)) {
    src = data._links?.covers?.image750x424?.href
  } else if (isPost(data)) {
    src = data._links?.image1120x633?.href
    if (data._links?.image1120x633?.href?.match(/image_placeholder/)) {
      src = '/images/default/content.svg'
    }
  } else {
    src = '/images/default/content.svg'
  }

  if (isAlbum(data)) {
    contentId = data.album_id
  } else if (isVideo(data)) {
    contentId = data.video_id
  } else if (isPost(data)) {
    contentId = data.post_id
  }

  const contentPath = isAlbum(data)
    ? `${AppRoute.AlbumsPage.url}/${contentId}`
    : isVideo(data)
      ? `${AppRoute.VideosPage.url}/${contentId}`
      : `${AppRoute.PostsPage.url}/${contentId}`

  return (
    <li className="content__item">
      <Link className="content__link" to={contentPath}>
        <div className="content__block">
          <div className={`content__icons ${!isAlbum(data) ? 'hidden' : ''}`}>
            <svg className="content__icon" width="30" height="30">
              <use xlinkHref="images/sprite.svg#camera" />
            </svg>
            <p className="content__number">{isAlbum(data) && data.photos?.length}</p>
          </div>
          <div className={`content__icons content__icons--video ${!isVideo(data) ? 'hidden' : ''}`}>
            <svg className="content__icon" width="30" height="30">
              <use xlinkHref="images/sprite.svg#play" />
            </svg>
          </div>
          <img className="content__img" src={src} width="480" height="275" alt={data.title} />
          <div className="content__count">
            <p className="content__date">{formattedDay}</p>
            <p className="content__date">{formattedMonth}</p>
          </div>
        </div>
        <div className="content__text">
          <p className="content__limiter">{data.title}</p>
        </div>
      </Link>
    </li>
  )
}

export default ContentItem
