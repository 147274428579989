/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice} from '@reduxjs/toolkit'
import {MatchSeriesState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {fetchMatchSeries, fetchMatchSeriesArray} from '../api-actions'

const initialState: MatchSeriesState = {
  isMatchSeriesArrayLoading: false,
  isMatchSeriesArrayError: false,
  matchSeriesArray: [],
  isMatchSeriesLoading: false,
  isMatchSeriesError: false,
  matchSeries: null,
}

const matchSeriesData = createSlice({
  name: NameSpace.MatchSeries,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMatchSeriesArray.pending, (state) => {
        state.isMatchSeriesArrayLoading = true
      })
      .addCase(fetchMatchSeriesArray.fulfilled, (state, action) => {
        state.matchSeriesArray = action.payload
        state.isMatchSeriesArrayLoading = false
      })
      .addCase(fetchMatchSeriesArray.rejected, (state) => {
        state.isMatchSeriesArrayError = true
        state.isMatchSeriesArrayLoading = false
      })
      .addCase(fetchMatchSeries.pending, (state) => {
        state.isMatchSeriesLoading = true
      })
      .addCase(fetchMatchSeries.fulfilled, (state, action) => {
        state.matchSeries = action.payload
        state.isMatchSeriesLoading = false
      })
      .addCase(fetchMatchSeries.rejected, (state) => {
        state.isMatchSeriesError = true
        state.isMatchSeriesLoading = false
      })
  },
})

export default matchSeriesData
