/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice} from '@reduxjs/toolkit'
import {PlayerTeamsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {fetchPlayerTeams} from '../api-actions'

const initialState: PlayerTeamsState = {
  playerTeamsLoading: false,
  playerTeamsError: false,
  playerTeams: [],
}

const playerTeamsData = createSlice({
  name: NameSpace.PlayerTeams,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlayerTeams.pending, (state) => {
        state.playerTeamsLoading = true
      })
      .addCase(fetchPlayerTeams.fulfilled, (state, action) => {
        state.playerTeams = action.payload
        state.playerTeamsLoading = false
      })
      .addCase(fetchPlayerTeams.rejected, (state) => {
        state.playerTeamsError = true
        state.playerTeamsLoading = false
      })
  },
})

export default playerTeamsData
