import React from 'react'
import EventTypes from '../../../core/constants/information'

function CalendarInfo() {
  return (
    <div className="calendar__content">
      <p className="calendar__text">Цветовые обозначения в календаре:</p>
      <ul className="calendar__infos">
        {EventTypes.map((eventType) => (
          <li className={`calendar__info calendar__info--${eventType.value}`} key={eventType.value}>
            <div className="calendar__circle" />
            <p className="calendar__type">{eventType.title}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CalendarInfo
