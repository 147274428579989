import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import Title from '../../components/ui/title/title'
import Grids from '../../components/ui/grids/grids'
import ButtonsList from '../../components/ui/buttons-list/buttons-list'
import TabsList from '../../components/ui/tabs-list/tabs-list'
import CrumbsList from '../../components/ordinary/crumbs-list/crumbs-list'
import AppRoute from '../../core/constants/routes'
import {useAppDispatch, useAppSelector} from '../../core/hooks'
import {
  getCurrentTournament,
  getTournamentErrorStatus,
  getTournamentLoadingStatus,
} from '../../core/store/tournaments-data/selectors'
import {fetchRounds, fetchTournament} from '../../core/store/api-actions'
import Stub from '../../components/ordinary/stub/stub'
import {
  generateTournamentButtonsArray,
  generateTournamentTabsArray,
} from '../../core/utils/common-helpers'
import {filterRoundsByTypes} from '../../core/utils/array-prepare-helpers'
import {ApiTournamentsIdRoundsGetTypeEnum, TournamentTypeEnum} from '../../api'
import {
  getRounds,
  getRoundsErrorStatus,
  getRoundsLoadingStatus,
} from '../../core/store/rounds-data/selectors'

function GridsPage() {
  const dispatch = useAppDispatch()
  const {id: tournamentId} = useParams()
  const tournament = useAppSelector(getCurrentTournament)
  const tournamentLoading = useAppSelector(getTournamentLoadingStatus)
  const tournamentError = useAppSelector(getTournamentErrorStatus)
  const rounds = useAppSelector(getRounds)
  const roundsLoading = useAppSelector(getRoundsLoadingStatus)
  const roundsError = useAppSelector(getRoundsErrorStatus)
  const gridsPageLoading = tournamentLoading || roundsLoading
  const gridsPageError = tournamentError || roundsError
  const lapRounds = filterRoundsByTypes(rounds, [
    ApiTournamentsIdRoundsGetTypeEnum.RoundRobinOne,
    ApiTournamentsIdRoundsGetTypeEnum.RoundRobinTwo,
  ])
  const playoffRounds = filterRoundsByTypes(rounds, [
    ApiTournamentsIdRoundsGetTypeEnum.PlayoffOlympic,
  ])
  const buttonsArray = generateTournamentButtonsArray(tournament)
  const tabsArray = generateTournamentTabsArray(tournamentId, lapRounds, playoffRounds)

  const crumbsRoutes = [
    AppRoute.Main,
    tournament?.type === TournamentTypeEnum.Commercial
      ? AppRoute.TournamentsPage
      : AppRoute.LeaguePage,
    {
      title: `${tournament?.full_name}/${AppRoute.GridsPage.title}`,
      url: `#`,
    },
  ]

  useEffect(() => {
    dispatch(
      fetchTournament({
        tournamentId: Number(tournamentId),
        _with: ['zone'],
      }),
    )
    dispatch(
      fetchRounds({
        tournamentId: Number(tournamentId),
        _with: ['playoffHtml'],
      }),
    )
  }, [dispatch, tournamentId])

  return (
    <div className="container">
      <div className={`gap gap--20 ${gridsPageLoading || gridsPageError ? 'hidden' : ''}`}>
        <CrumbsList routes={crumbsRoutes} />
        <div className="gap gap--20 gap--row">
          <Title className="title--uppercase" title="зона " titleGreen={tournament?.zone?.title} />
          <ButtonsList buttonsData={buttonsArray} parentPath="/tables" />
        </div>
        <div className="gap gap--30">
          <TabsList tabsData={tabsArray} />
          {playoffRounds.map((playoffRound) => (
            <div className="gap gap--20" key={playoffRound.round_id}>
              <Title title={playoffRound.name} />
              <Grids roundPlayoffHtml={playoffRound.playoffHtml} />
            </div>
          ))}
        </div>
      </div>
      <Stub
        type={
          gridsPageError
            ? 'error'
            : tournament === null || tournament === undefined
              ? 'absence'
              : ''
        }
        error={gridsPageError ? 'Произошла ошибка при загрузке страницы сеток.' : ''}
      />
    </div>
  )
}

export default GridsPage
