import React, {useEffect, useState} from 'react'
import {addMonths, subMonths} from 'date-fns'

function CalendarMonthItem({
  currentMonth,
  onChangeDate,
  currentDate,
}: {
  currentMonth: Date
  onChangeDate: (type: string, date?: Date) => void
  currentDate: Date
}) {
  const [visibleMonthsCount, setVisibleMonthsCount] = useState(12)

  useEffect(() => {
    const handleResize = () => {
      let count = 12
      if (window.innerWidth <= 780) {
        count = 10
      }
      if (window.innerWidth <= 680) {
        count = 8
      }
      if (window.innerWidth <= 580) {
        count = 6
      }
      if (window.innerWidth <= 390) {
        count = 4
      }
      setVisibleMonthsCount(count)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const visibleStart = subMonths(currentDate, visibleMonthsCount / 2)
  const visibleEnd = addMonths(currentDate, visibleMonthsCount / 2)
  const isVisibleMonth = currentMonth >= visibleStart && currentMonth <= visibleEnd

  const activeClass =
    currentMonth.getMonth() === currentDate.getMonth() ? 'calendar__month--active' : ''
  const hiddenClass = window.innerWidth <= 780 && !isVisibleMonth ? 'calendar__month--hidden' : ''
  const classNames = `calendar__month ${activeClass} ${hiddenClass}`

  const monthNames = [
    'янв.',
    'фев.',
    'мар.',
    'апр.',
    'май',
    'июнь',
    'июль',
    'авг.',
    'сен.',
    'окт.',
    'ноя.',
    'дек.',
  ]

  const monthName = monthNames[currentMonth.getMonth()]

  return (
    <li className={classNames}>
      <button
        type="button"
        className="calendar__link"
        onClick={() => onChangeDate('changeMonth', currentMonth)}>
        {monthName}
      </button>
    </li>
  )
}

export default CalendarMonthItem
