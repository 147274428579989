import React, {useEffect, useMemo} from 'react'
import {useParams} from 'react-router-dom'
import MeetingStatsList from '../../simple/meeting-stats-list/meeting-stats-list'
import MeetingStatsTable from '../meeting-stats-table/meeting-stats-table'
import Search from '../search/search'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {fetchMatches} from '../../../core/store/api-actions'
import Stub from '../stub/stub'
import {getMatches} from '../../../core/store/matches-data/selectors'
import {ItemsForFilter} from '../../../core/constants/filters'
import {
  filterItemsWithTournamentBySeasonId,
  filterMatchesByString,
  getTopItemsByStartDt,
} from '../../../core/utils/array-prepare-helpers'
import {getPlayerMatchesSearchString} from '../../../core/store/player-matches-data/selectors'
import getPlayerInfoFilterSeason from '../../../core/store/player-info-data/selectors'

function MeetingStatsPlayer() {
  const dispatch = useAppDispatch()
  const {id: playerId} = useParams()
  const matches = useAppSelector(getMatches)
  const matchesSearchString = useAppSelector(getPlayerMatchesSearchString)
  const filterSeasonStatus = useAppSelector(getPlayerInfoFilterSeason)

  const createProcessedMatches = useMemo(() => {
    let processedMatches = matches
    if (matchesSearchString !== '') {
      processedMatches = filterMatchesByString(processedMatches, matchesSearchString)
    }
    if (filterSeasonStatus?.label && filterSeasonStatus.value !== 'all') {
      processedMatches = filterItemsWithTournamentBySeasonId(
        processedMatches,
        filterSeasonStatus.value,
      )
    }
    return getTopItemsByStartDt(processedMatches, 4)
  }, [matchesSearchString, matches, filterSeasonStatus])

  useEffect(() => {
    dispatch(
      fetchMatches({
        playerId: Number(playerId),
        _with: ['periods', 'matchPlayers1', 'matchPlayers2', 'tournament', 'players'],
      }),
    )
  }, [dispatch, playerId])

  return (
    <div className="blocks__container blocks__container--start">
      <div className="gap gap--20 gap--start">
        <Search className="search--end" items={ItemsForFilter.MatchSeriesArray} />
        <div className={`meet ${createProcessedMatches.length === 0 ? 'hidden' : ''}`}>
          <MeetingStatsTable />
          <MeetingStatsList matches={createProcessedMatches} />
        </div>
      </div>
      <Stub
        type={
          matches.length > 0 && createProcessedMatches.length === 0
            ? 'searchError'
            : matches.length === 0
              ? 'absencePlayer'
              : ''
        }
      />
    </div>
  )
}

export default MeetingStatsPlayer
