import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import HistoryStatsRow from '../history-stats-row/history-stats-row'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getPlayerTeams} from '../../../core/store/player-teams-data/selectors'
import Stub from '../../ordinary/stub/stub'
import {fetchPlayerTeams} from '../../../core/store/api-actions'
import getPlayerInfoFilterSeason from '../../../core/store/player-info-data/selectors'

function HistoryStatsTable() {
  const dispatch = useAppDispatch()
  const playerTeams = useAppSelector(getPlayerTeams)
  const {id: playerId} = useParams()
  const filterSeasonStatus = useAppSelector(getPlayerInfoFilterSeason)

  useEffect(() => {
    if (filterSeasonStatus?.label && filterSeasonStatus.value !== 'all') {
      dispatch(
        fetchPlayerTeams({
          playerId: Number(playerId),
          seasonId: Number(filterSeasonStatus.value),
        }),
      )
    } else {
      dispatch(fetchPlayerTeams({playerId: Number(playerId)}))
    }
  }, [dispatch, playerId, filterSeasonStatus])

  return (
    <div className="blocks__container blocks__container--start">
      <div
        className={`table-scroll table-scroll--border ${playerTeams.length === 0 ? 'hidden' : ''}`}>
        <div className="table-border table__auto">
          <table className="table">
            <thead className="table__thead">
              <tr className="table__row">
                <th className="table__th table__start-2">команда</th>
                <th className="table__th">даты</th>
              </tr>
            </thead>
            <tbody className="table__tbody">
              {playerTeams.map((playerTeam) => (
                <HistoryStatsRow key={playerTeam.id} playerTeam={playerTeam} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Stub type={playerTeams.length === 0 ? 'absencePlayer' : ''} />
    </div>
  )
}

export default HistoryStatsTable
