import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import FilterStatSeason from '../../smart/filter-stat-season/filter-stat-season'
import {calculateAge} from '../../../core/utils/date-time-helpers'
import {fetchPlayer, fetchPlayersStats} from '../../../core/store/api-actions'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getPlayer} from '../../../core/store/players-data/selectors'
import getPlayerInfoFilterSeason from '../../../core/store/player-info-data/selectors'
import {getPlayersStats} from '../../../core/store/players-stats-data/selectors'

function PlayersItem() {
  const dispatch = useAppDispatch()
  const {id: playerId} = useParams()
  const player = useAppSelector(getPlayer)
  const birthday = player?.birthday ? player?.birthday : ''
  const filterSeasonStatus = useAppSelector(getPlayerInfoFilterSeason)
  const playersStats = useAppSelector(getPlayersStats)
  const playerStats = playersStats.length > 0 ? playersStats[0] : undefined

  useEffect(() => {
    dispatch(fetchPlayer(Number(playerId)))
  }, [dispatch, playerId])

  useEffect(() => {
    if (filterSeasonStatus?.label && filterSeasonStatus.value !== 'all') {
      dispatch(
        fetchPlayersStats({
          playerId: Number(playerId),
          seasonId: Number(filterSeasonStatus.value),
        }),
      )
    } else {
      dispatch(fetchPlayersStats({playerId: Number(playerId)}))
    }
  }, [playerId, dispatch, filterSeasonStatus])

  return (
    <div className="background">
      <div className="background__wrapper">
        <svg className="background__logo" width="1295" height="745">
          <use xlinkHref="images/sprite.svg#background-logo-big" />
        </svg>
      </div>
      <div className="players">
        <div className="players__block">
          <img
            className="players__img"
            src={
              player?.avatar?.includes('photo_placeholder_100x100.svg')
                ? '/images/default/player.svg'
                : player?.avatar
            }
            width="210"
            height="210"
            alt={`${player?.first_name} ${player?.last_name}`}
          />
          <div className="efficiency">
            <p className="efficiency__number">{playerStats?.efficiency || 0}</p>
            <p className="efficiency__text">КПД</p>
          </div>
        </div>
        <div className="gap gap--20">
          <h2 className="players__name">
            {player?.first_name} <span className="players__column">{player?.last_name}</span>
          </h2>
          <p className={`players__locus ${player?.city?.title ? '' : 'hidden'}`}>
            {player?.city?.title}
          </p>
          <p className={`players__age ${birthday === '' ? 'hidden' : ''}`}>
            {calculateAge(new Date(birthday))} лет
          </p>
        </div>
      </div>
      <FilterStatSeason />
    </div>
  )
}

export default PlayersItem
