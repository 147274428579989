import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getSendRecoveryCodeModalStatus} from '../../../core/store/modals-data/selectors'
import {
  changeRecoveryPasswordModal,
  changeSendRecoveryCodeModal,
} from '../../../core/store/modals-data/modals-data'
import Title from '../../ui/title/title'
import {sendRecoveryCode} from '../../../core/store/api-actions'
import {sendRecoveryCodeFormValidationSchema} from '../../../core/utils/form-validate-helpers'
import {
  getSendRecoveryCodeErrors,
  getSendRecoveryCodeRequestStatus,
  getSendRecoveryCodeStatus,
} from '../../../core/store/send-recovery-code-data/selectors'
import {SendRequestStatus, sendErrorText} from '../../../core/constants/common'

function ModalSendRecoveryCode() {
  const dispatch = useAppDispatch()
  const [requestError, setRequestError] = useState<string>('')
  const isModalOpen = useAppSelector(getSendRecoveryCodeModalStatus)
  const sendRecoveryCodeErrors = useAppSelector(getSendRecoveryCodeErrors) || {}
  const sendRecoveryCodeStatus = useAppSelector(getSendRecoveryCodeStatus)
  const sendRecoveryCodeRequestStatus = useAppSelector(getSendRecoveryCodeRequestStatus)

  const handleModalClose = () => {
    dispatch(changeSendRecoveryCodeModal({sendRecoveryCodeModal: false}))
  }

  // eslint-disable-next-line
  const onSubmit = async (values: any) => {
    dispatch(sendRecoveryCode(values))
  }

  useEffect(() => {
    if (sendRecoveryCodeRequestStatus === SendRequestStatus.Success && sendRecoveryCodeStatus) {
      dispatch(changeSendRecoveryCodeModal({sendRecoveryCodeModal: false}))
      dispatch(changeRecoveryPasswordModal({recoveryPasswordModal: true}))
    }
  }, [sendRecoveryCodeStatus, sendRecoveryCodeRequestStatus, dispatch])

  useEffect(() => {
    if (sendRecoveryCodeRequestStatus === SendRequestStatus.UnSuccess) {
      setRequestError(sendErrorText)
    }
  }, [sendRecoveryCodeRequestStatus])

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: sendRecoveryCodeFormValidationSchema,
    onSubmit,
  })

  return (
    <div className={`modal ${isModalOpen ? '' : 'modal--close'}`}>
      <div className="modal__container">
        <div className="modal__scroll">
          <div className="modal__background">
            <svg className="modal__logo" width="1025" height="440">
              <use xlinkHref="images/sprite.svg#background-logo" />
            </svg>
            <div className="modal__column">
              <Title title="Восстановление пароля" className="title--center title--white" />
              <ul className="modal__errors">
                <li className={`modal__error ${requestError === '' ? 'hidden' : ''}`}>
                  {requestError}
                </li>
                {Object.values(sendRecoveryCodeErrors).map((error) => (
                  <li className="modal__error" key={error}>
                    {error}
                  </li>
                ))}
              </ul>
              <form className="modal__form" onSubmit={formik.handleSubmit}>
                <div className="modal__list">
                  <label className="form" htmlFor="recovery-email">
                    <p className="form__text">
                      Email
                      <span className={`form__help ${formik.errors.email ? '' : 'hidden'}`}>
                        {formik.errors.email}
                      </span>
                    </p>
                    <input
                      id="recovery-email"
                      className={`form__input ${formik.errors.email ? 'form__input--error' : ''}`}
                      type="text"
                      name="email"
                      placeholder="Введите вашу почту"
                      value={formik.values.email}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                    />
                  </label>
                </div>
                <button className="modal__button modal__button--uppercase" type="submit">
                  Получить код
                </button>
              </form>
            </div>
          </div>
        </div>
        <button type="button" className="modal__close" onClick={handleModalClose}>
          <svg className="modal__icon" width="45" height="45">
            <use xlinkHref="images/sprite.svg#close" />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default ModalSendRecoveryCode
