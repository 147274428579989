import React from 'react'

function Proffer({
  onRemove,
  onAdd,
  disableRemove,
  index,
  numForms,
}: {
  onRemove: () => void
  onAdd: () => void
  disableRemove: boolean
  index: number
  numForms: number
}) {
  return (
    <div className="proffer">
      {!disableRemove && numForms > 1 && (
        <button type="button" className="proffer__link" onClick={() => onRemove()}>
          <p className="proffer__text">Удалить игрока</p>
          <svg className="proffer__icon" width="30" height="30">
            <use xlinkHref="images/sprite.svg#delete" />
          </svg>
        </button>
      )}
      {(numForms === 1 || index === numForms - 1) && (
        <button type="button" className="proffer__link" onClick={() => onAdd()}>
          <p className="proffer__text">Добавить игрока</p>
          <svg className="proffer__icon" width="30" height="30">
            <use xlinkHref="images/sprite.svg#add" />
          </svg>
        </button>
      )}
    </div>
  )
}

export default Proffer
