import {Album, Post, Video} from '../../api'

export function isVideo(data: Video | Album | Post): data is Video {
  return 'video_id' in data
}

export function isAlbum(data: Video | Album | Post): data is Album {
  return 'album_id' in data
}

export function isPost(data: Video | Album | Post): data is Post {
  return 'post_id' in data
}
