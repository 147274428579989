import React, {ChangeEvent, useEffect} from 'react'
import {FormikErrors} from 'formik'
import {ActionMeta, SingleValue} from 'react-select'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {
  transformCitiesToOptions,
  transformZonesToOptions,
} from '../../../core/utils/array-prepare-helpers'
import {getZones} from '../../../core/store/zones-data/selectors'
import {fetchCities, fetchZones} from '../../../core/store/api-actions'
import FormSelect from '../../smart/select/select'
import {SelectTypes} from '../../../core/constants/selects'
import {getCities} from '../../../core/store/cities-data/selectors'
import {RegistrationTournamentFormValues} from '../../../core/types/form'
import {FilterOption} from '../../../core/types/common'

function TournamentForm({
  values,
  errors,
  onChangeInput,
  onChangeSelect,
}: {
  values: RegistrationTournamentFormValues
  errors: FormikErrors<RegistrationTournamentFormValues>
  onChangeInput: (e: ChangeEvent<HTMLInputElement>) => void
  onChangeSelect: (
    selectedOption: SingleValue<FilterOption>,
    actionMeta: ActionMeta<FilterOption>,
  ) => void
}) {
  const dispatch = useAppDispatch()
  const zones = useAppSelector(getZones)
  const cities = useAppSelector(getCities)
  const zonesOptions = transformZonesToOptions(zones)
  const citiesOptions = transformCitiesToOptions(cities)

  useEffect(() => {
    dispatch(fetchZones())
    dispatch(fetchCities())
  }, [dispatch])

  return (
    <div className="fields">
      <div className="fields__item fields__item--3">
        <label className="form" htmlFor="full-name">
          <p className="form__text">
            Название турнира
            <span className={`form__help ${errors.full_name ? '' : 'hidden'}`}>
              {errors.full_name}
            </span>
          </p>
          <input
            id="full-name"
            className={`form__input ${errors.full_name ? 'form__input--error' : ''}`}
            type="text"
            placeholder="Введите название"
            name="full_name"
            value={values.full_name}
            onChange={(e) => onChangeInput(e)}
          />
        </label>
        <label className="form" htmlFor="short-name">
          <p className="form__text">
            Короткое название
            <span className={`form__help ${errors.short_name ? '' : 'hidden'}`}>
              {errors.short_name}
            </span>
          </p>
          <input
            id="short-name"
            className={`form__input ${errors.short_name ? 'form__input--error' : ''}`}
            type="text"
            placeholder="Введите название"
            name="short_name"
            value={values.short_name}
            onChange={(e) => onChangeInput(e)}
          />
        </label>
        <label className="form" htmlFor="club">
          <p className="form__text">
            Название клуба
            <span className={`form__help ${errors.club ? '' : 'hidden'}`}>{errors.club}</span>
          </p>
          <input
            id="club"
            className={`form__input ${errors.club ? 'form__input--error' : ''}`}
            type="text"
            placeholder="Введите название"
            name="club"
            value={values.club}
            onChange={(e) => onChangeInput(e)}
          />
        </label>
      </div>
      <div className="fields__item fields__item--3">
        <label className="form" htmlFor="club-address">
          <p className="form__text">
            Адрес клуба
            <span className={`form__help ${errors.club_address ? '' : 'hidden'}`}>
              {errors.club_address}
            </span>
          </p>
          <input
            id="club-address"
            className={`form__input ${errors.club_address ? 'form__input--error' : ''}`}
            type="text"
            placeholder="Введите адрес"
            name="club_address"
            value={values.club_address}
            onChange={(e) => onChangeInput(e)}
          />
        </label>
        <label className="form" htmlFor="start-dt">
          <p className="form__text">
            Начало турнира
            <span className={`form__help ${errors.start_dt ? '' : 'hidden'}`}>
              {errors.start_dt}
            </span>
          </p>
          <input
            id="start-dt"
            className={`form__input ${errors.start_dt ? 'form__input--error' : ''}`}
            type="date"
            placeholder="Введите дату"
            name="start_dt"
            value={values.start_dt}
            onChange={(e) => onChangeInput(e)}
          />
        </label>
        <label className="form" htmlFor="end-dt">
          <p className="form__text">
            Окончание турнира
            <span className={`form__help ${errors.end_dt ? '' : 'hidden'}`}>{errors.end_dt}</span>
          </p>
          <input
            id="end-dt"
            className={`form__input ${errors.end_dt ? 'form__input--error' : ''}`}
            type="date"
            placeholder="Введите дату"
            name="end_dt"
            value={values.end_dt}
            onChange={(e) => onChangeInput(e)}
          />
        </label>
      </div>
      <div className="fields__item fields__item--3">
        <div className="form">
          <p className="form__text">
            Город
            <span className={`form__help ${errors.city_id ? '' : 'hidden'}`}>{errors.city_id}</span>
          </p>
          <FormSelect
            options={citiesOptions}
            type={SelectTypes.Cities}
            value={values.city_id}
            name="city_id"
            error={!!errors.city_id}
            onChangeSelect={onChangeSelect}
          />
        </div>
        <div className="form">
          <p className="form__text">
            Желаемая региональная зона
            <span className={`form__help ${errors.zone_id ? '' : 'hidden'}`}>{errors.zone_id}</span>
          </p>
          <FormSelect
            options={zonesOptions}
            type={SelectTypes.Zones}
            value={values.zone_id}
            name="zone_id"
            error={!!errors.zone_id}
            onChangeSelect={onChangeSelect}
          />
        </div>
        <label className="form" htmlFor="cour-coverage">
          <p className="form__text">
            Покрытие корта{' '}
            <span className={`form__help ${errors.court_coverage ? '' : 'hidden'}`}>
              {errors.court_coverage}
            </span>
          </p>
          <input
            id="cour-coverage"
            className={`form__input ${errors.court_coverage ? 'form__input--error' : ''}`}
            type="text"
            placeholder="Введите тип"
            name="court_coverage"
            value={values.court_coverage}
            onChange={(e) => onChangeInput(e)}
          />
        </label>
      </div>
      <div className="fields__item fields__item--3">
        <label className="form" htmlFor="official-ball">
          <p className="form__text">
            Мяч
            <span className={`form__help ${errors.official_ball ? '' : 'hidden'}`}>
              {errors.official_ball}
            </span>
          </p>
          <input
            id="official-ball"
            className={`form__input ${errors.official_ball ? 'form__input--error' : ''}`}
            type="text"
            placeholder="Введите название"
            name="official_ball"
            value={values.official_ball}
            onChange={(e) => onChangeInput(e)}
          />
        </label>
        {/* не готов бек */}
        <label className="form" htmlFor="contact-info">
          <p className="form__text">
            Контактный телефон(инфо)
            <span className={`form__help ${errors.contact_info ? '' : 'hidden'}`}>
              {errors.contact_info}
            </span>
          </p>
          <input
            id="contact-info"
            className={`form__input ${errors.contact_info ? 'form__input--error' : ''}`}
            type="text"
            placeholder="Введите название"
            name="contact_info"
            value={values.contact_info}
            onChange={(e) => onChangeInput(e)}
          />
        </label>
        {/* не готов бек */}
        <label className="form" htmlFor="organizator">
          <p className="form__text">ФИО организатора</p>
          <input id="organizator" className="form__input" type="text" placeholder="Введите ФИО" />
        </label>
      </div>
    </div>
  )
}

export default TournamentForm
