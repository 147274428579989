import {PagesState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {Page} from '../../../api'

import {State} from '../index'

export const getPagesLoadingStatus = (state: State): boolean =>
  state[NameSpace.Pages].isPagesLoading
export const getPageLoadingStatus = (state: State): boolean => state[NameSpace.Pages].isPageLoading
export const getPagesErrorStatus = (state: State): PagesState['isPagesError'] =>
  state[NameSpace.Pages].isPagesError
export const getPageErrorStatus = (state: State): PagesState['isPageError'] =>
  state[NameSpace.Pages].isPageError
export const getPages = (state: State): Page[] => state[NameSpace.Pages].pages
export const getCurrentPage = (state: State): Page | null => state[NameSpace.Pages].currentPage
