import {useEffect, useRef} from 'react'
import {OverlayScrollbars} from 'overlayscrollbars'

interface ScrollbarOptions {
  root: React.RefObject<HTMLElement>
  hasScroll: boolean
}

const useScrollbar = ({root, hasScroll}: ScrollbarOptions) => {
  const scrollRef = useRef<OverlayScrollbars | null>(null)

  useEffect(() => {
    if (root.current && hasScroll) {
      scrollRef.current = OverlayScrollbars(root.current, {})
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.destroy()
      }
    }
  }, [root, hasScroll])
}

export default useScrollbar
