import {CitiesState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {HdbkCity} from '../../../api'

import {State} from '../index'

export const getCitiesLoadingStatus = (state: State): boolean =>
  state[NameSpace.Cities].isCitiesLoading
export const getCitiesErrorStatus = (state: State): CitiesState['isCitiesError'] =>
  state[NameSpace.Cities].isCitiesError
export const getCities = (state: State): HdbkCity[] => state[NameSpace.Cities].cities
