/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {TeamMatchSeriesState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {FilterOption} from '../../types/common'

const initialState: TeamMatchSeriesState = {
  teamMatchSeriesSeasonFilter: {
    value: 'all',
    label: 'Все сезоны',
  },
}

export const teamMatchSeriesData = createSlice({
  name: NameSpace.Calendar,
  initialState,
  reducers: {
    filteringTeamMatchSeriesBySeason: (
      state,
      action: PayloadAction<{filterSeasonStatus: FilterOption}>,
    ) => {
      const {filterSeasonStatus} = action.payload
      state.teamMatchSeriesSeasonFilter = filterSeasonStatus
    },
  },
})

export const {filteringTeamMatchSeriesBySeason} = teamMatchSeriesData.actions
