/* eslint-disable import/no-cycle */
import {Middleware} from 'redux'
import {PayloadAction} from '@reduxjs/toolkit'
import rootReducer from '../root-reducer'
import browserHistory from '../../services/browser-history/browser-history'

type Reducer = ReturnType<typeof rootReducer>

const redirect: Middleware<unknown, Reducer> = () => (next) => (action: PayloadAction<string>) => {
  if (action.type === 'postsData-data/redirectToRoute') {
    browserHistory.push(action.payload)
  }

  return next(action)
}

export default redirect
