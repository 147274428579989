import React, {useEffect, useState} from 'react'
import {registrationTabsArray} from '../../../core/constants/navigations'
import {TabsType} from '../../../core/types/common'
import ModalEntrance from '../modal-entrance/modal-entrance'
import ModalRegistration from '../modal-registration/modal-registration'
import SwitchList from '../../ui/switch-list/switch-list'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getAuthorizationStatus} from '../../../core/store/authorization-data/selectors'
import {getAuthorizationModalStatus} from '../../../core/store/modals-data/selectors'
import {changeAuthorizationModal} from '../../../core/store/modals-data/modals-data'
import {resetAuthorizationErrors} from '../../../core/store/authorization-data/authorization-data'

function ModalAuthorization() {
  const dispatch = useAppDispatch()
  const [selectedTab, setSelectedTab] = useState<TabsType>(registrationTabsArray[0])
  const loginStatus = useAppSelector(getAuthorizationStatus)
  const isModalOpen = useAppSelector(getAuthorizationModalStatus)

  useEffect(() => {
    if (loginStatus) {
      dispatch(changeAuthorizationModal({authorizationModal: false}))
    }
  }, [loginStatus, dispatch])

  const handleTabClick = (tab: TabsType) => {
    dispatch(resetAuthorizationErrors())
    setSelectedTab(tab)
  }

  const handleModalClose = () => {
    dispatch(changeAuthorizationModal({authorizationModal: false}))
  }

  return (
    <div className={`modal ${isModalOpen ? '' : 'modal--close'}`}>
      <div className="modal__container">
        <SwitchList
          selectedTab={selectedTab}
          onTabClick={handleTabClick}
          tabsData={registrationTabsArray}
        />
        <div className="modal__scroll">
          <ModalEntrance hidden={selectedTab.tabType !== 'entrance'} />
          <ModalRegistration hidden={selectedTab.tabType !== 'registration'} />
        </div>
        <button type="button" className="modal__close" onClick={handleModalClose}>
          <svg className="modal__icon" width="45" height="45">
            <use xlinkHref="images/sprite.svg#close" />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default ModalAuthorization
