import React from 'react'
import ReactPaginate from 'react-paginate'

function Pagination({
  pageCount,
  forcePage,
  onPageClick,
  className,
}: {
  pageCount: number
  forcePage: number
  onPageClick: (selectedPage: {selected: number}) => void
  className?: string
}) {
  return (
    <div hidden={pageCount < 2}>
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        onPageChange={onPageClick}
        containerClassName={`pagination ${className || ''}`}
        previousLabel={
          <svg className="pagination__icon" width="20" height="20">
            <use xlinkHref="images/sprite.svg#arrow" />
          </svg>
        }
        nextLabel={
          <svg className="pagination__icon" width="20" height="20">
            <use xlinkHref="images/sprite.svg#arrow" />
          </svg>
        }
        breakLabel="..."
        breakClassName="pagination__item"
        breakLinkClassName="pagination__link pagination__dots"
        forcePage={forcePage}
        disableInitialCallback={false}
        previousClassName="pagination__arrows"
        nextClassName="pagination__arrows pagination__arrows--right"
        previousLinkClassName="pagination__link"
        nextLinkClassName="pagination__link"
        disabledClassName="pagination__arrows--disabled"
        pageClassName="pagination__item"
        pageLinkClassName="pagination__link pagination__number"
        activeClassName="pagination__item--active"
      />
    </div>
  )
}

export default Pagination
