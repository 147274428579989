/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice} from '@reduxjs/toolkit'
import {RoundsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {fetchRounds} from '../api-actions'

const initialState: RoundsState = {
  isRoundsLoading: false,
  isRoundsError: false,
  rounds: [],
}

const roundsData = createSlice({
  name: NameSpace.Rounds,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRounds.pending, (state) => {
        state.isRoundsLoading = true
      })
      .addCase(fetchRounds.fulfilled, (state, action) => {
        state.rounds = action.payload
        state.isRoundsLoading = false
      })
      .addCase(fetchRounds.rejected, (state) => {
        state.isRoundsError = true
        state.isRoundsLoading = false
      })
  },
})

export default roundsData
