import React from 'react'
import {Link} from 'react-router-dom'
import {RouteType} from '../../../core/types/app-route'

function CrumbsItem({route}: {route: RouteType}) {
  return (
    <li className="crumbs__item">
      <Link className="crumbs__link" to={route.url}>
        {route.title}
      </Link>
    </li>
  )
}

export default CrumbsItem
