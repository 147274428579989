/* eslint-disable react-hooks/rules-of-hooks */
import React, {useEffect, useState} from 'react'
import {ItemsForSort} from '../../../core/constants/filters'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {sortingTeamsRating} from '../../../core/store/teams-rating-data/teams-rating-data'
import {getTeamsRatingSorterStatus} from '../../../core/store/teams-rating-data/selectors'
import {getPlayersRatingSorterStatus} from '../../../core/store/players-rating-data/selectors'
import {sortingPlayersRating} from '../../../core/store/players-rating-data/players-rating-data'
import getRoundTablesInnerSorterStatus from '../../../core/store/round-tables-inner-data/selectors'
import {sortingRoundTablesInner} from '../../../core/store/round-tables-inner-data/round-tables-inner-data'
import {getPlayerMatchesSorterStatus} from '../../../core/store/player-matches-data/selectors'
import {sortingPlayerMatches} from '../../../core/store/player-matches-data/player-matches-data'

function Sorting({title, items}: {title: string; items: string}) {
  const dispatch = useAppDispatch()
  const icons = ['arrow-double', 'arrow-top', 'arrow-bottom']
  const [direction, setDirection] = useState(0)
  const activeSortStatus = (items: string) => {
    switch (items) {
      case ItemsForSort.TeamsRating:
        return useAppSelector(getTeamsRatingSorterStatus)
      case ItemsForSort.PlayersRating:
        return useAppSelector(getPlayersRatingSorterStatus)
      case ItemsForSort.RoundTablesInner:
        return useAppSelector(getRoundTablesInnerSorterStatus)
      case ItemsForSort.PlayersMatches:
        return useAppSelector(getPlayerMatchesSorterStatus)
      default:
        return null
    }
  }
  const active = activeSortStatus(items)?.key === title

  useEffect(() => {
    if (!active) {
      setDirection(0)
    }
  }, [active])

  const handleClick = () => {
    const newDirection = (direction + 1) % icons.length
    setDirection(newDirection)
    switch (items) {
      case ItemsForSort.TeamsRating:
        dispatch(sortingTeamsRating({sorterStatus: {key: title, direction: newDirection}}))
        break
      case ItemsForSort.PlayersRating:
        dispatch(sortingPlayersRating({sorterStatus: {key: title, direction: newDirection}}))
        break
      case ItemsForSort.RoundTablesInner:
        dispatch(sortingRoundTablesInner({sorterStatus: {key: title, direction: newDirection}}))
        break
      case ItemsForSort.PlayersMatches:
        dispatch(sortingPlayerMatches({sorterStatus: {key: title, direction: newDirection}}))
        break
      default:
        break
    }
  }

  return (
    <button type="button" className="sorting" onClick={handleClick}>
      <p className="sorting__text">{title}</p>
      {direction < icons.length && (
        <svg className="sorting__icon" width="7" height="11">
          <use xlinkHref={`images/sprite.svg#${icons[direction]}`} />
        </svg>
      )}
    </button>
  )
}

export default Sorting
