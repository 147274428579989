import React, {useEffect} from 'react'
import {useFormik} from 'formik'
import {useParams} from 'react-router-dom'
import {ActionMeta, SingleValue} from 'react-select'
import CrumbsList from '../../components/ordinary/crumbs-list/crumbs-list'
import Title from '../../components/ui/title/title'
import AppRoute from '../../core/constants/routes'
import ButtonsList from '../../components/ui/buttons-list/buttons-list'
import TournamentForm from '../../components/ordinary/tournament-form/tournament-form'
import ButtonForm from '../../components/ui/button-form/button-form'
import {registrationTournamentFormValidationSchema} from '../../core/utils/form-validate-helpers'
import {createTournament, fetchTournamentsRequest} from '../../core/store/api-actions'
import {useAppDispatch, useAppSelector, useFilteredButtons} from '../../core/hooks'
import {getAuthorizationUserId} from '../../core/store/authorization-data/selectors'
import {
  getCurrentTournamentTemps,
  getTournamentTempErrorStatus,
  getTournamentTempLoadingStatus,
} from '../../core/store/tournaments-temps-data/selectors'
import Reason from '../../components/ordinary/reason/reason'
import RequestStatus from '../../components/simple/request-status/request-status'
import {getCities} from '../../core/store/cities-data/selectors'
import {getZones} from '../../core/store/zones-data/selectors'
import {
  transformCitiesToOptions,
  transformZonesToOptions,
} from '../../core/utils/array-prepare-helpers'
import Stub from '../../components/ordinary/stub/stub'
import {RegistrationTournamentFormValues} from '../../core/types/form'
import {FilterOption} from '../../core/types/common'

function RequestTournamentPage() {
  const dispatch = useAppDispatch()
  const {id: requestId} = useParams()
  const filteredButtons = useFilteredButtons()
  const userId = useAppSelector(getAuthorizationUserId)
  const tournamentTemp = useAppSelector(getCurrentTournamentTemps)
  const isTempLoading = useAppSelector(getTournamentTempLoadingStatus)
  const isTempError = useAppSelector(getTournamentTempErrorStatus)

  const zones = useAppSelector(getZones)
  const cities = useAppSelector(getCities)

  const zonesOptions = React.useMemo(() => {
    return transformZonesToOptions(zones)
  }, [zones])

  const citiesOptions = React.useMemo(() => {
    return transformCitiesToOptions(cities)
  }, [cities])

  const crumbsRoutes = [
    AppRoute.Main,
    AppRoute.RegistrationTournamentPage,
    {title: tournamentTemp?.full_name || '', url: '#'},
  ]

  const cityObject = citiesOptions.find((city) => city.value === tournamentTemp?.city_id)
  const zoneObject = zonesOptions.find((zone) => zone.value === tournamentTemp?.zone_id)

  const initialValues: RegistrationTournamentFormValues = {
    user_id: Number(userId),
    full_name: tournamentTemp?.full_name || '',
    short_name: tournamentTemp?.short_name || '',
    club: tournamentTemp?.club || '',
    club_address: tournamentTemp?.club_address || '',
    start_dt: tournamentTemp?.start_dt || '',
    end_dt: tournamentTemp?.end_dt || '',
    city_id: cityObject || undefined,
    zone_id: zoneObject || undefined,
    court_coverage: tournamentTemp?.court_coverage || '',
    official_ball: tournamentTemp?.official_ball || '',
    contact_info: tournamentTemp?.contact_info || '',
    is_draft: 0,
  }
  const validationSchema = registrationTournamentFormValidationSchema

  const onSubmit = async (values: RegistrationTournamentFormValues) => {
    dispatch(createTournament(values))
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e)
  }

  const handleSelectChange = (
    selectedOption: SingleValue<FilterOption>,
    actionMeta: ActionMeta<FilterOption>,
  ) => {
    const event = {
      target: {
        name: actionMeta.name,
        value: selectedOption || null,
      },
    }
    formik.handleChange(event)
  }

  useEffect(() => {
    if (requestId) {
      dispatch(fetchTournamentsRequest(Number(requestId)))
      formik.setFieldValue('user_id', Number(userId))
    }
    // eslint-disable-next-line
  }, [dispatch, requestId, userId])

  const handleSaveDraft = () => {
    formik.setFieldValue('is_draft', 1)
    formik.handleSubmit()
  }

  const handleSaveAndSubmit = () => {
    formik.setFieldValue('is_draft', 0)
    formik.handleSubmit()
  }

  if (isTempLoading) {
    return <div>Loading...</div>
  }

  if (isTempError) {
    return (
      <Stub
        type={isTempError ? 'error' : ''}
        error={isTempError ? 'Произошла ошибка при загрузке данных.' : ''}
      />
    )
  }
  return (
    <div className="container">
      <div className="gap gap--20">
        <div className="gap gap--20">
          <CrumbsList routes={crumbsRoutes} />
          <div className="gap">
            <div className="gap gap--row gap--15">
              <div className="gap gap--50 gap--row-2">
                <Title title={tournamentTemp?.full_name} className="title--uppercase" />
                <RequestStatus status={tournamentTemp?.status} />
              </div>
              <ButtonsList buttonsData={filteredButtons} />
            </div>
            {tournamentTemp?.comment && <Reason comment={tournamentTemp.comment} />}
          </div>
          <div className="gap gap-40">
            <form className="background-form">
              <TournamentForm
                values={formik.values}
                errors={formik.errors}
                onChangeInput={handleInputChange}
                onChangeSelect={handleSelectChange}
              />
            </form>
            <div className="saves">
              <ButtonForm className="save--green" title="Сохранить" onClick={handleSaveDraft} />
              <ButtonForm title="Сохранить и отправить на проверку" onClick={handleSaveAndSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestTournamentPage
