import React, {useMemo, useEffect, useState} from 'react'
import CrumbsList from '../../components/ordinary/crumbs-list/crumbs-list'
import Title from '../../components/ui/title/title'
import ButtonsList from '../../components/ui/buttons-list/buttons-list'
import {ratingPage} from '../../core/constants/navigations'
import Pagination from '../../components/ordinary/pagination/pagination'
import FilterRatingPlayersList from '../../components/simple/filter-rating-list/filter-rating-players-list'
import Amount from '../../components/ordinary/amount/amount'
import RatingPlayersTable from '../../components/ordinary/rating-players-table/rating-players-table'
import {useAppDispatch, useAppSelector} from '../../core/hooks'
import {fetchPlayersRating, fetchPlayersStats} from '../../core/store/api-actions'
import {RATING_PER_PAGE} from '../../core/constants/common'
import AppRoute from '../../core/constants/routes'
import Stub from '../../components/ordinary/stub/stub'
import {
  filterPlayersRatingByString,
  sortPlayersByEfficiency,
} from '../../core/utils/array-prepare-helpers'
import {
  getPlayersRating,
  getPlayersRatingError,
  getPlayersRatingFilterSeasonStatus,
  getPlayersRatingLoading,
  getPlayersRatingSearchString,
} from '../../core/store/players-rating-data/selectors'
import {
  getPlayersStats,
  getPlayersStatsError,
  getPlayersStatsLoading,
} from '../../core/store/players-stats-data/selectors'

function PlayersRatingPage() {
  const dispatch = useAppDispatch()
  const crumbsRoutes = [AppRoute.Main, AppRoute.PlayersRatingPage]
  const playersRating = useAppSelector(getPlayersRating)
  const playersRatingLoading = useAppSelector(getPlayersRatingLoading)
  const playersRatingError = useAppSelector(getPlayersRatingError)
  const playersStats = useAppSelector(getPlayersStats)
  const playersStatsLoading = useAppSelector(getPlayersStatsLoading)
  const playersStatsError = useAppSelector(getPlayersStatsError)
  const pageLoading = playersRatingLoading || playersStatsLoading
  const pageError = playersRatingError || playersStatsError
  const playersRatingSearchString = useAppSelector(getPlayersRatingSearchString)
  const filterSeasonStatus = useAppSelector(getPlayersRatingFilterSeasonStatus)

  const createProcessedPlayersRating = useMemo(() => {
    let processedPlayersRating = playersRating
    if (playersRatingSearchString !== '') {
      processedPlayersRating = filterPlayersRatingByString(
        processedPlayersRating,
        playersRatingSearchString,
      )
    }
    processedPlayersRating = sortPlayersByEfficiency(processedPlayersRating, playersStats)
    return processedPlayersRating
  }, [playersRatingSearchString, playersRating, playersStats])

  const [currentPage, setCurrentPage] = useState(0)
  const playersRatingPerPage = RATING_PER_PAGE
  const pageCount = Math.ceil((createProcessedPlayersRating?.length || 0) / playersRatingPerPage)
  const indexOfLastPlayerRating = (currentPage + 1) * playersRatingPerPage
  const indexOfFirstPlayerRating = indexOfLastPlayerRating - playersRatingPerPage
  const currentPlayersRating = createProcessedPlayersRating.slice(
    indexOfFirstPlayerRating,
    indexOfLastPlayerRating,
  )

  const handlePageClick = (selectedPage: {selected: number}) => {
    setCurrentPage(selectedPage.selected)
  }

  useEffect(() => {
    if (filterSeasonStatus?.label && filterSeasonStatus.value !== 'all') {
      dispatch(fetchPlayersRating({seasonId: Number(filterSeasonStatus.value)}))
      dispatch(fetchPlayersStats({seasonId: Number(filterSeasonStatus.value)}))
    } else {
      dispatch(fetchPlayersRating({}))
      dispatch(fetchPlayersStats({}))
    }
  }, [dispatch, filterSeasonStatus])

  return (
    <div className="container">
      <div className="gap gap--20">
        <CrumbsList routes={crumbsRoutes} />
        <div className="gap gap--row">
          <Title className="title--uppercase" title="рейтинг игроков" />
          <ButtonsList buttonsData={ratingPage} />
        </div>
        <div className={`gap gap--25 ${pageLoading ? 'hidden' : ''}`}>
          <div className="gap gap--0">
            <div className="gap gap--filter">
              <Amount amount={createProcessedPlayersRating.length} type="игроков" />
              <FilterRatingPlayersList />
            </div>
            <Stub
              type={
                pageError
                  ? 'error'
                  : playersRating.length > 0 && createProcessedPlayersRating.length === 0
                    ? 'searchError'
                    : playersRating.length === 0
                      ? 'absence'
                      : ''
              }
              error={pageError ? 'Произошла ошибка при загрузке рейтинга игроков.' : ''}
            />
          </div>
          <div className="gap ga--40">
            <RatingPlayersTable playersRating={currentPlayersRating} playersStats={playersStats} />
            <Pagination
              pageCount={pageCount}
              forcePage={currentPage}
              onPageClick={handlePageClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlayersRatingPage
