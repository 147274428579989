import React, {ChangeEvent, useEffect, useState} from 'react'
import {FormikErrors} from 'formik'
import {ActionMeta, SingleValue} from 'react-select'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {fetchDesireRoles} from '../../../core/store/api-actions'
import {getDesireRoles} from '../../../core/store/desire-roles-data/selectors'
import {SelectTypes} from '../../../core/constants/selects'
import FormSelect from '../../smart/select/select'
import {RegistrationFormValues} from '../../../core/types/form'
import {FilterOption} from '../../../core/types/common'

function ModalRegistrationList({
  values,
  errors,
  onChangeInput,
  onChangeSelect,
}: {
  values: RegistrationFormValues
  errors: FormikErrors<RegistrationFormValues>
  onChangeInput: (e: ChangeEvent<HTMLInputElement>) => void
  onChangeSelect: (
    selectedOption: SingleValue<FilterOption>,
    actionMeta: ActionMeta<FilterOption>,
  ) => void
}) {
  const dispatch = useAppDispatch()
  const [firstShowPassword, setFirstShowPassword] = useState(false)
  const [secondShowPassword, setSecondShowPassword] = useState(false)
  const desireRoles = useAppSelector(getDesireRoles)

  useEffect(() => {
    dispatch(fetchDesireRoles())
  }, [dispatch])

  return (
    <div className="modal__list">
      <label className="form" htmlFor="regitsration-first-name">
        <p className="form__text">
          Имя
          <span className={`form__help ${errors.firstName ? '' : 'hidden'}`}>
            {errors.firstName}
          </span>
        </p>
        <input
          id="regitsration-first-name"
          className={`form__input ${errors.firstName ? 'form__input--error' : ''}`}
          type="text"
          placeholder="Введите ваше имя"
          name="firstName"
          value={values.firstName}
          onChange={(e) => onChangeInput(e)}
        />
      </label>
      <label className="form" htmlFor="registration-last-name">
        <p className="form__text">
          Фамилия
          <span className={`form__help ${errors.lastName ? '' : 'hidden'}`}>{errors.lastName}</span>
        </p>
        <input
          id="registration-last-name"
          className={`form__input ${errors.lastName ? 'form__input--error' : ''}`}
          type="text"
          placeholder="Введите вашу фамилию"
          name="lastName"
          value={values.lastName}
          onChange={(e) => onChangeInput(e)}
        />
      </label>
      <label className="form" htmlFor="registration-email">
        <p className="form__text">
          E-mail
          <span className={`form__help ${errors.email ? '' : 'hidden'}`}>{errors.email}</span>
        </p>
        <input
          id="registration-email"
          className={`form__input ${errors.email ? 'form__input--error' : ''}`}
          type="text"
          placeholder="Введите почту"
          name="email"
          value={values.email}
          onChange={(e) => onChangeInput(e)}
        />
      </label>
      <label className="form" htmlFor="registration-phone">
        <p className="form__text">
          Телефон
          <span className={`form__help ${errors.phone ? '' : 'hidden'}`}>{errors.phone}</span>
        </p>
        <input
          id="registration-phone"
          className={`form__input ${errors.phone ? 'form__input--error' : ''}`}
          type="text"
          placeholder="Введите номер"
          name="phone"
          value={values.phone}
          onChange={(e) => onChangeInput(e)}
        />
      </label>
      <div className="form">
        <p className="form__text">Роль</p>
        <FormSelect
          options={desireRoles}
          type={SelectTypes.DesireRoles}
          value={values.desireRole}
          name="desireRole"
          onChangeSelect={onChangeSelect}
        />
      </div>
      <label className="form form--password" htmlFor="registration-password">
        <p className="form__text">
          Пароль
          <span className={`form__help ${errors.password ? '' : 'hidden'}`}>{errors.password}</span>
        </p>
        <input
          id="registration-password"
          className={`form__input ${errors.password ? 'form__input--error' : ''}`}
          type={firstShowPassword ? 'text' : 'password'}
          placeholder="Придумайте пароль"
          name="password"
          value={values.password}
          onChange={(e) => onChangeInput(e)}
        />
        <button
          className={`form__control ${firstShowPassword ? 'form__control--view' : ''}`}
          type="button"
          onClick={() => setFirstShowPassword(!firstShowPassword)}
        />
      </label>
      <label className="form form--password" htmlFor="registration-password-repeat">
        <p className="form__text">
          Подтверждение пароля
          <span className={`form__help ${errors.passwordRepeat ? '' : 'hidden'}`}>
            {errors.passwordRepeat}
          </span>
        </p>
        <input
          id="registration-password-repeat"
          className={`form__input ${errors.passwordRepeat ? 'form__input--error' : ''}`}
          type={secondShowPassword ? 'text' : 'password'}
          placeholder="Повторите придуманный пароль"
          name="passwordRepeat"
          value={values.passwordRepeat}
          onChange={(e) => onChangeInput(e)}
        />
        <button
          className={`form__control ${secondShowPassword ? 'form__control--view' : ''}`}
          type="button"
          onClick={() => setSecondShowPassword(!secondShowPassword)}
        />
      </label>
    </div>
  )
}

export default ModalRegistrationList
