import React from 'react'
import CrumbsList from '../../components/ordinary/crumbs-list/crumbs-list'
import PlayersItem from '../../components/ordinary/players-item/players-item'
import StatsTable from '../../components/ordinary/stats-table/stats-table'
import Stub from '../../components/ordinary/stub/stub'
import ButtonsList from '../../components/ui/buttons-list/buttons-list'
import {useAppSelector} from '../../core/hooks'
import {
  getPlayer,
  getPlayerErrorStatus,
  getPlayerLoadingStatus,
} from '../../core/store/players-data/selectors'
import AppRoute from '../../core/constants/routes'
import {generatePlayerButtonsArray} from '../../core/utils/common-helpers'
import {
  getPlayersStatsError,
  getPlayersStatsLoading,
} from '../../core/store/players-stats-data/selectors'

function PlayerStatPage() {
  const player = useAppSelector(getPlayer)
  const playerLoading = useAppSelector(getPlayerLoadingStatus)
  const playerError = useAppSelector(getPlayerErrorStatus)
  const playerStatsLoading = useAppSelector(getPlayersStatsLoading)
  const playersStatsError = useAppSelector(getPlayersStatsError)
  const playerStatPageLoading = playerLoading || playerStatsLoading
  const playerStatPageError = playerError || playersStatsError
  const buttonsArray = generatePlayerButtonsArray(player)

  const crumbsRoutes = [
    AppRoute.Main,
    AppRoute.PlayersRatingPage,
    {
      title: `${player?.first_name} ${player?.last_name}`,
      url: '#',
    },
  ]

  return (
    <div className="container">
      <div
        className={`gap gap--40 ${playerStatPageLoading || playerStatPageError ? 'hidden' : ''}`}>
        <CrumbsList routes={crumbsRoutes} />
        <div className="grid-profile">
          <PlayersItem />
          <div className="gap gap--0 gap--start">
            <ButtonsList className="buttons--border" buttonsData={buttonsArray} />
            <div className="blocks">
              <svg className="blocks__background" width="1400" height="600">
                <use xlinkHref="images/sprite.svg#background-logo" />
              </svg>
              <StatsTable />
            </div>
          </div>
        </div>
      </div>
      <Stub
        type={playerStatPageError ? 'error' : ''}
        error={
          playerStatPageError ? 'Произошла ошибка при загрузке страницы статистики игрока.' : ''
        }
      />
    </div>
  )
}

export default PlayerStatPage
