import NameSpace from '../../constants/api-constants'
import {TeamPlayer} from '../../../api'

import {State} from '../index'

export const getPlayerTeamsLoading = (state: State): boolean =>
  state[NameSpace.PlayerTeams].playerTeamsLoading
export const getPlayerTeamsError = (state: State): boolean =>
  state[NameSpace.PlayerTeams].playerTeamsError
export const getPlayerTeams = (state: State): TeamPlayer[] =>
  state[NameSpace.PlayerTeams].playerTeams
