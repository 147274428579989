import React from 'react'
import {Link} from 'react-router-dom'
import {MatchSeries} from '../../../api'
import {simpleDateFormat} from '../../../core/utils/date-time-helpers'
import AppRoute from '../../../core/constants/routes'

function MatchItem({series}: {series: MatchSeries}) {
  const startDateTime = series.start_dt ? new Date(series.start_dt) : ''
  const startTime = startDateTime ? simpleDateFormat(startDateTime, 'HH:mm') : 'время не указано'
  const startDate = startDateTime
    ? simpleDateFormat(startDateTime, 'dd MMMM yyyy')
    : 'дата не указана'

  return (
    <li className="match__item">
      <Link
        className="match__link match__grid"
        to={`${AppRoute.MatchPage.url}/${series.series_id}`}>
        <div className="match__date">
          <div className="match__data match__data--milky">
            <svg className="match__icon" width="20" height="20">
              <use xlinkHref="images/sprite.svg#calendar" />
            </svg>
            <p className="match__text">{startDate}</p>
          </div>
          <p className="match__time">{startTime}</p>
        </div>
        <div className="match__wrap">
          <div className="match__team match__end">
            <img
              className="match__img"
              src={
                series.team1?.avatar?.includes('logo_placeholder_100x100.svg')
                  ? '/images/default/team.svg'
                  : series.team1?.avatar
              }
              width="40"
              height="40"
              alt={series.team1?.full_name}
            />
            <p className="match__name">{series.team1?.full_name}</p>
          </div>
          <div className="match__score">
            <p
              className={`match__number ${series.score1 !== undefined && series.score2 !== undefined && series.score1 > series.score2 ? 'match__win' : ''}`}>
              {series.score1 === null ? '-' : series.score1}
            </p>
            <p className="match__dot">:</p>
            <p
              className={`match__number ${series.score2 !== undefined && series.score1 !== undefined && series.score2 > series.score1 ? 'match__win' : ''}`}>
              {series.score2 === null ? '-' : series.score2}
            </p>
          </div>
          <div className="match__team">
            <img
              className="match__img"
              src={
                series.team2?.avatar?.includes('logo_placeholder_100x100.svg')
                  ? '/images/default/team.svg'
                  : series.team2?.avatar
              }
              width="40"
              height="40"
              alt={series.team2?.full_name}
            />
            <p className="match__name">{series.team2?.full_name}</p>
          </div>
        </div>
        <div className="match__data">
          <svg className="match__icon" width="14" height="14">
            <use xlinkHref="images/sprite.svg#location" />
          </svg>
          <p className="match__text">{series.club_address || 'адрес не указан'}</p>
        </div>
      </Link>
    </li>
  )
}

export default MatchItem
