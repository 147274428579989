import NameSpace from '../../constants/api-constants'
import {TeamPlayer} from '../../../api'
import {State} from '../index'

export const getTeamPlayersLoadingStatus = (state: State): boolean =>
  state[NameSpace.TeamPlayers].isTeamPlayersLoading
export const getTeamPlayersErrorStatus = (state: State): boolean =>
  state[NameSpace.TeamPlayers].isTeamPlayersError
export const getTeamPlayers = (state: State): TeamPlayer[] =>
  state[NameSpace.TeamPlayers].teamPlayers
