import React from 'react'
import {Link} from 'react-router-dom'
import AppRoute from '../../../core/constants/routes'

function Personal() {
  return (
    <div className="personal">
      <p className="personal__text">
        Вам ещё не присвоена роль, поэтому функционал личного кабинета пока недоступен. После
        присвоения роли вы сможете зарегистрировать команду или турнир, а также произвести оплату.
      </p>
      <Link className="personal__link" to={AppRoute.Main.url}>
        Перейти на главную
      </Link>
    </div>
  )
}

export default Personal
