import React, {useEffect} from 'react'
import CrumbsList from '../../components/ordinary/crumbs-list/crumbs-list'
import CommandsItem from '../../components/ordinary/commands-item/commands-item'
import ButtonsList from '../../components/ui/buttons-list/buttons-list'
import PlayerList from '../../components/ordinary/player-list/player-list'
import {useAppDispatch, useAppSelector} from '../../core/hooks'
import {
  getTeam,
  getTeamErrorStatus,
  getTeamLoadingStatus,
} from '../../core/store/teams-data/selectors'
import {generateTeamButtonsArray} from '../../core/utils/common-helpers'
import AppRoute from '../../core/constants/routes'
import Stub from '../../components/ordinary/stub/stub'
import {
  getTeamPlayers,
  getTeamPlayersErrorStatus,
  getTeamPlayersLoadingStatus,
} from '../../core/store/team-players-data/selectors'
import {getPlayersStats} from '../../core/store/players-stats-data/selectors'
import {fetchPlayersStats} from '../../core/store/api-actions'

function CommandLineUpPage() {
  const dispatch = useAppDispatch()
  const team = useAppSelector(getTeam)
  const teamLoading = useAppSelector(getTeamLoadingStatus)
  const teamError = useAppSelector(getTeamErrorStatus)
  const teamPlayers = useAppSelector(getTeamPlayers)
  const teamPlayersLoading = useAppSelector(getTeamPlayersLoadingStatus)
  const teamPlayersError = useAppSelector(getTeamPlayersErrorStatus)
  const playersStats = useAppSelector(getPlayersStats)
  const teamLineUpPageLoading = teamLoading || teamPlayersLoading
  const teamLineUpPageError = teamError || teamPlayersError
  const buttonsArray = generateTeamButtonsArray(team)
  const crumbsRoutes = [
    AppRoute.Main,
    AppRoute.CommandsRatingPage,
    {
      title: team?.full_name || '',
      url: '#',
    },
  ]

  useEffect(() => {
    dispatch(fetchPlayersStats({}))
  }, [dispatch])

  return (
    <div className="container">
      <div
        className={`gap gap--40 ${teamLineUpPageLoading || teamLineUpPageError ? 'hidden' : ''}`}>
        <CrumbsList routes={crumbsRoutes} />
        <div className="grid-profile">
          <CommandsItem />
          <div className="gap gap--0 gap--start">
            <ButtonsList className="buttons--border" buttonsData={buttonsArray} />
            <div className="blocks">
              <svg className="blocks__background" width="1400" height="600">
                <use xlinkHref="images/sprite.svg#background-logo" />
              </svg>
              <div className="blocks__container">
                <PlayerList teamPlayers={teamPlayers} playersStats={playersStats} />
                <Stub type={teamPlayers.length === 0 ? 'absence' : ''} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Stub
        type={teamLineUpPageError ? 'error' : ''}
        error={teamLineUpPageError ? 'Произошла ошибка при загрузке страницы состава команды.' : ''}
      />
    </div>
  )
}

export default CommandLineUpPage
