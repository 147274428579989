import React from 'react'

function Stub({type, error}: {type: string; error?: string}) {
  return (
    <>
      <div className={`stub ${type !== 'searchError' ? 'hidden' : ''}`}>
        <p className="stub__text">По заданным параметрам информации не найдено.</p>
        <p className="stub__text">Попробуйте другие параметры!</p>
      </div>
      <div className={`stub ${type !== 'absence' ? 'hidden' : ''}`}>
        <p className="stub__text">На данный момент информация не опубликована.</p>
        <p className="stub__text">Ожидайте, так как в скором времени она появится!</p>
      </div>
      <div className={`stub ${type !== 'absenceMatch' ? 'hidden' : ''}`}>
        <p className="stub__text">Информация по матчам появится в ближайшее время.</p>
        <p className="stub__text">Спасибо за ваше терпение и оставайтесь с нами!</p>
      </div>
      <div className={`stub stub--player ${type !== 'absencePlayer' ? 'hidden' : ''}`}>
        <p className="stub__text">На данный момент информация об игроке не сформирована, </p>
        <p className="stub__text">но в скором времени она появится</p>
      </div>
      <div className={`stub ${type !== 'error' ? 'hidden' : ''}`}>
        <p className="stub__text">{error}</p>
        <p className="stub__text">Попробуйте обновить страницу.</p>
      </div>
    </>
  )
}

export default Stub
