import React from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import App from './app'
import store from './core/store'
import browserHistory from './core/services/browser-history/browser-history'
import HistoryRoute from './components/smart/history-route/history-route'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <HistoryRoute history={browserHistory}>
      <App />
    </HistoryRoute>
  </Provider>,
)
