import React, {useState} from 'react'
import ImageCropper from '../../smart/image-cropper/image-cropper'

function Avatar({
  profile,
  onFileChange,
}: {
  profile: string
  onFileChange: (file: File | null) => void
}) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [croppedImage, setCroppedImage] = useState<string | null>(null)
  const [isCropping, setIsCropping] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]
    if (file) {
      const img = new Image()
      img.onload = () => {
        if (img.width < 400 || img.height < 400) {
          setError('Размер изображения должен быть не менее 400x400 пикселей')
          setSelectedFile(null)
        } else {
          setError(null)
          setSelectedFile(file)
          setIsCropping(true)
        }
      }
      img.src = URL.createObjectURL(file)
    }
  }

  const handleRemovePhoto = () => {
    setSelectedFile(null)
    setCroppedImage(null)
    onFileChange(null)
  }

  const handleCropComplete = (croppedImgFile: File | null) => {
    setIsCropping(false)
    if (croppedImgFile) {
      setCroppedImage(URL.createObjectURL(croppedImgFile))
      onFileChange(croppedImgFile)
    }
  }

  return (
    <div className="avatar">
      {croppedImage ? (
        <img
          className="avatar__img"
          src={croppedImage}
          width="160"
          height="160"
          alt="Фото команды"
        />
      ) : (
        <img
          className="avatar__img"
          src={`images/default/${profile}`}
          alt="Загрузить фото"
          width="160"
          height="160"
        />
      )}
      <div className="avatar__texts">
        <p className="avatar__text">Мин. размер фото 400x400</p>
        <label htmlFor="avatar-upload" className="avatar__title">
          {selectedFile ? 'Изменить изображение' : 'Загрузить изображение'}
        </label>
        <input
          id="avatar-upload"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{display: 'none'}}
        />
        {selectedFile && (
          <button type="button" className="avatar__title" onClick={handleRemovePhoto}>
            Удалить изображение
          </button>
        )}
        {error && <span className="avatar__error">{error}</span>}
      </div>
      {isCropping && selectedFile && (
        <div className="cropper-overlay">
          <ImageCropper
            image={URL.createObjectURL(selectedFile)}
            onCropCompleteBase64={() => {}}
            onCropCompleteFile={handleCropComplete}
            onCancel={() => setIsCropping(false)}
            outputType="file"
          />
        </div>
      )}
    </div>
  )
}

export default Avatar
