import React from 'react'
import CrumbsList from '../../components/ordinary/crumbs-list/crumbs-list'
import PlayersItem from '../../components/ordinary/players-item/players-item'
import Stub from '../../components/ordinary/stub/stub'
import HistoryStatsTable from '../../components/simple/history-stats-table/history-stats-table'
import ButtonsList from '../../components/ui/buttons-list/buttons-list'
import {useAppSelector} from '../../core/hooks'
import {
  getPlayer,
  getPlayerErrorStatus,
  getPlayerLoadingStatus,
} from '../../core/store/players-data/selectors'
import AppRoute from '../../core/constants/routes'
import {generatePlayerButtonsArray} from '../../core/utils/common-helpers'
import {
  getPlayerTeamsError,
  getPlayerTeamsLoading,
} from '../../core/store/player-teams-data/selectors'

function PlayerHistoryPage() {
  const player = useAppSelector(getPlayer)
  const playerLoading = useAppSelector(getPlayerLoadingStatus)
  const playerError = useAppSelector(getPlayerErrorStatus)
  const playerTeamsLoading = useAppSelector(getPlayerTeamsLoading)
  const playerTeamsError = useAppSelector(getPlayerTeamsError)
  const playerHistoryPageLoading = playerLoading || playerTeamsLoading
  const playerHistoryPageError = playerError || playerTeamsError
  const buttonsArray = generatePlayerButtonsArray(player)

  const crumbsRoutes = [
    AppRoute.Main,
    AppRoute.PlayersRatingPage,
    {
      title: `${player?.first_name} ${player?.last_name}`,
      url: '#',
    },
  ]

  return (
    <div className="container">
      <div
        className={`gap gap--40 ${playerHistoryPageLoading || playerHistoryPageError ? 'hidden' : ''}`}>
        <CrumbsList routes={crumbsRoutes} />
        <div className="grid-profile">
          <PlayersItem />
          <div className="gap gap--0 gap--start">
            <ButtonsList className="buttons--border" buttonsData={buttonsArray} />
            <div className="blocks">
              <svg className="blocks__background" width="1400" height="600">
                <use xlinkHref="images/sprite.svg#background-logo" />
              </svg>
              <HistoryStatsTable />
            </div>
          </div>
        </div>
      </div>
      <Stub
        type={playerHistoryPageError ? 'error' : ''}
        error={
          playerHistoryPageError ? 'Произошла ошибка при загрузке страницы история игрока.' : ''
        }
      />
    </div>
  )
}

export default PlayerHistoryPage
