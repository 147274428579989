import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import CrumbsList from '../../components/ordinary/crumbs-list/crumbs-list'
import Title from '../../components/ui/title/title'
import Author from '../../components/ordinary/author/author'
import HashtagList from '../../components/ordinary/hashtag-list/hashtag-list'
import AlbumList from '../../components/ordinary/album-list/album-list'
import {useAppDispatch, useAppSelector} from '../../core/hooks'
import {getAlbumLoadingStatus, getCurrentAlbum} from '../../core/store/albums-data/selectors'
import AppRoute from '../../core/constants/routes'
import {fetchAlbum} from '../../core/store/api-actions'
import {ApiAlbumsIdGetWithEnum} from '../../api'

function AlbumPage() {
  const dispatch = useAppDispatch()
  const {id: albumId} = useParams()
  const album = useAppSelector(getCurrentAlbum)
  // const albumError = useAppSelector(getAlbumErrorStatus);
  const albumLoading = useAppSelector(getAlbumLoadingStatus)
  const currentURL = window.location.href
  const crumbsRoutes = [
    AppRoute.Main,
    AppRoute.AlbumsPage,
    {title: album?.title || '', url: currentURL},
  ]

  useEffect(() => {
    dispatch(
      fetchAlbum({
        albumId: Number(albumId),
        _with: [ApiAlbumsIdGetWithEnum.Photos],
      }),
    )
  }, [albumId, dispatch])

  return (
    <div className="container">
      <div className={`gap gap--20 ${albumLoading ? 'hidden' : ''}`}>
        <CrumbsList routes={crumbsRoutes} />
        <Title title={album?.title} />
        <Author name={album?.author} number={album?.photos?.length} date={album?.published_dt} />
        <HashtagList tags={album?.tags} />
        <div className="gap gap--40">
          <AlbumList photos={album?.photos} />
        </div>
      </div>
    </div>
  )
}

export default AlbumPage
