import NameSpace from '../../constants/api-constants'
import {ApiError} from '../../types/state'

import {State} from '../index'

export const getCreateTeamLoadingStatus = (state: State): boolean =>
  state[NameSpace.CreateTeamTemps].CreateTeamLoading
export const getCreateTeamStatus = (state: State): boolean | undefined =>
  state[NameSpace.CreateTeamTemps].createTeamStatus
export const getCreateTeamErrors = (state: State): ApiError[] =>
  state[NameSpace.CreateTeamTemps].CreateTeamErrors
export const getCreateTeamDuplicates = (state: State): ApiError[] =>
  state[NameSpace.CreateTeamTemps].CreateTeamDuplicates
