/* eslint-disable react/no-danger */
import React, {useEffect} from 'react'
import $ from 'jquery'
import {RoundPlayoffHtml} from '../../../api'
import '../../../assets/css/playoff.css'
import '../../../assets/js/playoff'

function Grids({roundPlayoffHtml}: {roundPlayoffHtml?: RoundPlayoffHtml}) {
  // eslint-disable-next-line
  const playoff = ($ as any).playoff
  useEffect(() => {
    if (playoff && typeof playoff.destroy === 'function') {
      playoff.destroy()
    }
    playoff.init()
    return () => {
      if (playoff && typeof playoff.destroy === 'function') {
        playoff.destroy()
      }
    }
  }, [roundPlayoffHtml, playoff])

  return (
    <div className="gap gap--40">
      <div dangerouslySetInnerHTML={{__html: roundPlayoffHtml?.html || ''}} />
    </div>
  )
}

export default Grids
