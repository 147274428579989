import React from 'react'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getRecoveryPasswordSuccessModalStatus} from '../../../core/store/modals-data/selectors'
import {
  changeAuthorizationModal,
  changeRecoveryPasswordSuccessModal,
} from '../../../core/store/modals-data/modals-data'
import Title from '../../ui/title/title'

function ModalRecoverySuccess() {
  const dispatch = useAppDispatch()
  const isModalOpen = useAppSelector(getRecoveryPasswordSuccessModalStatus)

  const handleModalClose = () => {
    dispatch(changeRecoveryPasswordSuccessModal({recoveryPasswordSuccessModal: false}))
  }

  const onClick = () => {
    dispatch(changeRecoveryPasswordSuccessModal({recoveryPasswordSuccessModal: false}))
    dispatch(changeAuthorizationModal({authorizationModal: true}))
  }

  return (
    <div className={`modal ${isModalOpen ? '' : 'modal--close'}`}>
      <div className="modal__container">
        <div className="modal__scroll">
          <div className="modal__background">
            <svg className="modal__logo" width="1025" height="440">
              <use xlinkHref="images/sprite.svg#background-logo" />
            </svg>
            <div className="modal__form">
              <Title
                title="Вы успешно восстановили пароль."
                className="title--center title--white"
              />
              <button
                type="button"
                className="modal__button modal__button--uppercase"
                onClick={onClick}>
                Войти
              </button>
            </div>
          </div>
        </div>
        <button type="button" className="modal__close" onClick={handleModalClose}>
          <svg className="modal__icon" width="45" height="45">
            <use xlinkHref="images/sprite.svg#close" />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default ModalRecoverySuccess
