import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../core/hooks'
import {getCurrentPage, getPageLoadingStatus} from '../../core/store/pages-data/selectors'
import {fetchPageAlias} from '../../core/store/api-actions'
import AsideBanners from '../../components/simple/aside-banners/aside-banners'
import CrumbsList from '../../components/ordinary/crumbs-list/crumbs-list'
import Title from '../../components/ui/title/title'
import RedactorStyles from '../../components/ordinary/redactor-styles/redactor-styles'
import Contact from '../../components/ordinary/contact/contact'
import DocumentsList from '../../components/ordinary/documents-list/documents-list'
import AppRoute from '../../core/constants/routes'
import {BannerPositionEnum, HdbkDocument} from '../../api'

function AboutUsPage() {
  const {alias} = useParams()
  const dispatch = useAppDispatch()
  const page = useAppSelector(getCurrentPage)
  const pageLoading = useAppSelector(getPageLoadingStatus)
  const currentURL = window.location.href
  const crumbsRoutes = [AppRoute.Main, {title: page?.title || '', url: currentURL}]

  useEffect(() => {
    if (alias != null) {
      dispatch(fetchPageAlias({alias, _with: ['documents']}))
    }
  }, [alias, dispatch])

  let documents: HdbkDocument[] = []
  if (page && 'documents' in page && Array.isArray(page.documents)) {
    documents = page?.documents || []
  }

  return (
    <div className="main__container">
      <div className="container-left">
        <div className={`gap gap--20 ${pageLoading ? 'hidden' : ''}`}>
          <CrumbsList routes={crumbsRoutes} />
          <Title className="title--uppercase" title={page?.title || 'О нас'} />
          <div className="grid grid--sidebar">
            <RedactorStyles string={page?.text || ''} />
            <Contact page={page} />
            <DocumentsList text="Документы" documents={documents} />
          </div>
        </div>
      </div>
      <AsideBanners
        bannerPositions={[
          BannerPositionEnum.SidebarStaticPages,
          BannerPositionEnum.SidebarMainWithStatic,
        ]}
      />
    </div>
  )
}

export default AboutUsPage
