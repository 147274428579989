import React from 'react'
import FilterTeamMatchesItem from '../../smart/filter-team-matches-item/filter-team-matches-item'

function FilterTeamMatches() {
  return (
    <ul className="filter filter--pages">
      <FilterTeamMatchesItem />
    </ul>
  )
}

export default FilterTeamMatches
