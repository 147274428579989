import React from 'react'
import {matchPath, useLocation} from 'react-router-dom'
import AppRoute from '../../../core/constants/routes'
import {Post, Video} from '../../../api'
import HtmlRenderer from '../../smart/html-renderer/html-renderer'

function Images({data}: {data: Video | Post}) {
  const location = useLocation()
  const isVideoPlay = !!matchPath({path: AppRoute.VideoPage.url}, location.pathname)

  let postSrc
  let videoSrc
  if (data) {
    if ('code' in data) {
      videoSrc = data.code
    }
  }
  if (data && data._links) {
    if ('image1120x633' in data._links) {
      postSrc = data._links?.image1120x633?.href
      if (data._links?.image1120x633?.href?.match(/image_placeholder/)) {
        postSrc = '/images/default/content.svg'
      }
    }
  } else {
    postSrc = '/images/default/content.svg'
  }

  if (isVideoPlay) {
    return (
      <div className="images">
        <div className="video-iframe">
          <HtmlRenderer htmlString={videoSrc || ''} />
        </div>
      </div>
    )
  }
  return (
    <div className="images">
      <img
        className="images__picture"
        src={postSrc}
        width="996"
        height="555"
        alt={data?.title || ''}
      />
    </div>
  )
}

export default Images
