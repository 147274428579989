import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getSeasons} from '../../../core/store/seasons-data/selectors'
import {transformSeasonsToOptions} from '../../../core/utils/array-prepare-helpers'
import {fetchSeasons} from '../../../core/store/api-actions'
import Dropdown from '../dropdown/dropdown'
import {DropdownTypes} from '../../../core/constants/filters'
import {getItemForPageType} from '../../../core/utils/common-helpers'

function FilterPagesItem() {
  const dispatch = useAppDispatch()
  const seasons = useAppSelector(getSeasons)
  const seasonsOptions = transformSeasonsToOptions(seasons)
  useEffect(() => {
    dispatch(fetchSeasons())
  }, [dispatch])

  const location = useLocation()
  const items = getItemForPageType(location.pathname)

  return (
    <li className="filter__item">
      <Dropdown options={seasonsOptions} type={DropdownTypes.Season} items={items} />
    </li>
  )
}

export default FilterPagesItem
