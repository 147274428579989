import React, {useEffect, useMemo, useState} from 'react'
import CrumbsList from '../../components/ordinary/crumbs-list/crumbs-list'
import Title from '../../components/ui/title/title'
import FilterTournaments from '../../components/ordinary/filter-tournaments/filter-tournaments'
import Pagination from '../../components/ordinary/pagination/pagination'
import TournamentsList from '../../components/ordinary/tournaments-list/tournaments-list'
import {useAppDispatch, useAppSelector} from '../../core/hooks'
import {
  getTournaments,
  getTournamentsErrorStatus,
  getTournamentsFilterRegionStatus,
  getTournamentsFilterSeasonStatus,
  getTournamentsLoadingStatus,
  getTournamentsSearchString,
} from '../../core/store/tournaments-data/selectors'
import AppRoute from '../../core/constants/routes'
import {TOURNAMENTS_PER_PAGE} from '../../core/constants/common'
import {fetchTournaments} from '../../core/store/api-actions'
import {
  filterItemsByRegion,
  filterItemsBySeason,
  filterTournamentsByString,
} from '../../core/utils/array-prepare-helpers'
import {getSeasonsLoadingStatus} from '../../core/store/seasons-data/selectors'
import {getRegionsLoadingStatus} from '../../core/store/regions-data/selectors'

function TournamentsPage() {
  const dispatch = useAppDispatch()
  const crumbsRoutes = [AppRoute.Main, AppRoute.TournamentsPage]
  const tournaments = useAppSelector(getTournaments)
  const tournamentsErrorStatus = useAppSelector(getTournamentsErrorStatus)
  const tournamentsSearchString = useAppSelector(getTournamentsSearchString)
  const filterSeasonStatus = useAppSelector(getTournamentsFilterSeasonStatus)
  const filterRegionStatus = useAppSelector(getTournamentsFilterRegionStatus)

  useEffect(() => {
    dispatch(fetchTournaments())
  }, [dispatch])

  const createProcessedTournaments = useMemo(() => {
    let processedTournaments = tournaments

    if (filterSeasonStatus?.label && filterSeasonStatus.value !== 'all') {
      processedTournaments = filterItemsBySeason(processedTournaments, filterSeasonStatus.label)
    }
    if (filterRegionStatus?.label && filterRegionStatus.value !== 'all') {
      processedTournaments = filterItemsByRegion(processedTournaments, filterRegionStatus.label)
    }
    if (tournamentsSearchString !== '') {
      processedTournaments = filterTournamentsByString(
        processedTournaments,
        tournamentsSearchString,
      )
    }
    return processedTournaments
  }, [filterSeasonStatus, filterRegionStatus, tournaments, tournamentsSearchString])

  const tournamentsPerPage = TOURNAMENTS_PER_PAGE
  const pageCount = Math.ceil((createProcessedTournaments?.length || 0) / tournamentsPerPage)
  const [currentPage, setCurrentPage] = useState(0)
  const indexOfLastTournament = (currentPage + 1) * tournamentsPerPage
  const indexOfFirstTournament = indexOfLastTournament - tournamentsPerPage
  const currentTournaments = createProcessedTournaments.slice(
    indexOfFirstTournament,
    indexOfLastTournament,
  )

  const handlePageClick = (selectedPage: {selected: number}) => {
    if (selectedPage.selected >= 0 && selectedPage.selected < pageCount) {
      setCurrentPage(selectedPage.selected)
    }
  }

  const tournamentsLoading = useAppSelector(getTournamentsLoadingStatus)
  const seasonsLoading = useAppSelector(getSeasonsLoadingStatus)
  const regionsLoading = useAppSelector(getRegionsLoadingStatus)
  const tournamentsPageLoading = tournamentsLoading || seasonsLoading || regionsLoading

  return (
    <div className="container">
      <div className={`gap gap--40 ${tournamentsPageLoading ? 'hidden' : ''}`}>
        <div className="gap gap--25">
          <CrumbsList routes={crumbsRoutes} />
          <Title className="title--uppercase" title={AppRoute.TournamentsPage.title} />
          <div className="gap gap--0">
            <FilterTournaments
              tournaments={tournaments}
              tournamentsErrorStatus={tournamentsErrorStatus}
              filteredTournaments={createProcessedTournaments}
            />
          </div>
        </div>
        <TournamentsList tournaments={currentTournaments} />
        {pageCount > 1 && (
          <Pagination pageCount={pageCount} forcePage={currentPage} onPageClick={handlePageClick} />
        )}
      </div>
    </div>
  )
}

export default TournamentsPage
