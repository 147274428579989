import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {EventClickArg, EventContentArg} from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import ReactDOMServer from 'react-dom/server'
import CalendarInfo from '../../simple/calendar-info/calendar-info'
import EventModal from '../event-modal/event-modal'
import {eventTypeColors} from '../../../core/constants/common'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getMatches} from '../../../core/store/matches-data/selectors'
import {fetchMatches} from '../../../core/store/api-actions'
import {Match} from '../../../api'

// eslint-disable-next-line
function CalendarMain({calendarRef}: {calendarRef: any}) {
  const dispatch = useAppDispatch()
  const {id: teamId} = useParams()
  const matchesArray = useAppSelector(getMatches)
  useEffect(() => {
    dispatch(
      fetchMatches({
        teamId: Number(teamId),
        _with: ['team1', 'team2', 'tournament', 'round'],
      }),
    )
  }, [dispatch, teamId])
  // const events = [
  //   {
  //     title: 'game-home',
  //     start: '2024-06-30',
  //     type: 'home',
  //     display: 'background',
  //     color: eventTypeColors.home,
  //     teamLogo: '/images/default/team.svg',
  //     teamName: '-',
  //     place: '-',
  //     regulations: '-',
  //     category: '-',
  //   },
  // ]

  // временное решение
  const transformMatchToEvent = (match: Match) => {
    const startDate = match?.start_dt ? match.start_dt.split(' ')[0] : '-'
    const isHomeGame = match.team1_id === Number(teamId)

    return {
      title: isHomeGame
        ? `${match.team2?.full_name || 'неизвестная команда'}`
        : `${match.team1?.full_name || 'неизвестная команда'}`,
      start: startDate,
      type: isHomeGame ? 'home' : 'away',
      display: 'background',
      color: isHomeGame ? eventTypeColors.home : eventTypeColors.away,
      teamLogo: isHomeGame
        ? match.team2?.avatar || '/images/default/team.svg'
        : match.team1?.avatar || '/images/default/team.svg',
      teamName: isHomeGame
        ? match.team2?.full_name || 'неизвестная команда'
        : match.team1?.full_name || 'неизвестная команда',
      place: match.tournament?.club_address || 'неизвестный адрес',
      regulations: match.round?.series_regulation || '-',
      category: match.round?.series_match_type || '-',
    }
  }

  const events = matchesArray.map(transformMatchToEvent)
  function showModal(arg: EventClickArg) {
    const allModals = document.getElementsByClassName('event')
    for (let i = 0; i < allModals.length; i += 1) {
      allModals[i].classList.remove('event--open')
    }
    const currentModal = arg.el.getElementsByClassName('event')[0]
    const parents = document.querySelectorAll('td.fc-day.fc-day-past.fc-daygrid-day')
    const parentTd = arg.el.closest('td.fc-day.fc-day-past.fc-daygrid-day')
    parents.forEach((td) => {
      if (td && td instanceof HTMLElement) {
        // eslint-disable-next-line no-param-reassign
        td.style.zIndex = '0'
      }
    })
    if (parentTd && parentTd instanceof HTMLElement) {
      parentTd.style.zIndex = '1'
    }
    if (currentModal) {
      currentModal.classList.add('event--open')
    }
  }

  document.addEventListener('click', (event: MouseEvent) => {
    const allModals = document.getElementsByClassName('event') as HTMLCollectionOf<HTMLElement>
    const allModalsArray = Array.from(allModals)
    if (event.target && !(event.target as HTMLElement).closest('.fc-media-screen')) {
      allModalsArray.forEach((modal) => {
        modal.classList.remove('event--open')
      })
    }
  })

  function showEventContent(arg: EventContentArg) {
    const arrayOfDomNodes = []
    const eventContainer = document.createElement('div')
    eventContainer.classList.add('fc-event-container')
    const imgEventWrap = document.createElement('div')
    imgEventWrap.classList.add('fc-event-img')
    const modalEventWrap = document.createElement('div')
    modalEventWrap.classList.add('event-modal')
    if (arg.event.extendedProps.teamLogo) {
      // eslint-disable-next-line
      imgEventWrap.innerHTML = `<img src="${arg.event.extendedProps.teamLogo}" width="35" height="35" alt="${arg.event._def.title}">`
    }
    modalEventWrap.innerHTML = ReactDOMServer.renderToString(<EventModal event={arg.event} />)
    eventContainer.appendChild(imgEventWrap)
    eventContainer.appendChild(modalEventWrap)
    arrayOfDomNodes.push(eventContainer)
    return {domNodes: arrayOfDomNodes}
  }

  return (
    <div className="calendar__main">
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={events}
        headerToolbar={false}
        locale="ru"
        height="auto"
        contentHeight="auto"
        eventContent={(arg) => showEventContent(arg)}
        firstDay={1}
        eventClick={(arg) => showModal(arg)}
      />
      <CalendarInfo />
    </div>
  )
}

export default CalendarMain
