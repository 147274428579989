import {ApiPagesAliasGetAliasEnum} from '../../api'
import {HeaderNavigationType} from '../types/navigation'
import AppRoute from './routes'

export const HeaderNavigations: HeaderNavigationType = [
  {
    title: 'О нас',
    submenu: [
      {
        title: 'О проекте',
        url: `${AppRoute.AboutStaticPages.url}/${ApiPagesAliasGetAliasEnum.About}`,
      },
      {
        title: 'Юридическая информация',
        url: `${AppRoute.AboutStaticPages.url}/${ApiPagesAliasGetAliasEnum.LegalInformation}`,
      },
      {
        title: 'Основные положения',
        url: AppRoute.MainProvisionsPage.url,
      },
      {
        title: 'FAQ',
        url: AppRoute.FaqPage.url,
      },
    ],
  },
  {
    title: 'Календарь',
    submenu: [
      {
        title: 'Лига',
        url: AppRoute.LeaguePage.url,
      },
      {
        title: 'Турниры',
        url: AppRoute.TournamentsPage.url,
      },
    ],
  },
  {
    title: 'Участники',
    submenu: [
      {
        title: 'Команды',
        url: AppRoute.CommandsRatingPage.url,
      },
      {
        title: 'Игроки',
        url: AppRoute.PlayersRatingPage.url,
      },
    ],
  },
  {
    title: 'Медиа',
    submenu: [
      {
        title: 'Видео',
        url: AppRoute.VideosPage.url,
      },
      {
        title: 'Фотоальбомы',
        url: AppRoute.AlbumsPage.url,
      },
      {
        title: 'Новости',
        url: AppRoute.PostsPage.url,
      },
      // {
      //   title: 'Трансляции',
      //   url: AppRoute.StreamPage.url,
      // },
    ],
  },
]

export const gridsTabsArray = [
  {title: '1/4 финала', tabType: 'finale-1/4'},
  {title: '1/2 финала', tabType: 'finale-1/2'},
  {title: 'Финал и турнир за 3-е место', tabType: 'finale-3'},
]

export const registrationTabsArray = [
  {title: 'Вход', tabType: 'entrance'},
  {title: 'Регистрация', tabType: 'registration'},
]

export const ratingPage = [
  {title: 'Рейтинг команд', url: AppRoute.CommandsRatingPage.url},
  {title: 'Рейтинг игроков', url: AppRoute.PlayersRatingPage.url},
]

export const registrationButtonsArray = [
  {title: 'Регистрация команды', url: AppRoute.RegistrationCommandsPage.url},
  {title: 'Регистрация турнира', url: AppRoute.RegistrationTournamentPage.url},
  {title: 'Оплата', url: AppRoute.PaymentPage.url},
]

export const FooterDocumentArray = [
  {title: 'Пользовательское соглашение', url: '#'},
  {title: 'Политика конфиденциальности', url: '#'},
  {title: 'Условия участия в теннисной лиге', url: '#'},
]
