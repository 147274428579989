import React, {useEffect, useRef} from 'react'
import ChessTeams from '../chess-teams/chess-teams'
import ChessScores from '../chess-scores/chess-scores'
import ChessIndicator from '../../ui/chess-indicator/chess-indicator'
import {RoundChess} from '../../../api'

function Chess({roundChess}: {roundChess?: RoundChess}) {
  const teamTableRef = useRef<HTMLTableElement>(null)
  const scoresTableRef = useRef<HTMLTableElement>(null)
  const scoresTableScrollRef = useRef<HTMLTableElement>(null)
  const scrollIndicatorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const scoresTableScroll = scoresTableScrollRef.current
    const scrollIndicator = scrollIndicatorRef.current

    if (scoresTableScroll && scrollIndicator) {
      const isScrollNeeded = scoresTableScroll.scrollWidth > scoresTableScroll.clientWidth
      const indicatorWidth = (scoresTableScroll.clientWidth / scoresTableScroll.scrollWidth) * 100
      scrollIndicator.style.width = `${indicatorWidth}%`
      scrollIndicator.style.display = isScrollNeeded ? 'block' : 'none'

      const handleWheel = (event: WheelEvent) => {
        event.preventDefault()
        const scrollAmount = event.deltaY
        scoresTableScroll.scrollLeft += scrollAmount
      }

      const handleScroll = () => {
        const scrollPercentage =
          scoresTableScroll.scrollLeft /
          (scoresTableScroll.scrollWidth - scoresTableScroll.clientWidth)
        const maxPosition = 100 - indicatorWidth
        const indicatorPosition = scrollPercentage * maxPosition
        scrollIndicator.style.left = `${indicatorPosition}%`
      }

      scoresTableScroll.addEventListener('wheel', handleWheel, {passive: false})
      scoresTableScroll.addEventListener('scroll', handleScroll)

      return () => {
        scoresTableScroll.removeEventListener('wheel', handleWheel)
        scoresTableScroll.removeEventListener('scroll', handleScroll)
      }
    }
    return undefined
  }, [])

  return (
    <div className="gap gap--0">
      <div className="chess">
        <div className="chess__wrapper">
          <ChessTeams roundTables={roundChess?.tableRows} ref={teamTableRef} />
          <div className="chess__content" ref={scoresTableScrollRef}>
            <ChessScores roundChess={roundChess} ref={scoresTableRef} />
          </div>
        </div>
        <ChessIndicator ref={scrollIndicatorRef} />
      </div>
    </div>
  )
}

export default Chess
