import React from 'react'
import SidebarList from '../../ordinary/sidebar-list/sidebar-list'
import Next from '../../ordinary/next/next'
import {Post, Video} from '../../../api'
import Stub from '../../ordinary/stub/stub'

function AsideMedia({
  contents,
  contentsError,
  contentsLoading,
}: {
  contents: Post[] | Video[]
  contentsError: boolean
  contentsLoading: boolean
}) {
  const hidden = contents.length === 0 || contentsError || contentsLoading

  return (
    <aside className="sidebar">
      <Next />
      <SidebarList contents={contents} hidden={hidden} />
      <Stub
        type={contentsError ? 'error' : contents.length === 0 ? 'absence' : ''}
        error={contentsError ? 'Произошла ошибка при загрузке интересного контента.' : ''}
      />
    </aside>
  )
}

export default AsideMedia
