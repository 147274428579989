/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice} from '@reduxjs/toolkit'
import {PlayersState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {fetchPlayer, fetchPlayers} from '../api-actions'

const initialState: PlayersState = {
  isPlayersLoading: false,
  isPlayersError: false,
  players: [],
  isPlayerLoading: false,
  isPlayerError: false,
  player: null,
}

const playersData = createSlice({
  name: NameSpace.Players,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlayers.pending, (state) => {
        state.isPlayersLoading = true
      })
      .addCase(fetchPlayers.fulfilled, (state, action) => {
        state.players = action.payload
        state.isPlayersLoading = false
      })
      .addCase(fetchPlayers.rejected, (state) => {
        state.isPlayersError = true
        state.isPlayersLoading = false
      })
      .addCase(fetchPlayer.pending, (state) => {
        state.isPlayerLoading = true
      })
      .addCase(fetchPlayer.fulfilled, (state, action) => {
        state.player = action.payload
        state.isPlayerLoading = false
      })
      .addCase(fetchPlayer.rejected, (state) => {
        state.isPlayerError = true
        state.isPlayerLoading = false
      })
  },
})

export default playersData
