import React, {ChangeEvent, useState} from 'react'
import {FormikErrors} from 'formik'
import {LoginFormValues} from '../../../core/types/form'

function ModalEntranceList({
  values,
  errors,
  onChangeInput,
}: {
  values: LoginFormValues
  errors: FormikErrors<LoginFormValues>
  onChangeInput: (e: ChangeEvent<HTMLInputElement>) => void
}) {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className="modal__list">
      <label className="form" htmlFor="login-email">
        <p className="form__text">
          Email
          <span className={`form__help ${errors.email ? '' : 'hidden'}`}>{errors.email}</span>
        </p>
        <input
          id="login-email"
          className={`form__input ${errors.email ? 'form__input--error' : ''}`}
          type="text"
          name="email"
          placeholder="Введите вашу почту"
          onChange={(e) => onChangeInput(e)}
          value={values.email}
        />
      </label>
      <label className="form form--password" htmlFor="login-password">
        <p className="form__text">
          Пароль
          <span className={`form__help ${errors.password ? '' : 'hidden'}`}>{errors.password}</span>
        </p>
        <div className="form__password">
          <input
            id="login-password"
            className={`form__input ${errors.password ? 'form__input--error' : ''}`}
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="Введите ваш пароль"
            onChange={(e) => onChangeInput(e)}
            value={values.password}
          />
        </div>
        <button
          className={`form__control ${showPassword ? 'form__control--view' : ''}`}
          type="button"
          onClick={() => setShowPassword(!showPassword)}
        />
      </label>
    </div>
  )
}

export default ModalEntranceList
