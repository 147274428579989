import React from 'react'
import Button from '../../ui/button/button'
import {ApiTournamentsIdRoundsGetTypeEnum, Tournament} from '../../../api'
import {simpleDateFormat} from '../../../core/utils/date-time-helpers'
import AppRoute from '../../../core/constants/routes'
import {filterRoundsByTypes} from '../../../core/utils/array-prepare-helpers'

function TournamentsItem({tournament}: {tournament: Tournament}) {
  const startedDate: string | undefined = tournament.start_dt
  let formattedDate: string | null = ''
  if (startedDate !== undefined) {
    formattedDate = simpleDateFormat(new Date(startedDate), 'dd MMMM')
  }
  const lapRounds = filterRoundsByTypes(tournament.rounds, [
    ApiTournamentsIdRoundsGetTypeEnum.RoundRobinOne,
    ApiTournamentsIdRoundsGetTypeEnum.RoundRobinTwo,
  ])
  const playoffRounds = filterRoundsByTypes(tournament.rounds, [
    ApiTournamentsIdRoundsGetTypeEnum.PlayoffOlympic,
  ])
  const logo = tournament._links?.logo?.href?.includes('logo_placeholder_thumb.png')
    ? '/images/default/tournament.svg'
    : tournament._links?.logo?.href

  return (
    <li className="tournaments__item">
      <div className="tournaments__wrap">
        <div className="tournaments__blocks">
          <div className="tournaments__block">
            <img
              className="tournaments__img"
              src={logo}
              width="80"
              height="80"
              alt={tournament.short_name}
            />
            <p className="tournaments__name">{tournament.full_name}</p>
          </div>
          <div className="tournaments__texts show-480">
            <p className="tournaments__title">
              Сезон <span className="tournaments__blue">{tournament.season?.title}</span>
            </p>
            <div className="tournaments__data">
              <p className="tournaments__text">{formattedDate}</p>
              <svg className="tournaments__icon" width="20" height="20">
                <use xlinkHref="images/sprite.svg#calendar" />
              </svg>
            </div>
            <div className="tournaments__data">
              <p className="tournaments__text">{tournament.city?.title}</p>
              <svg className="tournaments__icon" width="20" height="20">
                <use xlinkHref="images/sprite.svg#location" />
              </svg>
            </div>
          </div>
        </div>
        <div className="tournaments__container">
          <ul className="tournament">
            <li className="tournament__item">
              <p className="tournament__title">Клуб:</p>
              <p className="tournament__subtitle">{tournament.club}</p>
            </li>
            <li className="tournament__item">
              <p className="tournament__title">Покрытие:</p>
              <p className="tournament__subtitle">{tournament.court_coverage}</p>
            </li>
            <li className="tournament__item">
              <p className="tournament__title">Адрес:</p>
              <p className="tournament__subtitle">{tournament.club_address}</p>
            </li>
            <li className="tournament__item">
              <p className="tournament__title">Мяч:</p>
              <p className="tournament__subtitle">{tournament.official_ball}</p>
            </li>
            <li className="tournament__item">
              {/* нужно доработать */}
              <p className="tournament__title">Формат:</p>
              <p className="tournament__subtitle">Optima</p>
            </li>
          </ul>
          <Button
            className="show-480"
            to={`${playoffRounds.length > 0 && lapRounds.length === 0 ? AppRoute.GridsPage.url : AppRoute.RatingPage.url}/${tournament.tournament_id}`}
            text="Подробнее"
          />
        </div>
      </div>
      <div className="tournaments__footer hide-480">
        <div className="tournaments__texts">
          <p className="tournaments__title">
            Сезон <span className="tournaments__season">{tournament.season?.title}</span>
          </p>
          <div className="tournaments__data">
            <svg className="tournaments__icon" width="20" height="20">
              <use xlinkHref="images/sprite.svg#location" />
            </svg>
            <p className="tournaments__text">{tournament.city?.title}</p>
          </div>
          <div className="tournaments__data">
            <svg className="tournaments__icon" width="20" height="20">
              <use xlinkHref="images/sprite.svg#calendar" />
            </svg>
            <p className="tournaments__text">{formattedDate}</p>
          </div>
        </div>
        <Button
          to={`${playoffRounds.length > 0 && lapRounds.length === 0 ? AppRoute.GridsPage.url : AppRoute.RatingPage.url}/${tournament.tournament_id}`}
          text="Подробнее"
        />
      </div>
      <svg className="tournaments__background" width="745" height="300">
        <use xlinkHref="images/sprite.svg#background-logo" />
      </svg>
    </li>
  )
}

export default TournamentsItem
