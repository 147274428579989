import React from 'react'

function CommandItem({name, text}: {name: string; text: string}) {
  return (
    <li className="command__item">
      <p className="command__name">{name}</p>
      <p className="command__text">{text}</p>
    </li>
  )
}

export default CommandItem
