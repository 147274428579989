import React from 'react'
import {Route, Routes} from 'react-router-dom'
import MainPage from '../../pages/main-page/main-page'
import {RouteType} from '../types/app-route'
import ErrorPage from '../../pages/error-page/error-page'
import AppRoute from '../constants/routes'
import TournamentsPage from '../../pages/tournaments-page/tournaments-page'
import VideosPage from '../../pages/videos-page/videos-page'
import AlbumsPage from '../../pages/albums-page/albums-page'
import FaqPage from '../../pages/faq-page/faq-page'
import LeaguePage from '../../pages/league-page/league-page'
import BasicProvisionsPage from '../../pages/main-provisions-page/main-provisions-page'
import PostsPage from '../../pages/posts-page/posts-page'
import StreamPage from '../../pages/stream-page/stream-page'
import ProtocolsPage from '../../pages/protocols-page/protocols-page'
import GridsPage from '../../pages/grids-page/grids-page'
import TournamentPage from '../../pages/tournament-page/tournament-page'
import VideoPage from '../../pages/video-page/video-page'
import CurrentNewsPage from '../../pages/current-news-page/current-news-page'
import AlbumPage from '../../pages/album-page/album-page'
import AboutUsPage from '../../pages/about-us-page/about-us-page'
import CommandsRatingPage from '../../pages/commands-rating-page/commands-rating-page'
import PlayersRatingPage from '../../pages/players-rating-page/players-rating-page'
import PlayerStatPage from '../../pages/player-stat-page/player-stat-page'
import CommandMatchesPage from '../../pages/command-matches-page/command-matches-page'
import PlayerMeetPage from '../../pages/player-meet-page/player-meet-page'
import PlayerHistoryPage from '../../pages/player-history-page/player-history-page'
import CommandLineUpPage from '../../pages/command-line-up-page/command-line-up-page'
import CommandCalendarPage from '../../pages/command-calendar-page/command-calendar-page'
import RatingPage from '../../pages/rating-page/rating-page'
import ChessPage from '../../pages/chess-page/chess-page'
import RegistrationCommandsPage from '../../pages/registration-commands-page/registration-commands-page'
import RegistrationTournamentPage from '../../pages/registration-tournament-page/registration-tournament-page'
import PaymentPage from '../../pages/payment-page/payment-page'
import MatchPage from '../../pages/match-page/match-page'
import PersonalPage from '../../pages/personal-page/personal-page'
import RequestTournamentPage from '../../pages/request-tournament-page/request-tournament-page'
import RequestCommandPage from '../../pages/request-command-page/request-command-page'
import ProtectedRoute from '../../components/ordinary/private-route/private-route'
import PaymentProcessPage from '../../pages/payment-process-page/payment-process-page'

function RoutesComponent() {
  const createElementForRoute = (route: RouteType) => {
    switch (route) {
      case AppRoute.Main:
        return <MainPage />
      case AppRoute.TournamentsPage:
        return <TournamentsPage />
      case AppRoute.VideoPage:
        return <VideoPage />
      case AppRoute.VideosPage:
        return <VideosPage />
      case AppRoute.AboutStaticPage:
        return <AboutUsPage />
      case AppRoute.AlbumsPage:
        return <AlbumsPage />
      case AppRoute.AlbumPage:
        return <AlbumPage />
      case AppRoute.FaqPage:
        return <FaqPage />
      case AppRoute.LeaguePage:
        return <LeaguePage />
      case AppRoute.MainProvisionsPage:
        return <BasicProvisionsPage />
      case AppRoute.PostsPage:
        return <PostsPage />
      case AppRoute.CurrentNewsPage:
        return <CurrentNewsPage />
      case AppRoute.StreamPage:
        return <StreamPage />
      case AppRoute.RatingPageWithId:
        return <RatingPage />
      case AppRoute.ChessPageWithId:
        return <ChessPage />
      case AppRoute.GridsPageWithId:
        return <GridsPage />
      case AppRoute.ProtocolsPageWithId:
        return <ProtocolsPage />
      case AppRoute.AboutPageWithId:
        return <TournamentPage />
      case AppRoute.CommandsRatingPage:
        return <CommandsRatingPage />
      case AppRoute.PlayersRatingPage:
        return <PlayersRatingPage />
      case AppRoute.PlayerStatPageWithId:
        return <PlayerStatPage />
      case AppRoute.PlayerMeetPageWithId:
        return <PlayerMeetPage />
      case AppRoute.PlayerHistoryPageWithId:
        return <PlayerHistoryPage />
      case AppRoute.CommandMatchesPageWithId:
        return <CommandMatchesPage />
      case AppRoute.CommandLineUpPageWithId:
        return <CommandLineUpPage />
      case AppRoute.CommandCalendarPageWithId:
        return <CommandCalendarPage />
      case AppRoute.RegistrationCommandsPage:
        return (
          <ProtectedRoute roles={[34]}>
            <RegistrationCommandsPage />
          </ProtectedRoute>
        )
      case AppRoute.RegistrationTournamentPage:
        return (
          <ProtectedRoute roles={[66]}>
            <RegistrationTournamentPage />
          </ProtectedRoute>
        )
      case AppRoute.PersonalPage:
        return <PersonalPage />
      case AppRoute.PaymentPage:
        return (
          <ProtectedRoute roles={[34, 66]}>
            <PaymentPage />
          </ProtectedRoute>
        )
      case AppRoute.MatchPageWithId:
        return <MatchPage />
      case AppRoute.RequestTournamentPage:
        return <RequestTournamentPage />
      case AppRoute.RequestCommandPage:
        return <RequestCommandPage />
      case AppRoute.PaymentProcessPage:
        return <PaymentProcessPage />
      default:
        return <ErrorPage />
    }
  }
  return (
    <Routes>
      {Object.values(AppRoute).map((route) => (
        <Route key={route.url} path={route.url} element={createElementForRoute(route)} />
      ))}
    </Routes>
  )
}

export default RoutesComponent
