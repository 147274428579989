import React, {useEffect} from 'react'
import Search from '../../ordinary/search/search'
import {DropdownTypes, ItemsForFilter, filterStage} from '../../../core/constants/filters'
import {useAppDispatch, useAppSelector} from '../../../core/hooks'
import {getSeasons} from '../../../core/store/seasons-data/selectors'
import {
  transformRegionsToOptions,
  transformSeasonsToOptions,
} from '../../../core/utils/array-prepare-helpers'
import {fetchRegions, fetchSeasons} from '../../../core/store/api-actions'
import {getRegions} from '../../../core/store/regions-data/selectors'
import Dropdown from '../dropdown/dropdown'
import Reset from '../reset/reset'

function FilterLeagueItem() {
  const dispatch = useAppDispatch()
  const seasons = useAppSelector(getSeasons)
  const seasonsOptions = transformSeasonsToOptions(seasons)
  const regions = useAppSelector(getRegions)
  const regionsOptions = transformRegionsToOptions(regions)

  useEffect(() => {
    dispatch(fetchSeasons())
    dispatch(fetchRegions())
  }, [dispatch])
  return (
    <>
      <li className="filter__item">
        <Dropdown
          options={seasonsOptions}
          type={DropdownTypes.Season}
          items={ItemsForFilter.Leagues}
        />
      </li>
      <li className="filter__item">
        <Dropdown options={filterStage} type={DropdownTypes.Stage} items={ItemsForFilter.Leagues} />
      </li>
      <li className="filter__item">
        <Dropdown
          options={regionsOptions}
          type={DropdownTypes.Region}
          items={ItemsForFilter.Leagues}
        />
      </li>
      <li className="filter__item hidden">
        <Search items={ItemsForFilter.Leagues} />
      </li>
      <li className="filter__item">
        <Reset items={ItemsForFilter.Leagues} />
      </li>
    </>
  )
}

export default FilterLeagueItem
