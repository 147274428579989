import {ProvisionsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {HdbkMainProvision} from '../../../api'

import {State} from '../index'

export const getProvisionsLoadingStatus = (state: State): boolean =>
  state[NameSpace.Provisions].isProvisionsLoading
export const getProvisionsErrorStatus = (state: State): ProvisionsState['isProvisionsError'] =>
  state[NameSpace.Provisions].isProvisionsError
export const getProvisions = (state: State): HdbkMainProvision[] =>
  state[NameSpace.Provisions].provisions
