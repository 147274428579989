/* eslint-disable no-param-reassign, import/no-cycle */
import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {fetchAlbum, fetchAlbums} from '../api-actions'
import {AlbumsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {FilterOption} from '../../types/common'

const initialState: AlbumsState = {
  isAlbumsLoading: false,
  isAlbumLoading: false,
  isAlbumsError: false,
  isAlbumError: false,
  albums: [],
  currentAlbum: null,
  filterSeasonStatus: {
    value: 'all',
    label: 'все сезоны',
  },
}

export const albumsData = createSlice({
  name: NameSpace.Albums,
  initialState,
  reducers: {
    filteringAlbumsBySeason: (state, action: PayloadAction<{filterSeasonStatus: FilterOption}>) => {
      const {filterSeasonStatus} = action.payload
      state.filterSeasonStatus = filterSeasonStatus
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAlbums.pending, (state) => {
        state.isAlbumsLoading = true
      })
      .addCase(fetchAlbums.fulfilled, (state, action) => {
        state.albums = action.payload
        state.isAlbumsLoading = false
      })
      .addCase(fetchAlbums.rejected, (state) => {
        state.isAlbumsError = true
        state.isAlbumsLoading = false
      })
      .addCase(fetchAlbum.pending, (state) => {
        state.isAlbumLoading = true
      })
      .addCase(fetchAlbum.fulfilled, (state, action) => {
        state.currentAlbum = action.payload
        state.isAlbumLoading = false
      })
      .addCase(fetchAlbum.rejected, (state) => {
        state.isAlbumError = true
        state.isAlbumLoading = false
      })
  },
})

export const {filteringAlbumsBySeason} = albumsData.actions
