import NameSpace from '../../constants/api-constants'
import {TeamTemp} from '../../../api'
import {State} from '../index'

export const getTeamTempsLoadingStatus = (state: State): boolean =>
  state[NameSpace.TeamsTemps].isTeamTempsLoading
export const getTeamTempLoadingStatus = (state: State): boolean =>
  state[NameSpace.TeamsTemps].isTeamTempLoading
export const getTeamTempsErrorStatus = (state: State): boolean =>
  state[NameSpace.TeamsTemps].isTeamTempsError
export const getTeamTempErrorStatus = (state: State): boolean =>
  state[NameSpace.TeamsTemps].isTeamTempError
export const getTeamTemps = (state: State): TeamTemp[] => state[NameSpace.TeamsTemps].teamTemps
export const getCurrentTeamTemp = (state: State): TeamTemp | null =>
  state[NameSpace.TeamsTemps].currentTeamTemp
