import React from 'react'

// eslint-disable-next-line
function EventModal({event}: {event: any}) {
  return (
    <div className={`event event--${event.extendedProps.type}`}>
      <ul className="event__list">
        <li className="event__item">
          <p className="event__left">Команда</p>
          <p className="event__right">{event.extendedProps.teamName || '-'}</p>
        </li>
        <li className="event__item">
          <p className="event__left">Место</p>
          <p className="event__right">{event.extendedProps.place || '-'}</p>
        </li>
        <li className="event__item">
          <p className="event__left">Регламент</p>
          <p className="event__right">{event.extendedProps.regulations || '-'}</p>
        </li>
        <li className="event__item">
          <p className="event__left">Разряд</p>
          <p className="event__right">{event.extendedProps.category || '-'}</p>
        </li>
      </ul>
    </div>
  )
}

export default EventModal
