import React from 'react'

function SocialItem({social, link}: {social: string; link: string}) {
  return (
    <li className="social__item">
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        type="button"
        className="social__link">
        <svg className="social__icon" width="15" height="15">
          <use xlinkHref={`images/sprite.svg#${social}`} />
        </svg>
      </a>
    </li>
  )
}

export default SocialItem
