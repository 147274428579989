import React from 'react'
import {Link} from 'react-router-dom'
import {Banner} from '../../../api'
import {fixUrl} from '../../../core/utils/common-helpers'

function BannerItem({banner}: {banner: Banner}) {
  const image1140 = fixUrl(banner._links?.image?.[1140]?.href || '')
  const image460 = fixUrl(banner._links?.image_mobile?.[460]?.href || '')
  const image290 = fixUrl(banner._links?.image?.[290]?.href || '')
  return (
    <Link className="banner" to={banner.link} target={banner.blank ? '_blank' : '_self'}>
      <picture>
        <source media="(min-width: 1140px)" srcSet={image1140} />
        <source media="(min-width: 460px) and (max-width: 1140px)" srcSet={image460} />
        <source media="(max-width: 460px)" srcSet={image290} />
        <img className="banner__img" src={image1140} alt={banner.title} />
      </picture>
    </Link>
  )
}

export default BannerItem
