import React from 'react'
import {MatchSeries} from '../../../api'
import ChessCell from '../chess-cell/chess-cell'

function ChessRow({seriesGroup}: {seriesGroup: MatchSeries[][]}) {
  return (
    <tr className="table__row">
      {Object.values(seriesGroup).map((seriesArray) =>
        seriesArray === null ? (
          <td className="table__cell table-scores__empty" key={seriesArray} />
        ) : (
          <ChessCell seriesArray={seriesArray} key={seriesArray.length} />
        ),
      )}
    </tr>
  )
}
export default ChessRow
