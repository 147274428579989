import React from 'react'
import HashtagItem from '../hashtag-item/hashtag-item'
import {Tag} from '../../../api'

function HashtagList({tags}: {tags?: Tag[]}) {
  return (
    <ul className="hashtag">{tags?.map((tag) => <HashtagItem key={tag.tag_id} tag={tag} />)}</ul>
  )
}

export default HashtagList
