import React, {useEffect} from 'react'
import {FormikProvider, useFormik} from 'formik'
import {useParams} from 'react-router-dom'
import CrumbsList from '../../components/ordinary/crumbs-list/crumbs-list'
import Title from '../../components/ui/title/title'
import AppRoute from '../../core/constants/routes'
import ButtonsList from '../../components/ui/buttons-list/buttons-list'
import ButtonForm from '../../components/ui/button-form/button-form'
import {registrationCommandFormValidationSchema} from '../../core/utils/form-validate-helpers'
import {createTeam, fetchTeamsRequest} from '../../core/store/api-actions'
import {useAppDispatch, useAppSelector, useFilteredButtons} from '../../core/hooks'
import Reason from '../../components/ordinary/reason/reason'
import RequestStatus from '../../components/simple/request-status/request-status'
import {getCities} from '../../core/store/cities-data/selectors'
import {getZones} from '../../core/store/zones-data/selectors'
import {
  transformCitiesToOptions,
  transformZonesToOptions,
} from '../../core/utils/array-prepare-helpers'
import {
  getCurrentTeamTemp,
  getTeamTempErrorStatus,
  getTeamTempLoadingStatus,
} from '../../core/store/teams-temps-data/selectors'
import CommandsForm from '../../components/ordinary/commands-form/commands-form'
import Avatar from '../../components/ordinary/avatar/avatar'
import CompoundCommandsList from '../../components/simple/compound-commands-list/compound-commands-list'
import {TeamTempPlayer, TeamTempPlayerRequest} from '../../api'
import {getAuthorizationUserId} from '../../core/store/authorization-data/selectors'
import Stub from '../../components/ordinary/stub/stub'

function RequestCommandPage() {
  const dispatch = useAppDispatch()
  const {id: requestId} = useParams()
  const filteredButtons = useFilteredButtons()
  const userId = useAppSelector(getAuthorizationUserId)
  const zones = useAppSelector(getZones)
  const cities = useAppSelector(getCities)
  const teamTemp = useAppSelector(getCurrentTeamTemp)
  const isTempLoading = useAppSelector(getTeamTempLoadingStatus)
  const isTempError = useAppSelector(getTeamTempErrorStatus)

  const zonesOptions = React.useMemo(() => {
    return transformZonesToOptions(zones)
  }, [zones])

  const citiesOptions = React.useMemo(() => {
    return transformCitiesToOptions(cities)
  }, [cities])

  useEffect(() => {
    if (requestId) {
      dispatch(fetchTeamsRequest(Number(requestId)))
    }
  }, [dispatch, requestId])

  const crumbsRoutes = [
    AppRoute.Main,
    AppRoute.RegistrationCommandsPage,
    {title: teamTemp?.full_name || '', url: '#'},
  ]

  const cityObject = citiesOptions.find((city) => city.value === teamTemp?.city_id)
  const zoneObject = zonesOptions.find((zone) => zone.value === teamTemp?.zone_id)

  const transformGenderToObject = (gender: string | undefined) => {
    const genderOptions = [
      {value: 'man', label: 'Мужской'},
      {value: 'woman', label: 'Женский'},
    ]
    return genderOptions.find((option) => option.value === gender) || null
  }

  const initialValues = {
    user_id: userId || '',
    full_name: teamTemp?.full_name ?? '',
    club: teamTemp?.club ?? '',
    club_address: teamTemp?.club_address ?? '',
    city_id: cityObject ?? '',
    zone_id: zoneObject ?? '',
    court_coverage: teamTemp?.court_coverage ?? '',
    phone: teamTemp?.phone ?? '',
    email: teamTemp?.email ?? '',
    logo: teamTemp?.logo ?? '',
    partner_logo: '',
    is_draft: 0,
    players: teamTemp?.players?.map((player) => ({
      first_name: player.first_name,
      last_name: player.last_name,
      birthday: player.birthday,
      gender: transformGenderToObject(player.gender),
      photo: player.photo,
      ntrp: player.ntrp?.toString(),
    })) ?? [
      {
        first_name: '',
        last_name: '',
        birthday: '',
        gender: '',
        photo: '',
        ntrp: 0,
      },
    ],
  }

  const validationSchema = registrationCommandFormValidationSchema

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const prepareFormData = (formData: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updatedPlayers = formData.players.map((player: any) => ({
      ...player,
      gender: player.gender?.value,
    }))
    return {...formData, players: JSON.stringify(updatedPlayers)}
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (values: any) => {
    const preparedData = prepareFormData(values)
    dispatch(createTeam(preparedData))
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  })

  const handleAvatarChange = (file: File | null) => {
    formik.setFieldValue('logo', file || '')
  }

  const handleSaveDraft = () => {
    formik.setFieldValue('is_draft', 1)
    formik.handleSubmit()
  }

  const handleSaveAndSubmit = () => {
    formik.setFieldValue('is_draft', 0)
    formik.handleSubmit()
  }

  if (isTempLoading) {
    return <div>Loading...</div>
  }

  if (isTempError) {
    return (
      <Stub
        type={isTempError ? 'error' : ''}
        error={isTempError ? 'Произошла ошибка при загрузке данных.' : ''}
      />
    )
  }
  return (
    <div className="container">
      <div className="gap gap--20">
        <div className="gap gap--20">
          <CrumbsList routes={crumbsRoutes} />
          <div className="gap">
            <div className="gap gap--row gap--15">
              <div className="gap gap--50 gap--row-2">
                <Title title={teamTemp?.full_name} className="title--uppercase" />
                <RequestStatus status={teamTemp?.status} />
              </div>
              <ButtonsList buttonsData={filteredButtons} />
            </div>
            {teamTemp?.comment && <Reason comment={teamTemp.comment} />}
          </div>
        </div>
        <FormikProvider value={formik}>
          <form className="gap" onSubmit={formik.handleSubmit}>
            <div className="gap gap--50">
              <div className="gap gap--15">
                <div className="background-form background-form--profile">
                  {/* надо доработать */}
                  <Avatar profile="camera.svg" onFileChange={handleAvatarChange} />
                  <CommandsForm formik={formik} />
                </div>
              </div>
              <div className="gap gap--15">
                <Title title="Состав команды" />
                <div className="background-form background-form--profile">
                  <CompoundCommandsList
                    formik={formik}
                    players={formik.values.players as (TeamTempPlayerRequest | TeamTempPlayer)[]}
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="saves">
            <ButtonForm className="save--green" title="Сохранить" onClick={handleSaveDraft} />
            <ButtonForm title="Сохранить и отправить на проверку" onClick={handleSaveAndSubmit} />
          </div>
        </FormikProvider>
      </div>
    </div>
  )
}

export default RequestCommandPage
