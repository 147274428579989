import {PostsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {Post} from '../../../api'
import {FilterOption} from '../../types/common'

import {State} from '../index'

export const getPostsLoadingStatus = (state: State): boolean =>
  state[NameSpace.Posts].isPostsLoading
export const getPostLoadingStatus = (state: State): boolean => state[NameSpace.Posts].isPostLoading
export const getPostsErrorStatus = (state: State): PostsState['isPostsError'] =>
  state[NameSpace.Posts].isPostsError
export const getPostErrorStatus = (state: State): PostsState['isPostError'] =>
  state[NameSpace.Posts].isPostError
export const getPosts = (state: State): Post[] => state[NameSpace.Posts].posts
export const getCurrentPost = (state: State): Post | null => state[NameSpace.Posts].currentPost
export const getPostsFilterSeasonStatus = (state: State): FilterOption =>
  state[NameSpace.Posts].filterSeasonStatus
