import React from 'react'
import PartnersItem from '../partners-item/partners-item'

function PartnersList() {
  return (
    <ul className="partners">
      <PartnersItem icon="pay-visa.svg" width="28" height="10" />
      <PartnersItem icon="pay-mastercard.svg" width="26" height="20" />
      <PartnersItem icon="pay-mir.svg" width="32" height="10" />
      <PartnersItem icon="pay-assist.svg" width="30" height="30" />
    </ul>
  )
}

export default PartnersList
