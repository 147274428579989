/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice} from '@reduxjs/toolkit'
import {fetchProvisions} from '../api-actions'
import {ProvisionsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'

const initialState: ProvisionsState = {
  isProvisionsLoading: false,
  isProvisionsError: false,
  provisions: [],
}

const provisionsData = createSlice({
  name: NameSpace.Provisions,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchProvisions.pending, (state) => {
        state.isProvisionsLoading = true
      })
      .addCase(fetchProvisions.fulfilled, (state, action) => {
        state.provisions = action.payload
        state.isProvisionsLoading = false
      })
      .addCase(fetchProvisions.rejected, (state) => {
        state.isProvisionsError = true
        state.isProvisionsLoading = false
      })
  },
})

export default provisionsData
