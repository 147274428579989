import {FaqsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {HdbkFaq} from '../../../api'

import {State} from '../index'

export const getFaqsLoadingStatus = (state: State): boolean => state[NameSpace.Faqs].isFaqsLoading
export const getFaqsErrorStatus = (state: State): FaqsState['isFaqsError'] =>
  state[NameSpace.Faqs].isFaqsError
export const getFaqs = (state: State): HdbkFaq[] => state[NameSpace.Faqs].faqs
