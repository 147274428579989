/* eslint-disable no-param-reassign, import/no-cycle */
import {createSlice} from '@reduxjs/toolkit'
import {TeamTempsState} from '../../types/state'
import NameSpace from '../../constants/api-constants'
import {fetchTeamsRequest, fetchTeamsRequestsMine} from '../api-actions'

const initialState: TeamTempsState = {
  isTeamTempsLoading: false,
  isTeamTempLoading: false,
  isTeamTempsError: false,
  isTeamTempError: false,
  teamTemps: [],
  currentTeamTemp: null,
}

const teamsTempsData = createSlice({
  name: NameSpace.TeamsTemps,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTeamsRequestsMine.pending, (state) => {
        state.isTeamTempsLoading = true
      })
      .addCase(fetchTeamsRequestsMine.fulfilled, (state, action) => {
        state.teamTemps = action.payload
        state.isTeamTempsLoading = false
      })
      .addCase(fetchTeamsRequestsMine.rejected, (state) => {
        state.isTeamTempsError = true
        state.isTeamTempsLoading = false
      })
      .addCase(fetchTeamsRequest.pending, (state) => {
        state.isTeamTempLoading = true
      })
      .addCase(fetchTeamsRequest.fulfilled, (state, action) => {
        state.currentTeamTemp = action.payload
        state.isTeamTempLoading = false
      })
      .addCase(fetchTeamsRequest.rejected, (state) => {
        state.isTeamTempError = true
        state.isTeamTempLoading = false
      })
  },
})

export default teamsTempsData
