import React from 'react'
import FilterPagesItem from '../../smart/filter-pages-item/filter-pages-item'

function FilterPagesList() {
  return (
    <div>
      <ul className="filter filter--pages">
        <FilterPagesItem />
      </ul>
    </div>
  )
}

export default FilterPagesList
