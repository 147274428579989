import React from 'react'
import CommandItem from '../command-item/command-item'
import {Player, Team} from '../../../api'

function CommandList({team, captain}: {team: Team | null; captain: Player | null}) {
  return (
    <ul className="command__list">
      <CommandItem
        name="Капитан:"
        text={captain ? `${captain.first_name} ${captain.last_name}` : '-'}
      />
      <CommandItem name="Клуб:" text={team?.club || '-'} />
      <CommandItem name="Город:" text={team?.city?.title || '-'} />
      <CommandItem name="Адрес:" text={team?.club_address || '-'} />
      <CommandItem name="Покрытие:" text={team?.court_coverage || '-'} />
      {/* нужно доработать */}
      <CommandItem name="Партнёр:" text="-" />
    </ul>
  )
}

export default CommandList
