import React from 'react'
import {Link} from 'react-router-dom'
import {RatingTeam, StatsTeam} from '../../../api'
import AppRoute from '../../../core/constants/routes'

function RatingCommandsRow({
  teamRating,
  index,
  teamStats,
}: {
  teamRating: RatingTeam
  index: number
  teamStats?: StatsTeam
}) {
  return (
    <tr className="table__row">
      <td className="table__cell table__number">{index + 1}</td>
      <td className="table__cell">
        <Link
          className="table__link table__start"
          to={`${AppRoute.CommandCalendarPage.url}/${teamRating.team_id}`}>
          <img
            className="table__img"
            src={
              teamRating.team?.avatar?.includes('logo_placeholder_100x100.svg')
                ? '/images/default/team.svg'
                : teamRating.team?.avatar
            }
            width="25"
            height="25"
            alt={teamRating.team?.full_name}
          />
          <p className="table__name">{teamRating.team?.full_name}</p>
        </Link>
      </td>
      <td className="table__cell">{teamStats?.matches_games || 0}</td>
      <td className="table__cell">{teamStats?.wins || 0}</td>
      <td className="table__cell">{teamStats?.losses || 0}</td>
      <td className="table__cell table__common">{teamRating.points || 0}</td>
    </tr>
  )
}

export default RatingCommandsRow
