import React from 'react'

function SwitchItem({
  title,
  className,
  onClick,
}: {
  title: string
  className?: string
  onClick?: () => void
}) {
  return (
    <li className={`switch__item ${className || ''}`} onClick={onClick}>
      <button type="button" className="switch__button">
        {title}
      </button>
    </li>
  )
}

export default SwitchItem
